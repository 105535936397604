import React from "react";
import { Route } from "react-router-dom";

import { EmailPDFPage } from "./email/pdf";
import { ManageBannersTodaysLearningPage } from "./manage-banners/TodaysLearning";
import { ManageBannersSlideshowPage } from "./manage-banners/Slideshow";
import { ManageBannersPopupPage } from "./manage-banners/Popup";
import { ManageBannersTopPage } from "./manage-banners/Top";
import { ManageBannersVideoPage } from "./manage-banners/Video";
import { AppleAppSitePage } from "./misc/apple-app-site";
import { PushNotifyPage } from "./misc/push-notify";
import { PlayerList } from "./misc/PlayerList";
import { Cache } from './misc/Cache'
import { ManagePlayerPage } from "./misc/Player";
import { ManagePlayerSettings } from "./misc/PlayerSettings";
import { HomeRowsReorderPage } from "./HomeRowsReorder";
import { LayoutCellsReorderPage } from "./LayoutCellsReorder";
import { NoLayoutDemoPage } from "./TestNoLayout";
import { LectureRethink } from "./lectures/LectureRethink";
import { TranscodersPage } from "./Transcoders";
import { DailyDoseEmail } from "./email/DailyDoseEmail";

// Just export special pages that we don't route to here.
export * from "./Dashboard";

const pages = [
  // Non-menu Pages
  HomeRowsReorderPage,
  LayoutCellsReorderPage,
  NoLayoutDemoPage,
  TranscodersPage,
  LectureRethink,
  // Banners
  ManageBannersPopupPage,
  ManageBannersSlideshowPage,
  ManageBannersTodaysLearningPage,
  ManageBannersTopPage,
  ManageBannersVideoPage,
  // Email
  EmailPDFPage,
  // Misc
  AppleAppSitePage,
  PushNotifyPage,
  ManagePlayerPage,
  PlayerList,
  ManagePlayerSettings,
  DailyDoseEmail,
  Cache
];

/** Array of metadata to create menu items in the sidebar.  */
export const pageMenuItems = [
  // e.g. -- {
  //   category: "tanext",
  //   icon: IconFromMaterialUI,
  //   label: "My Page",
  //   name: "my-page",
  //   url: "/my/page",
  // }, ...
];
/** Array of routes to pass to the React Admin */
export const pageRoutes = [
  // e.g. --
  // <Route
  //   exact
  //   path="/my/page"
  //   render={render(MyPage)}
  //   noLayout, ...etc see React-Admin and React Router docs.
  // />, ...
];

function render(Component) {
  return () => {
    return <Component />;
  };
}

pages.forEach(page => {
  const { menu, route } = page;
  if (menu) {
    pageMenuItems.push(menu);
  }
  if (route) {
    const {
      view,
      path = menu ? menu.url || `/${menu.name}` : "/unknown",
      ...routeProps
    } = route;
    pageRoutes.push(
      <Route path={path} render={render(view)} {...routeProps} />,
    );
  }
});
