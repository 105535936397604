import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// #region Scroll Restoration
// See:
// - https://github.com/ReactTraining/react-router/blob/ddc86f1291317b99502eaf59f17d9ec6aa778394/packages/react-router-dom/docs/guides/scroll-restoration.md

/** @type {import('history').Location} */
var prevLocation = {};

function maybeScrollToTop(location) {
  if (location.pathname.startsWith("/manage-user-comments")) {
    return;
  }
  window.scrollTo(0, 0);
}

history.listen(location => {
  if (prevLocation.pathname !== location.pathname) {
    maybeScrollToTop(location);
  }
  prevLocation = { ...location };
});
// #endregion

export default history;
