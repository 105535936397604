import React from "react";
import { Edit, NumberInput, SimpleForm } from "react-admin";
import resourceIcon from "@material-ui/icons/Label";
import {
  CategoryInput,
  EditorToolbar,
  InputRow,
  StaticField,
  SubCategoryInput,
} from "../../components";

const styles = {
  row: {
    maxWidth: 800,
  },
};

// NOTE: All of these AutocompleteInputs get EVEN SLOWER if you render an array.
// const categoryRows = Array.from(Array(7));
// function renderCategoryRow(emptyValue, index) {
//   const source = `category${index}`;
//   return (
//     <InputRow style={styles.row} key={index}>
//       <CategoryInput source={source} allowEmpty />
//       <SubCategoryInput
//         source={`subcategory${index}`}
//         parentSource={source}
//         allowEmpty
//       />
//     </InputRow>
//   );
// }
// To render:
// {categoryRows.map(renderCategoryRow)}

function EditView(props) {
  return (
    <Edit {...props} title="Homepage Categories">
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <StaticField>
          <h4 style={{ fontFamily: "Arial" }}>Categories to show</h4>
        </StaticField>
        <InputRow style={styles.row}>
          <CategoryInput source="category0" allowEmpty />
          <SubCategoryInput
            source="subcategory0"
            parentSource="category0"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category1" allowEmpty />
          <SubCategoryInput
            source="subcategory1"
            parentSource="category1"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category2" allowEmpty />
          <SubCategoryInput
            source="subcategory2"
            parentSource="category2"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category3" allowEmpty />
          <SubCategoryInput
            source="subcategory3"
            parentSource="category3"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category4" allowEmpty />
          <SubCategoryInput
            source="subcategory4"
            parentSource="category4"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category5" allowEmpty />
          <SubCategoryInput
            source="subcategory5"
            parentSource="category5"
            allowEmpty
          />
        </InputRow>
        <InputRow style={styles.row}>
          <CategoryInput source="category6" allowEmpty />
          <SubCategoryInput
            source="subcategory6"
            parentSource="category6"
            allowEmpty
          />
        </InputRow>
        <NumberInput
          source="limit"
          label="Items per category to show."
          style={{ marginTop: 40 }}
        />
      </SimpleForm>
    </Edit>
  );
}

export const HomepageCategories = {
  category: "manage",
  name: "manage-homepage-categories",
  edit: EditView,
  editId: "category_sliders",
  icon: resourceIcon,
  options: { label: "Homepage Categories" },
};
