import React, { useState, useEffect } from "react";
import {
  BooleanField,
  Create,
  Datagrid,
  DateInput,
  DateTimeInput,
  Edit,
  Filter,
  FormTab,
  ImageField,
  List,
  ImageInput,
  LongTextInput,
  NumberInput,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  FunctionField,
  RadioButtonGroupInput,
  // useRecordContext,
} from "react-admin";
import {
  Button,
  TextField as MaterialTextField,
  InputAdornment,
} from "@material-ui/core";
import resourceIcon from "@material-ui/icons/Camera";
import { Shuffle } from "@material-ui/icons";
import {
  CategoryInput,
  connectCommon,
  DateTimeField,
  BoolNumInput,
  EditorToolbar,
  EventSpeakersInput,
  HostInput,
  LanguageInput,
  LocationInput,
  ListActions,
  MorePagination,
  OrganizationInput,
  SpeakerInput,
  SubCategoryInput,
  validation,
  CopyToClipboard,
} from "../components";
import { authClient, authUser } from "../server";
import { MediaUploader } from "../uploader";
import { LectureRethink } from "../pages/lectures/LectureRethink";
import SanitizedP from "../components/field/SanitizedP";
import { ProjectsInput } from "../components/input/ProjectsInput";

const defaultValue = {
  on_recent_list: 1,
};

const validate = {
  title: [validation.required(), validation.minLength(2)],
  date_recorded: [validation.required()],
  language: [validation.required()],
  location: [validation.required()],
  speaker: [validation.required()],
  category: [validation.required()],
};

const ResendFollowingEmail = connectCommon(
  class ResendFollowingEmailButton extends React.PureComponent {
    handleClick = e => {
      e.preventDefault();
      e.stopPropagation();
      const {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
        showNotification,
      } = this.props;
      if (!window.confirm("Are you sure?")) {
        return;
      }
      fetchStart();
      authClient
        .post(`/admin/lectures/${id}/resend-following-email`, {})
        .then(() => {
          showNotification("Following email sent.");
        })
        .catch(showError)
        .finally(fetchEnd);
    };
    render() {
      return <Button onClick={this.handleClick}>Resend Following Email</Button>;
    }
  },
);

/**
 *
 * @param {Object} params
 * @param {number} params.lectureId - lecture id
 * @param {number} params.duration - lecture duration
 * @returns
 */
const UpdateThumbnail = params => {
  const { lectureId, duration } = params;

  const [thumbnailUpdateSeconds, setThumbnailUpdateSeconds] = useState(null);
  const [updatingThumbnail, setUpdatingThumbnail] = useState(false);

  const error = thumbnailUpdateSeconds > duration;
  return (
    <div>
      <MaterialTextField
        style={{
          maxWidth: 150,
        }}
        type="number"
        placeholder="seconds"
        onChange={event => setThumbnailUpdateSeconds(event.target.value)}
        value={thumbnailUpdateSeconds || ""}
        error={error}
        helperText={
          error ? "Can't be greater than duration of lecture" : undefined
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setThumbnailUpdateSeconds(
                    Math.floor(Math.random() * duration) + 1,
                  );
                  console.log("updating");
                }}
              >
                <Shuffle />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Button
        color="primary"
        variant="text"
        disabled={!thumbnailUpdateSeconds || updatingThumbnail}
        onClick={async () => {
          setUpdatingThumbnail(true);
          await authClient.post(`admin/lectures/${lectureId}/thumbnail`, {
            time: parseInt(thumbnailUpdateSeconds),
          });

          // eslint-disable-next-line no-restricted-globals
          location.reload();
          // Instead of this, we should figure out how to inject
          // the new thumbnail id into React Admin state.
        }}
      >
        Save
      </Button>
    </div>
  );
};

function CreateView(props) {
  return (
    <Create {...props}>
      <TabbedForm
        defaultValue={defaultValue}
        redirect={"create"}
        toolbar={
          <EditorToolbar
            registerTrigger={MediaUploader.setLectureActions}
            showSave={authUser.isAdministrator}
          />
        }
        submitOnEnter={false}
      >
        <FormTab label="Details" replace>
          <LongTextInput source="title" validate={validate.title} autoFocus />
          <DateInput source="date_recorded" validate={validate.date_recorded} />

          <LanguageInput validate={validate.language} />
          <LocationInput validate={validate.location} />
          <HostInput />
          <OrganizationInput />
          <SpeakerInput validate={validate.speaker} />
          <CategoryInput validate={validate.category} />
          <SubCategoryInput />
          <EventSpeakersInput />

          <BoolNumInput
            source="on_recent_list"
            label="Show on 'Recent Lectures' on Home Page"
            style={{ marginTop: 50 }}
            defaultValue={1}
          />
          <BoolNumInput
            source="auto_display"
            label="Auto Send Email and Publish When Encoding Completes"
            defaultValue={1}
          />
          <BoolNumInput source="no_download" label="Disable downloads" />
          <BoolNumInput
            source="ladies"
            label="Only Ladies can View After Login"
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

function EditView(props) {
  const {
    location: { pathname = "" },
    id,
  } = props;
  console.log(pathname);
  const [onRethink, setOnRethink] = useState(false);
  useEffect(() => {
    const tabIndex = pathname.split("/lectures/" + id).pop();
    if (tabIndex === "/2") {
      setOnRethink(true);
    } else {
      setOnRethink(false);
    }
  }, [id, pathname]);

  // return <EditGuesser {...props} />;
  return (
    <Edit {...props} undoable={false}>
      <TabbedForm
        redirect={false}
        toolbar={
          onRethink ? (
            <div></div>
          ) : (
            <EditorToolbar registerTrigger={MediaUploader.setLectureActions}>
              <ResendFollowingEmail toolbarProps />
            </EditorToolbar>
          )
        }
        submitOnEnter={false}
      >
        <FormTab label="Details" replace>
          <ProjectsInput />
          <UrlField
            source="url"
            label="Website"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="vimeo_url"
            label="Vimeo"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="download_url"
            label="Video File"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="audio_download_url"
            label="Audio File"
            target="_blank"
            rel="noopener noreferrer"
          />
          <FunctionField
            source="bitly_video"
            label="Video Shortlink"
            style={{ width: "inherit" }}
            render={record => {
              return (
                <div style={{ display: "flex" }}>
                  <UrlField
                    record={record}
                    source="bitly_video"
                    label="Video Shortlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  <CopyToClipboard textToCopy={record.bitly_video} />
                </div>
              );
            }}
          />
          <FunctionField
            source="bitly_audio"
            label="Audio Shortlink"
            style={{ width: "inherit" }}
            render={record => {
              return (
                <div style={{ display: "flex" }}>
                  <UrlField
                    record={record}
                    source="bitly_audio"
                    label="Audio Shortlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  <CopyToClipboard textToCopy={record.bitly_video} />
                </div>
              );
            }}
          />
          <SanitizedP>Originals are stored in box.</SanitizedP>
          <UrlField
            source="box_url"
            label="Box"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SanitizedP>Originals are kept in trash for a 30 days</SanitizedP>
          <UrlField
            source="cloud_trash_url"
            label="Trash"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="cloud_url"
            label="Originals"
            target="_blank"
            rel="noopener noreferrer"
          />
          <TextField source="original_file" />
          <TextInput source="thumbnail" />
          <ImageField source="thumbnail_url" />
          <FunctionField
            label="Change Thumbnail"
            render={record => (
              <UpdateThumbnail
                lectureId={record.id}
                duration={record.duration}
              />
            )}
          />

          <BoolNumInput source="display_active" label="Display" />
          <BoolNumInput
            source="auto_display"
            label="Auto Send Email and Publish When Encoding Completes"
          />
          <LongTextInput source="title" validate={validate.title} />
          <NumberInput source="duration" label="Duration (in seconds)" />
          <DateTimeInput source="date_created" />
          <DateInput source="date_recorded" validate={validate.date_recorded} />
          <LanguageInput validate={validate.language} />
          <LocationInput validate={validate.location} />
          <HostInput />
          <OrganizationInput />
          <SpeakerInput validate={validate.speaker} />
          <CategoryInput validate={validate.category} />
          <SubCategoryInput />
          <EventSpeakersInput />
          <BoolNumInput
            source="on_recent_list"
            label="Show on 'Recent Lectures' on Home Page"
            style={{ marginTop: 50 }}
          />
          <BoolNumInput source="no_download" label="Disable downloads" />
          <BoolNumInput
            source="ladies"
            label="Only Ladies can View After Login"
          />
          <BoolNumInput
            source="private"
            label="Private Lecture"
            style={{ marginBottom: -25 }}
          />
          <TextInput
            source="private_code"
            label="Private Lecture Access Code"
          />
          <LongTextInput source="dedication" label="Dedication message" />
        </FormTab>
        <FormTab label="Access Restriction Flags" replace>
          <BoolNumInput source="display_active" label="Display" />
          <BoolNumInput
            source="is_only_watchable_by_female"
            label="Only Watchable by Female"
          />
          <BoolNumInput
            source="is_only_listenable_by_female"
            label="Only Listenable by Female"
          />
          <BoolNumInput
            source="is_only_discoverable_by_female"
            label="Only Discoverable by Female"
          />
          <BoolNumInput
            source="is_only_discoverable_within_org"
            label="Only Discoverable within Org"
          />
          <BoolNumInput source="is_not_discoverable" label="Not Discoverable" />
        </FormTab>
        <FormTab label="Rethink Database" replace>
          <LectureRethink />
        </FormTab>
        <FormTab label="JewishAnytime" replace>
          <FunctionField
            label="JewishAnytime Thumbnail (this may take time to update)"
            render={record => (
              <>
                <img
                  src={`https://torahanytime-files.sfo2.cdn.digitaloceanspaces.com/assets/thumbnails/${record.id}.jpg`}
                  width={200}
                  title="ja thumnail"
                />
                <ImageInput
                  source="FILE_photo"
                  label="JewishAnytime Thumbnail Upload "
                  accept="image/*"
                  placeholder={
                    <p>
                      Click or drop a photo here. <br />
                      Size: 1280x720
                      <br />
                      (Youtube Thumbnail size)
                    </p>
                  }
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </>
            )}
          />
          <FunctionField
            label="Feature on JewishAnytime"
            render={record =>
              record.projects?.includes(2) ? (
                <>
                  <BoolNumInput source="is_featured_on_JA" label="Featured" />
                  <img
                    src={record.featured_banner}
                    width={600}
                    title="featured banner"
                  />
                  <ImageInput
                    source="FILE_banner"
                    label="Upload Banner"
                    accept="image/*"
                    placeholder={<p>Click or drop a full-size banner here.</p>}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </>
              ) : (
                <label>
                  Only JewishAnytime lectures can be featured on JewishAnytime
                </label>
              )
            }
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <RadioButtonGroupInput
        alwaysOn
        label="Display"
        source="display_active"
        initialValue=""
        options={{
          row: true,
        }}
        choices={[
          { id: "", name: "All" },
          { id: "1", name: "Active" },
          { id: "0", name: "Inactive" },
        ]}
      />
      <TextInput source="id" alwaysOn />
      <TextInput source="title" alwaysOn />
      <SpeakerInput
        label="Speaker"
        source="speaker"
        reference="speakers"
        alwaysOn
      />
      <OrganizationInput
        label="Organization"
        source="org"
        reference="organizations"
      />
      <CategoryInput
        label="Category"
        source="category"
        reference="categories"
      />
      <SubCategoryInput
        label="Sub-Category"
        source="subcategory"
        reference="categories"
      />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      filterDefaultValues={{ display_active: "" }}
      perPage={250}
      pagination={<MorePagination />}
      sort={{
        field: "id",
        order: "DESC",
      }}
      title="Lectures"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ImageField source="thumbnail_url" label="Thumbnail" />
        <TextField source="speaker_name" label="Speaker" />
        <TextField source="title" />
        <BooleanField source="display_active" label="Active"></BooleanField>
        <DateTimeField
          source="created_at"
          label="Published at"
          style={{ minWidth: 150 }}
        />
        <DateTimeField
          source="updated_at"
          label="Updated at"
          style={{ minWidth: 150 }}
        />
        <TextField source="published_by_email" label="Published by" />
      </Datagrid>
    </List>
  );
}

export const Lectures = {
  category: "database",
  name: "lectures",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Lectures" },
  edit: EditView,
  create: CreateView,
  roles: ["author"],
  permissions: {
    author: ["create"],
  },
};
