import {
  fetchEnd,
  fetchStart,
  refreshView,
  showNotification,
} from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";

function defaultMapState(state) {
  const { admin: { loading = 0 } = {} } = state;
  return {
    admin: {
      isLoading: loading > 0,
    },
  };
}

const defaultMapDispatch = {
  fetchEnd,
  fetchStart,
  push,
  refreshView,
  showNotification,
  showError(err) {
    console.error(err);
    return showNotification(err.message ? err.message : err, "warning");
  },
};
/** Connect with common actions.
 * @param {React.ComponentType} ViewComponent
 * @param {()=>any} [mapState]
 * @param {object | Function} [mapDispatch]
 */
export function connectCommon(ViewComponent, mapState, mapDispatch) {
  return connect(
    mapState || defaultMapState,
    mapDispatch || defaultMapDispatch,
  )(ViewComponent);
}
