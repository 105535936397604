import React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  LongTextInput,
  Edit,
  Create,
  SimpleForm,
  DisabledInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/AllInclusive";
import { ListActions, EditorToolbar } from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
      <TextInput source="id" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Packages"
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <TextField source="sort_score" label="Sort Score" />
        <TextField source="category" label="Category" />
        <TextField source="display_category" label="Display Category" />
        <TextField source="price" label="Amount" />
        <TextField source="monthly" label="Monthly Amount" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <DisabledInput source="id" label="Id" />
      <TextInput source="name" label="Name" />
      <LongTextInput source="description" label="Description" />
      <SelectInput
        label="Category"
        source="category"
        choices={[
          { id: "shiur", name: "Shiur" },
          { id: "website", name: "Website" },
          { id: "daily_dose", name: "Daily Dose" },
          { id: "corporate_sponsor", name: "Corporate Ssponsor" },
          { id: "executive_sponsor", name: "Executive Sponsor" },
          { id: "general", name: "General Donation" },
        ]}
      />
      <SelectInput
        label="Display Category"
        source="display_category"
        choices={[
          { id: "website", name: "Website" },
          { id: "executive_sponsor", name: "Executive Sponsor" },
          { id: "general", name: "General Donations" },
          { id: "none", name: "Dont Display" },
        ]}
      />
      <NumberInput source="sort_score" label="Sort Score" />
      <NumberInput source="price" label="Amount" />
      <NumberInput source="monthly" label="Monthly Amount" />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}
function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}
export const CampaignPackages = {
  category: "database",
  name: "campaign_packages",
  icon: resourceIcon,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Campaign Packages" },
};
