import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiToolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
import { Title } from "react-admin";
import { withRouter } from "react-router-dom";
// import classNames from "classnames";
import { connectCommon, getInputValue } from "../../components";
import { authClient } from "../../server";
import { FaApple } from "react-icons/fa";

const icon = () => <FaApple size={24} />;

class AppleAppSite extends React.Component {
  state = {
    data: "",
    changed: false,
  };

  loadState = res => {
    const data = JSON.stringify(res.json, null, 2);
    this.setState({
      data,
      changed: false,
    });
  };

  /**
   * @param {string} field The field name.
   * @param {(e:React.SyntheticEvent<HTMLInputElement>)=>any} [getValue] */
  onChangeField = (field, getValue = getInputValue) => {
    return (...args) => {
      const value = getValue(...args);
      this.setState({
        [field]: value,
        changed: true,
      });
    };
  };

  save = () => {
    const {
      state: { changed, data },
    } = this;
    const objData = JSON.parse(data);
    if (changed) {
      this.props.fetchStart();
      authClient
        .put("/admin/apple-app-site/data", objData)
        .then(this.loadState)
        .catch(this.props.showError)
        .finally(this.props.fetchEnd);
    }
  };

  componentDidMount() {
    this.props.fetchStart();
    authClient
      .get("/admin/apple-app-site/data")
      .then(this.loadState)
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
  }

  render() {
    const { state } = this;
    return (
      <Card>
        <Title title="Apple App Site" />
        <CardContent>
          <div>
            <TextField
              label="Association Data"
              placeholder="Association Data"
              value={state.data}
              onChange={this.onChangeField("data")}
              multiline={true}
              rows={25}
              style={{ width: "100%" }}
            />
          </div>
          <MuiToolbar role="toolbar">
            <Button
              variant="contained"
              color={state.changed ? "primary" : "default"}
              size="small"
              onClick={this.save}
              // className={css.saveButton}
            >
              Save
            </Button>
          </MuiToolbar>
        </CardContent>
      </Card>
    );
  }
}

export const AppleAppSitePage = {
  menu: {
    category: "misc",
    icon,
    name: "apple-app-site",
    label: "Apple App Site",
    url: "/apple-app-site",
  },
  route: {
    exact: true,
    view: withRouter(connectCommon(AppleAppSite)),
  },
};
