import React from "react";
import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { filterByName } from "../utils";

export class EventSpeakersInput extends React.PureComponent {
  render() {
    const {
      allowEmpty = true,
      label = "Event Speakers",
      source = "event_speakers",
      reference = "speakers",
      filterToQuery = filterByName,
      perPage = 100,
      // sort = { field: "name", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceArrayInput
        allowEmpty={allowEmpty}
        label={label}
        source={source}
        reference={reference}
        filterToQuery={filterToQuery}
        perPage={perPage}
        // sort={sort}
        {...rest}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    );
  }
}
