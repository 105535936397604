import React from "react";
import { Link } from "react-router-dom";
import {
  Create,
  SimpleForm,
  Datagrid,
  Filter,
  List,
  Show,
  TextField,
  TextInput,
  BooleanInput,
  FunctionField,
  Mutation,
  DateField,
  DateInput,
} from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Tooltip,
} from "@material-ui/core";
import { CheckCircle, Launch } from "@material-ui/icons";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
  validation,
} from "../../components";
import SanitizedP from "../../components/field/SanitizedP";
import format from "date-fns/format";
import { CopyToClipboard } from "../../components";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutline";

import { openPreview } from "./helpers";

const validate = {
  required: [validation.required()],
};

const imgNameMessage =
  "Image name with extension goes here (e.g. test.png). The image must be on https://cloud.digitalocean.com/spaces/torahanytime-cdn?i=955929&path=images%2F. Alternatively, an icon can be specified.";
function CreateView(props) {
  return (
    <Create {...props} title="Upload Clip">
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
      </SimpleForm>
    </Create>
  );
}

const DedicationsAddedButton = ({ messageDate }) => {
  const payload = { id: messageDate.id, data: { added: !messageDate.added } };
  return (
    <Mutation
      type="UPDATE"
      resource="manage-corp-sponsor-dates"
      payload={payload}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "doseDonors.updated",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "doseDonors.failed",
            level: "warning",
          },
        },
      }}
    >
      {approve => {
        const props = {
          style: { cursor: "pointer" },
          onClick: approve,
        };
        return (
          <Tooltip
            enterDelay={1}
            enterTouchDelay={1}
            title={messageDate.added ? "Un-add" : "Add"}
          >
            {messageDate.added ? (
              <RemoveCircleOutlineOutlinedIcon {...props} />
            ) : (
              <AddCircleOutlineOutlinedIcon {...props} />
            )}
          </Tooltip>
        );
      }}
    </Mutation>
  );
};

const DateWithAction = ({ date }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: 75,
    }}
  >
    <span style={{ color: date.added ? "gray" : undefined }}>
      {format(new Date(date.date), "MM/dd")}
    </span>
    <DedicationsAddedButton messageDate={date} />
  </div>
);

const SetPaymentCompleteButton = ({ record }) => {
  const payload = { id: record.id, data: { payment_status: "complete" } };
  return (
    <Mutation
      type="UPDATE"
      resource="update-dose-donor"
      payload={payload}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "doseDonors.updated",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "doseDonors.failed",
            level: "warning",
          },
        },
      }}
    >
      {approve => (
        <Tooltip enterDelay={1} enterTouchDelay={1} title="Mark as complete">
          <CheckCircle
            variant="outlined"
            color="primary"
            size="small"
            label="Add"
            onClick={approve}
            style={{ marginLeft: 5, height: 20 }}
            cursor="pointer"
          />
        </Tooltip>
      )}
    </Mutation>
  );
};

function ShowView(props) {
  return (
    <Show {...props}>
      <RenderShow />
    </Show>
  );
}
function RenderRow({ k, v }) {
  return (
    typeof v === "string" && (
      <TableRow style={{ height: 30 }}>
        <TableCell
          style={{
            width: "15%",
            padding: 5,
          }}
        >
          {k.includes("attachment") ? k.substring(0, 18) : k}
        </TableCell>
        <TableCell
          style={{
            padding: 5,
          }}
        >
          {k === "created_at" || k === "updated_at" ? (
            new Date(v).toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ) : k === "slide_1_attachment_fulllink" ||
            k === "slide_2_attachment_fulllink" ? (
            <a href={v} rel="noopener noreferrer" target="_blank">
              {v}
            </a>
          ) : (
            v
          )}
          {(k === "message_text" ||
            k === "slide_1_text" ||
            k === "slide_2_text") && (
            <CopyToClipboard style={{ display: "inline" }} textToCopy={v} />
          )}
        </TableCell>
      </TableRow>
    )
  );
}
function formatAndJoinDates(dates) {
  return dates.map(d => format(new Date(d.date), "MM/dd")).join(", ");
}

function RenderShow(props) {
  const { record } = props;

  function statusKeysToFilter(key) {
    if (
      !record.corporate_dose_sponsor_status[key] &&
      record.corporate_dose_sponsor_status[key] !== false &&
      record.corporate_dose_sponsor_status[key] !== 0
    ) {
      return false;
    }

    const keysToAvoid = new Set([
      "created_at",
      "updated_at",
      "deleted_at",
      "slide_1_type",
      "slide_2_type",
      "slide_1_attachment_link",
      "slide_2_attachment_link",
    ]);
    return !keysToAvoid.has(key);
  }

  const [corporate_email_message] = record.corporate_email_messages;

  return (
    <div style={{ marginLeft: 50 }}>
      {corporate_email_message && (
        <div>
          <h1>Email Info</h1>
          <Table>
            <TableBody aria-label="simple table">
              <TableRow>
                <TableCell>
                  <div>
                    <a
                      onClick={() => openPreview(corporate_email_message.html)}
                      href="#"
                    >
                      Open preview
                    </a>
                    <CopyToClipboard
                      style={{ display: "inline" }}
                      textToCopy={corporate_email_message.html}
                    />
                  </div>
                </TableCell>
              </TableRow>

              <RenderRow
                k="dates"
                v={formatAndJoinDates(
                  record.dose_corporate_sponsorship_dates.filter(
                    d => d.type === "email",
                  ),
                )}
              />
            </TableBody>
          </Table>
        </div>
      )}
      {record.corporate_dose_sponsor_message && (
        <div>
          <h1>Message Info</h1>
          <Table aria-label="simple table">
            <TableBody>
              {Object.keys(record.corporate_dose_sponsor_message)
                .filter(
                  k =>
                    record.corporate_dose_sponsor_message[k] &&
                    k !== "created_at" &&
                    k !== "updated_at" &&
                    k !== "deleted_at",
                )
                .map(key => (
                  <RenderRow
                    key={key}
                    k={key}
                    v={record.corporate_dose_sponsor_message[key]}
                  />
                ))}
              <RenderRow
                k="dates"
                v={formatAndJoinDates(
                  record.dose_corporate_sponsorship_dates.filter(
                    d => d.type === "message",
                  ),
                )}
              />
            </TableBody>
          </Table>
        </div>
      )}
      {record.corporate_dose_sponsor_status && (
        <div>
          <h1>Status Info</h1>
          <Table aria-label="simple table">
            <TableBody>
              {Object.keys(record.corporate_dose_sponsor_status)
                .filter(statusKeysToFilter)
                .map(key => (
                  <RenderRow
                    key={key}
                    k={key}
                    v={record.corporate_dose_sponsor_status[key]}
                  />
                ))}
              <RenderRow
                k="dates"
                v={formatAndJoinDates(
                  record.dose_corporate_sponsorship_dates.filter(
                    d => d.type === "status",
                  ),
                )}
              />
            </TableBody>
          </Table>
        </div>
      )}

      <h1>Donor</h1>
      <Table aria-label="simple table">
        <TableBody>
          {Object.keys(record)
            .filter(k => k !== "right_x_phone")
            .map(key => (
              <RenderRow
                key={key}
                k={key}
                v={
                  key !== "total_amount" && key !== "additional_amount"
                    ? record[key]
                    : parseInt(record[key]).toString()
                }
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <DateInput label="From" source="dedication_date" alwaysOn />
      <TextInput source="email" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Corporate Dose Sponsors"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid
        rowStyle={(record, index) => ({
          backgroundColor: record.added
            ? "#f0f0f0"
            : record.payment_status === "complete"
            ? "#d6ffe1"
            : record.payment_status === "initiated"
            ? "#dbf2ff"
            : record.payment_status === "pending"
            ? "lightyellow"
            : record.payment_status === "user cancelled" ||
              record.payment_status === "declined" ||
              record.payment_status === "payment error"
            ? "#ffe3e3"
            : null,
        })}
      >
        <FunctionField
          render={record => {
            const { id } = record;
            return (
              <Button
                component={Link}
                to={`/corporate-dose-sponsors/${id}/show`}
                color="primary"
              >
                View
              </Button>
            );
          }}
        />
        <TextField source="id" />
        <FunctionField
          label="Total Amount"
          render={record => parseFloat(record.total_amount || 0).toFixed()}
        />
        <FunctionField
          label="Additional Donation Amount"
          render={record => parseFloat(record.additional_amount || 0).toFixed()}
        />
        <TextField source="currency_id" label="Currency" />
        <TextField source="payment_type" label="Payment Type" />
        <FunctionField
          label="Type"
          render={record => {
            // I don't know why this value is an array, but let's
            // unpack it
            const [corporate_email_message] = record.corporate_email_messages;

            let message = "";
            const messageParts = [];

            if (record.corporate_dose_sponsor_message) {
              messageParts.push("message");
            }
            if (record.corporate_dose_sponsor_status) {
              messageParts.push("status");
            }
            if (corporate_email_message) {
              messageParts.push("email");
            }

            if (messageParts.length > 0) {
              message = `dose:\n${messageParts.join(",\n")}`;
            }

            return <p>{message}</p>;
          }}
        />
        <FunctionField
          label="Payment Status"
          render={record => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{record.payment_status}</span>
              {record.payment_status !== "complete" && (
                <SetPaymentCompleteButton record={record} />
              )}
            </div>
          )}
        />
        <FunctionField
          label="Email Dates"
          render={record => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {record.dose_corporate_sponsorship_dates
                  .filter(d => d.type === "email")
                  .map((d, _i) => (
                    <DateWithAction key={_i} date={d} />
                  ))}
              </span>
            </div>
          )}
        />
        <FunctionField
          label="Message Dates"
          render={record => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {record.dose_corporate_sponsorship_dates
                  .filter(d => d.type === "message")
                  .map((d, _i) => (
                    <DateWithAction key={_i} date={d} />
                  ))}
              </span>
            </div>
          )}
        />
        <FunctionField
          label="Status Dates"
          render={record => (
            <div>
              {record.dose_corporate_sponsorship_dates
                .filter(d => d.type === "status")
                .map((d, _i) => (
                  <DateWithAction key={_i} date={d} />
                ))}
            </div>
          )}
        />
        <TextField source="phone" label="Phone" />
        <TextField source="city" label="city" />
        <TextField source="state" label="state" />
        <TextField source="country" label="Country" />
        <FunctionField
          label="Lucky Orange"
          render={record =>
            record.ip && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.luckyorange.com/v3/dash.php?rwt=&f=${record.ip}#/recordings`}
              >
                <Launch />
              </a>
            )
          }
        />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
      </Datagrid>
    </List>
  );
}

export const CorporateDoseSponsor = {
  category: "corporate_sponsors",
  name: "corporate-dose-sponsors",
  list: ListView,
  show: ShowView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Corporate Dose Sponsors" },
  roles: ["corporate_sponsor_admin"],
  permissions: {
    corporate_sponsor_admin: ["list", "show"],
  },
};
