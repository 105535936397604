import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/Slideshow";

export const ManageBannersSlideshowPage = makeSortableFormPage({
  createItem() {
    return {
      image: "",
      link: "",
      onclick: "",
      title: "",
      html: "",
    };
  },
  fetch() {
    return authClient.get("/admin/manage-banners/slideshow");
  },
  getInitialState() {
    return {
      auto_start: false,
      interval: 0,
      items: [],
      undoing: false,
    };
  },
  load(res) {
    const data = res.json;
    return {
      auto_start: data.auto_start ? true : false,
      interval: data.interval / 1000,
      items: data.slides.map(item => {
        if (!item.html) {
          item.html = "";
        }
        return item;
      }),
      changed: false,
    };
  },
  menu: {
    category: "banners",
    icon,
    name: "slideshow-banners",
    label: "Slideshow",
    url: "/manage-banners/slideshow",
  },
  renderFields({ actions, /* css, */ state }) {
    const { onChangeField } = actions;
    return (
      <>
        <TextField
          style={{ marginLeft: 125, width: 280 }}
          type="number"
          label="Seconds to display each message"
          value={state.interval}
          onChange={onChangeField(
            "interval",
            e => e.currentTarget.valueAsNumber || 0,
          )}
          InputLabelProps={{ shrink: !!state.interval }}
        />
        <FormControlLabel
          style={{ marginLeft: 20 }}
          control={
            <Checkbox
              checked={state.auto_start}
              onChange={onChangeField(
                "auto_start",
                e => e.currentTarget.checked,
              )}
            />
          }
          label="Auto-start slideshow rotation"
        />
        <br />
        <br />
        <FormControlLabel
          style={{ marginLeft: 110, userSelect: "none" }}
          control={
            <Checkbox
              checked={state.undoing}
              onChange={onChangeField("undoing", e => e.currentTarget.checked)}
            />
          }
          label="Toggle Save / Undo"
          title="Check or uncheck to enable save button, if disabled, to save an old version of the form data."
        />
      </>
    );
  },
  renderItem({ actions, css, focusedField, item, rowIndex }) {
    const { onChangeItem, setFocusedField } = actions;
    return (
      <>
        <TextField
          label=""
          placeholder="Image URL"
          value={item.image}
          onChange={onChangeItem(rowIndex, "image")}
          onBlur={() => setFocusedField("")}
          onFocus={() => setFocusedField("image")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Title"
          value={item.title}
          onChange={onChangeItem(rowIndex, "title")}
          onBlur={() => setFocusedField("")}
          onFocus={() => setFocusedField("title")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Link URL"
          value={item.link}
          onChange={onChangeItem(rowIndex, "link")}
          onBlur={() => setFocusedField("")}
          onFocus={() => setFocusedField("link")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="JS onclick function()"
          value={item.onclick}
          onChange={onChangeItem(rowIndex, "onclick")}
          onBlur={() => setFocusedField("")}
          onFocus={() => setFocusedField("onclick")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="HTML"
          value={item.html}
          onChange={onChangeItem(rowIndex, "html")}
          onBlur={() => setFocusedField("")}
          onFocus={() => setFocusedField("html")}
          className={css.rowField}
          multiline={true}
          rows={focusedField === "html" ? 9 : 2}
        />
      </>
    );
  },
  save(state) {
    const data = {
      auto_start: state.auto_start ? 1 : 0,
      interval: state.interval * 1000,
      // TODO: Remove slides where all the fields are empty...
      slides: state.items, // .filter(item => ("" + item).trim().length > 0),
    };
    return authClient.put("/admin/manage-banners/slideshow", data);
  },
  subtitle: (
    <>
      NOTE: If <strong>HTML</strong> Code is set, it will be displayed instead
      of the <strong>Image</strong>.
    </>
  ),
  title: "Slideshow Banners",
});
