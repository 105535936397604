import React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  List,
  NumberField,
  TextField,
} from "react-admin";
import resourceIcon from "@material-ui/icons/AccountBox";
import {
  formatDateTimeInput,
  ListActions,
  MorePagination,
} from "../../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      {/* <TextInput source="email" alwaysOn />
      <TextInput source="user_name" alwaysOn /> */}
      <DateInput
        label="From"
        source="date_start"
        alwaysOn
        format={formatDateTimeInput}
      />
      <DateInput
        label="To"
        source="date_end"
        alwaysOn
        format={formatDateTimeInput}
      />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={100}
      pagination={<MorePagination />}
      sort={{ field: "total", order: "DESC" }}
      title="Speaker Stats"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="expand">
        <TextField source="name" style={{ minWidth: 180 }} />
        <NumberField source="total" />
        <NumberField source="video" />
        <NumberField source="audio" />
        <NumberField source="download" />
        <NumberField source="phone" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

export const SpeakerStats = {
  category: "stats",
  name: "stats-speakers",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Speaker Stats" },
  roles: ["stat_viewer"],
  permissions: {
    stat_viewer: ["list"],
  },
};
