import React from "react";
import {
  Filter,
  // DateTimeInput,
  // DateInput,
  // TextInput,
  SelectInput,
  LongTextInput,
  Edit,
  Create,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  // DateField,
  // FunctionField,
} from "react-admin";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import { EditorToolbar, UrlTextField } from "../../components";

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <LongTextInput source="question" label="Question" />
      <LongTextInput source="answer" label="Answer" />
      <LongTextInput source="video_answer" label="Video Tutorial Url" />
      <SelectInput
        label="Display On"
        source="displayOn"
        choices={[
          { id: "both", name: "Both" },
          { id: "web", name: "Website" },
          { id: "app", name: "App" },
        ]}
        defaultValue="both"
      />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} title="FAQ">
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <SelectInput
        label="Display On"
        source="displayOn"
        choices={[
          { id: "both", name: "Both" },
          { id: "web", name: "Website" },
          { id: "app", name: "App" },
        ]}
        alwaysOn
      />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      // actions={<ListActions />}
      exporter={false}
      filters={<FiltersView />}
      title="News & announcements"
      // bulkActionButtons={false}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="question" label="Question" />
        <TextField source="answer" label="Answer" />
        <TextField source="displayOn" label="Display On" />
        <TextField source="video_answer" label="Video Tutorial" />
      </Datagrid>
    </List>
  );
}

export const FAQ = {
  category: "manage",
  name: "manage-faq",
  edit: EditView,
  create: CreateView,
  list: ListView,
  icon: LiveHelpIcon,
  options: { label: "FAQ" },
};
