import React from "react";
import {
  CardActions,
  Button,
  BooleanInput,
  BooleanField,
  Create,
  Datagrid,
  Edit,
  FileField,
  FunctionField,
  UrlField,
  FileInput,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  TextField,
  TextInput,
  ReferenceField,
  SelectInput,
  TabbedForm,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import resourceIcon from "@material-ui/icons/AccountBox";
import {
  BoolNumField,
  BoolNumInput,
  EditorToolbar,
  ListActions,
  OrganizationInput,
  SpeakerTitleInput,
  validation,
  CopyToClipboard,
} from "../components";
import { apiURL, authToken } from "../server";
import { SpeakerRethink } from "../pages/speakers/SpeakerRethink";

function followersExporter(speakerId) {
  return e => {
    e.preventDefault();
    // window.alert(`Exporting followers of speaker id ${speakerId}...`);
    const token = authToken();
    let url = apiURL(
      `/admin/speakers/${speakerId}/followers/export?` +
        `ua_token=${encodeURIComponent(token)}`,
    );
    // NOTE: The export endpoint MUST set the Content-Disposition, e.g.:
    // .header("Content-Disposition", `attachment;filename=${filename}`)
    // in order to prompt the browser to start downloading the file.
    window.location = url;
  };
}

const EditActions = props => {
  const { data: { id, follower_count = 0 } = {} } = props;
  return (
    <CardActions>
      <Button
        color="primary"
        onClick={followersExporter(id)}
        label={`Export Followers (${follower_count}) Emails`}
        title="Exports the emails of all followers..."
      />
    </CardActions>
  );
};

function renderEditForm({ redirect }) {
  return (
    <TabbedForm redirect={redirect} toolbar={<EditorToolbar />}>
      <FormTab label="Summary" replace>
        <ImageField source="photo_url" label="Photo" />
        <TextField source="follower_count" />
        <SpeakerTitleInput />
        <TextInput
          source="name_first"
          label="First name"
          validate={[validation.required()]}
        />
        <TextInput
          source="name_last"
          label="Last name"
          validate={[validation.required()]}
        />
        <OrganizationInput allowEmpty />
        <LongTextInput source="photo" />
        <LongTextInput source="speaker_audio_url" allowEmpty />
        <FunctionField
          source="short_link"
          label="Shortlink"
          style={{ width: "inherit" }}
          render={record => {
            return (
              <div style={{ display: "flex" }}>
                <UrlField
                  record={record}
                  source="short_link"
                  label="Shortlink"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <CopyToClipboard textToCopy={record.short_link} />
              </div>
            );
          }}
        />
        <FileInput
          source="FILE_speaker_audio_url"
          label="Audio Upload For Xchange Telecom"
          placeholder={<p>Click or drop an audio here.</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput
          source="FILE_photo"
          label="Photo Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BoolNumInput source="display_active" label="Display" />
        <BoolNumInput source="female" label="Female" />
        <BoolNumInput source="is_guest" label="Guest Speaker" />
        <BoolNumInput source="login_access" label="Only viewable after login" />
        <BoolNumInput source="no_download" label="Disable downloads" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.female ? null : (
              <SelectInput
                source="view_female_level"
                label="View Female Level"
                choices={[
                  {
                    id: 1,
                    name: "Default (No image. Audio only for male viewers)",
                  },
                  { id: 2, name: "Invisible to male viewers" },
                ]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <TextField source="dial_in_ext" label="Dial-in extension" />
        <BooleanInput
          source="display_dial_in_ext"
          label="Display dial-in extension"
        />
        <BooleanInput source="ya_approved" label="YeshivaAnytime approved" />
      </FormTab>
      <FormTab label="Description" replace>
        <RichTextInput source="desc" addLabel={false} />
      </FormTab>

      <FormTab label="Dose" replace>
        <ImageField source="dose_email_photo_url" label=" Dose Email Photo" />
        <ImageInput
          source="FILE_dose_email_photo"
          label="Upload Email Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="double_dose_email_photo_url"
          label="Double Dose Email Photo"
        />
        <ImageInput
          source="FILE_double_dose_email_photo"
          label="Upload Double Dose Email Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="dose_podcast_photo_url"
          label=" Dose Podcast Photo"
        />
        <ImageInput
          source="FILE_dose_podcast_photo"
          label="Upload Podcast Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="dose_instagram_photo_url"
          label=" Dose Instagram Photo"
        />

        <ImageInput
          source="FILE_dose_instagram_photo"
          label="Upload Instagram Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField source="dose_app_photo_url" label=" Dose App Photo" />
        <ImageInput
          source="FILE_dose_app_photo"
          label="Upload App Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>

      <FormTab label="Lecture promo" replace>
        <RichTextInput source="lecture_promo" addLabel={false} />
      </FormTab>
      <FormTab label="Rethink Database" replace>
        <SpeakerRethink />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false} actions={<EditActions />}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <BoolNumInput label="Active" source="display_active" alwaysOn />
      <BoolNumInput label="YA Approved" source="ya_approved" />
      <TextInput source="id" alwaysOn />
      <TextInput label="First Name" source="name_first" alwaysOn />
      <TextInput label="Last Name" source="name_last" alwaysOn />
      <OrganizationInput
        label="Organization"
        source="org"
        reference="organizations"
        alwaysOn
        allowEmpty
      />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={<FiltersView />}
      title="Speakers"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <ImageField source="photo_url" label="Photo" />
        <ReferenceField
          label="Title"
          source="title"
          reference="speaker-titles"
          linkType={false}
          allowEmpty={true}
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="name_first" label="First name" />
        <TextField source="name_last" label="Last name" />
        {/* <NumberField source="follower_count" label="Followers" /> */}
        <BoolNumField source="login_access" label="Login access" />
        <BoolNumField source="display_active" label="Active" />
        <BoolNumField source="female" label="Female" />
        <BoolNumField source="is_guest" label="Guest" />
        <BoolNumField source="no_download" label="No download" />
        <BooleanField source="ya_approved" label="YA Approved" />
        <ReferenceField
          label="Organization"
          source="org"
          reference="organizations"
          linkType={false}
          allowEmpty={true}
        >
          <TextField source="name" />
        </ReferenceField>

        {/* <LongTextField source="lecture_promo" /> */}
        {/* <RichTextField source="desc" /> */}
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Speakers = {
  category: "database",
  name: "speakers",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
};
