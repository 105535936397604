import { DateField } from "react-admin";

export class DateTimeField extends DateField {
  static defaultProps = {
    addLabel: true,
    locale: "en-US",
    options: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    showTime: true,
  };
}
