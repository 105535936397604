import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  UrlField,
  List,
  NumberField,
  SimpleForm,
  TextField,
  LongTextInput,
  ImageInput,
  BooleanInput,
  TextInput,
  required,
  regex,
} from "react-admin";

import resourceIcon from "@material-ui/icons/AttachMoney";
import { EditorToolbar, BoolNumField, WideCard } from "../components";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <ImageField source="img" sortable={false} />
        <BoolNumField source="display_active" />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" defaultValue={0} />
      <TextInput source="name" validate={required()} />
      <br />
      <br />
      <h4>Image</h4>
      <p>Image aspect ratio 1:1 (square)</p>
      <LongTextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const TbavSponsors = {
  category: "tbav",
  name: "tisha_bav_sponsors",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Sponsors" },
};
