import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Title";
import { EditorToolbar, ListActions } from "../components";

const EditTitle = ({ record }) => <span>{`Speaker Title #${record.id}`}</span>;

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <TextInput source="title" label="Title" />
      <TextInput source="title_short" label="Short title" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false} title={<EditTitle />}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      perPage={25}
      sort={{ field: "title", order: "ASC" }}
      title="Speaker Titles"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" label="Title" />
        <TextField source="title_short" label="Short title" />
        <NumberField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const SpeakerTitles = {
  category: "database",
  name: "speaker-titles",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Speaker Titles" },
};
