import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  ReferenceInput,
  TextInput,
  LongTextInput,
  AutocompleteInput,
  DisabledInput,
  SelectInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
} from "react-admin";
import { change } from "redux-form";
import JSONInput from "react-json-editor-ajrm";

import { Button, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  EditorToolbar,
  SubCategoryInput,
  validation,
  ListActions,
  BoolNumInput,
} from "../components";

const defaultValue = {
  replace: 1,
};

const TypeName = ({ record }) => {
  const split = record.type && record.type.split("_");
  if (!split || !split.length) {
    return "";
  }
  return (
    <Typography>
      {split[0]} {split[1]}
    </Typography>
  );
};

const CustomName = ({ record = {}, source }) => {
  if (record.type_id !== 1) {
    return <Typography>{record.display_name}</Typography>;
  }
  const data = record[source];
  return data.title ? (
    <Typography>{data.title}</Typography>
  ) : data.tag ? (
    <Typography>Vast Tag</Typography>
  ) : null;
};
const CustomValue = ({ record = {}, source }) => {
  if (record.type_id !== 1 && record.value) {
    return <pre>{JSON.stringify(record.value)}</pre>;
  }
  const data = record[source];
  return data.title ? (
    <Typography>{data.display_title}</Typography>
  ) : data.tag ? (
    <Typography>{data.tag}</Typography>
  ) : null;
};

const DataConsumer = props => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, dispatch, ...rest }) => {
        if (formData.type_id === 4) {
          return (
            <>
              <DisabledInput
                style={{ display: "none" }}
                source="name"
                defaultValue="vast_tag"
              />
              <LongTextInput source="value.tag" label="Vast tag" />
              <BoolNumInput source="value.active" label="Active" />
            </>
          );
        }
        return (
          <>
            <TextInput
              source="display_name"
              style={{
                display: "block",
                width: 256,
              }}
            />
            {formData.type_id === 1 && (
              <SubCategoryInput
                filter={{
                  parent:
                    formData.name === "weekly_parsha"
                      ? 23
                      : formData.name === "upcoming_holiday"
                      ? 8
                      : undefined,
                }}
                source="value.id"
                label="Category"
                validate={[validation.required()]}
                {...rest}
              />
            )}
            {formData.type_id === 2 && (
              <>
                <DisabledInput
                  style={{ display: "none" }}
                  source="name"
                  defaultValue="featured_series"
                />
                <ReferenceInput
                  source="value.id"
                  reference="series"
                  label="Series"
                  validate={[validation.required()]}
                  filterToQuery={searchText => ({ title: searchText })}
                  style={{ width: 256 }}
                  {...rest}
                >
                  <AutocompleteInput optionText="title" />
                </ReferenceInput>
              </>
            )}
            {formData.type_id === 3 && (
              <>
                <DisabledInput
                  style={{ display: "none" }}
                  source="name"
                  defaultValue="featured_org"
                />
                <ReferenceInput
                  source="value.id"
                  reference="organizations"
                  label="Organizations"
                  validate={[validation.required()]}
                  filterToQuery={searchText => ({ name: searchText })}
                  style={{ width: 256 }}
                  {...rest}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </>
            )}
            {(formData.type_id === 5 || formData.type_id === 6) && (
              //<FunctionInput render={r => <SettingsInput record={r} />} />
              <>
                <JSONInput
                  height={350}
                  onKeyPressUpdate={false}
                  placeholder={
                    typeof formData.value !== "object"
                      ? JSON.parse(formData.value)
                      : formData.value
                  }
                  onChange={c => {
                    console.log(c);
                    if (c.error === false) {
                      return dispatch(change(REDUX_FORM_NAME, "value", c.json));
                    }
                  }}
                />
                {/*Hack to check if done so that it will reformat*/}
                <Button>Done Editing</Button>
              </>
            )}
          </>
        );
      }}
    </FormDataConsumer>
  );
};

function FiltersView(props) {
  return (
    <Filter {...props}>
      <NumberInput label="ID" source="id" alwaysOn />
      <TextInput label="Name" source="display_name" alwaysOn />
      <SelectInput
        label="Active"
        source="display_active"
        alwaysOn
        choices={[{ id: 1, name: "active" }, { id: 0, name: "Not Active" }]}
      ></SelectInput>
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      filters={<FiltersView />}
      sort={{ field: "name", order: "ASC" }}
      // bulkActionButtons={false}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <NumberField source="display_active" label="Active" />
        <NumberField source="is_public" label="Public" />
        <CustomName source="value" label="Name" />
        <CustomValue source="value" label="Value" />
      </Datagrid>
    </List>
  );
}

const RenderCreateForm = ({ redirect, ...rest }) => {
  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
      defaultValue={defaultValue}
      {...rest}
    >
      <ReferenceInput
        source="type_id"
        reference="settings_types"
        label="Setting Type"
      >
        <SelectInput optionText={<TypeName />} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.type_id === 1 && (
              <>
                <SelectInput
                  source="name"
                  label="Type"
                  choices={[
                    {
                      value: "weekly_parsha",
                      name: "Weekly Parsha",
                    },
                    { value: "upcoming_holiday", name: "Upcoming Holidays" },
                  ]}
                  optionText="name"
                  optionValue="value"
                  {...rest}
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
      <DataConsumer />
      <BoolNumInput
        source="replace"
        label="Replace the current record"
        style={{ marginTop: 50 }}
      />
      <BoolNumInput
        source="display_active"
        label="Active"
        style={{ marginTop: 50 }}
      />
      <BoolNumInput
        source="is_public"
        label="Public"
        style={{ marginTop: 50 }}
      />
    </SimpleForm>
  );
};

function CreateView(props) {
  return <Create {...props}>{<RenderCreateForm redirect="list" />}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <DisabledInput source="id" />
        <BoolNumInput
          source="display_active"
          label="Active"
          style={{ marginTop: 50 }}
        />
        <BoolNumInput
          source="is_public"
          label="Public"
          style={{ marginTop: 50 }}
        />
        <ReferenceInput
          source="type_id"
          reference="settings_types"
          label="Setting Type"
          validate={[validation.required()]}
          disabled
        >
          <SelectInput optionText={<TypeName />} />
        </ReferenceInput>
        <DataConsumer />
      </SimpleForm>
    </Edit>
  );
}

export const Settings = {
  category: "tanext",
  name: "settings",
  create: CreateView,
  edit: EditView,
  icon: SettingsIcon,
  list: ListView,
  options: { label: "Settings" },
};

export const SettingsTypes = {
  name: "settings_types",
  hidden: true,
};
