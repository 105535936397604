import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MuiToolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Title } from "react-admin";
import { withRouter } from "react-router-dom";
import { authClient } from "../server";
import { connectCommon } from "../components";
import { getIdRouteParam } from "../state/selectors";

// WARNING: This page is not stateless. It depends on Redux state that
// was loaded on the previous page.

// TODO: Warn the user to not refresh. Or convert this page into a Modal
// or an aside or put it in a Drawer like the user_comments editor.

const SortableItem = SortableElement(({ value }) => {
  return (
    <div
      style={{
        height: 42,
        outline: "1px solid #eee",
        paddingLeft: 150,
        lineHeight: "42px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: ".875rem",
        zIndex: 500,
        cursor: "pointer",
      }}
    >
      {value}
    </div>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value = {}, index) => (
        <SortableItem
          key={value.id || -index}
          index={index}
          value={value.title}
          show={value.show}
          custom={value.is_custom}
        />
      ))}
    </div>
  );
});

class LayoutCellsReorder extends Component {
  state = {
    items: [],
    changed: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.banners && props.banners.length !== state.items.length) {
      // console.log("DERIVED STATE");
      return {
        items: [...props.banners],
        changed: false,
      };
    }
    // console.log("NO DERIVED STATE");
    return null;
  }

  saveRows = async () => {
    if (this.state.changed) {
      let rows = [...this.state.items];
      const rowArray = rows.map(row => row.id);
      const { fetchStart, layoutId } = this.props;
      fetchStart();
      await authClient
        .put(`admin/layouts/${layoutId}/reorder`, rowArray)
        .catch(this.props.showError)
        .finally(this.props.fetchEnd);
      this.props.history.replace("/layouts");
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    // console.log("MOVING: ", oldIndex, newIndex);
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
      changed: true,
    }));
  };

  restoreDefault = () => {
    this.setState({ items: [...this.props.banners], changed: false });
  };

  render() {
    const {
      state: { items, changed },
      props: { layoutId },
      onSortEnd,
      restoreDefault,
      saveRows,
    } = this;
    return (
      <Card>
        <Title title="Reorder Rows" />
        <CardContent style={{ padding: 0 }}>
          <div
            style={{
              lineHeight: "100px",
              fontWeight: 600,
              fontSize: 20,
              color: "#555",
              paddingLeft: 150,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            Drag rows to reorder, then click "Save."
          </div>
          <SortableList
            items={items}
            onSortEnd={onSortEnd}
            hideSortableGhost={true}
          />
          <MuiToolbar
            role="toolbar"
            style={{
              backgroundColor: "white",
              marginTop: 0,
              justifyContent: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color={changed ? "primary" : "default"}
              size="small"
              onClick={saveRows}
              style={{ margin: 10 }}
            >
              Save
            </Button>
            <Link
              to={`/layouts/${layoutId}/1`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 10 }}
              >
                Cancel
              </Button>
            </Link>
            <Button
              variant="contained"
              color="default"
              size="small"
              onClick={restoreDefault}
              style={{ margin: 10 }}
            >
              Restore Default Order
            </Button>
          </MuiToolbar>
        </CardContent>
      </Card>
    );
  }
}

export const LayoutCellsReorderPage = {
  route: {
    exact: true,
    path: "/layouts/:id/reorder",
    view: withRouter(
      connectCommon(LayoutCellsReorder, (state, props) => {
        // console.log("STATE: ", state);
        // console.log("PROPS: ", props);
        const layoutId = getIdRouteParam(props);
        // console.log("LAYOUT ID: ", layoutId);
        const {
          admin: {
            loading = 0,
            resources: {
              layouts: {
                data: { [layoutId]: layout },
              },
              banners: {
                data: {
                  [layout.cell_one]: banner1,
                  [layout.cell_two]: banner2,
                  [layout.cell_three]: banner3,
                  [layout.cell_four]: banner4,
                  [layout.cell_five]: banner5,
                  [layout.cell_six]: banner6,
                  [layout.cell_seven]: banner7,
                  [layout.cell_eight]: banner8,
                },
              },
            },
          } = {},
        } = state;
        return {
          layout,
          layoutId,
          banners: [
            banner1,
            banner2,
            banner3,
            banner4,
            banner5,
            banner6,
            banner7,
            banner8,
          ].filter(b => !!b && !!b.id),
          admin: {
            isLoading: loading > 0,
          },
        };
      }),
    ),
  },
};
