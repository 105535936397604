import React from "react";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import { authClient } from "../server";
import { connectCommon } from "../components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import icon from "@material-ui/icons/Transform";
import Typography from "@material-ui/core/Typography";

const cardStyle = {
  fontFamily: "Roboto Condensed, sans-serif",
};

class Transcoders extends React.PureComponent {
  state = {
    transcoders: {},
  };

  async getSummary() {
    const res = await authClient
      .get("/admin/transcoders/status")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const transcoders = res.json.data.reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {});

    this.setState({
      transcoders,
    });
  }
  async handleDelete(id) {
    authClient
      .delete(`/admin/transcoder/${id}`)
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    const transcoderC = this.state.transcoders;
    delete transcoderC[id];
    this.setState({
      transcoders: { ...transcoderC },
    });
  }

  async handleCreate() {
    const res = await authClient
      .post("/admin/transcoder/create")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const { data } = res.json;
    const { transcoders } = this.state;
    this.setState({
      transcoders: { ...transcoders, [data.id]: data },
    });
  }

  async handleRefresh(id) {
    const res = await authClient
      .get(`/admin/transcoder/${id}`)
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    const { data } = res.json;
    if (!data.id) {
      // If no id in response, it was deleted, then remove from state.
      const transcoderC = this.state.transcoders;
      delete transcoderC[id];
      this.setState({
        transcoders: { ...transcoderC },
      });
      return;
    }
    const { transcoders } = this.state;
    this.setState({
      transcoders: { ...transcoders, [data.id]: data },
    });
  }

  componentDidMount() {
    this.getSummary();
  }

  render() {
    const {
      state: { transcoders },
    } = this;

    const hasPending = !!Object.keys(transcoders).find(
      id =>
        transcoders[id].server_status === "pending" ||
        transcoders[id].transcoder_status === "pending",
    );

    return (
      <React.Fragment>
        <Card>
          <Title title="Transcoders" />
          <CardContent style={cardStyle}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button
                variant="fab"
                color="primary"
                aria-label="Add"
                disabled={hasPending}
                onClick={() => this.handleCreate()}
              >
                <AddIcon />
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell numeric>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Server Status</TableCell>
                  <TableCell>Transcoder Status</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(transcoders).map(row => {
                  return (
                    <TableRow key={row}>
                      <TableCell component="th" scope="row" numeric>
                        {transcoders[row].id}
                      </TableCell>
                      <TableCell>{transcoders[row].name}</TableCell>
                      <TableCell>{transcoders[row].server_status}</TableCell>
                      <TableCell>
                        {transcoders[row].transcoder_status}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          style={
                            transcoders[row].deletable
                              ? { backgroundColor: "red", color: "white" }
                              : undefined
                          }
                          onClick={() => this.handleDelete(row)}
                          disabled={
                            transcoders[row].transcoder_status !== "pending" &&
                            !transcoders[row].deletable
                          }
                        >
                          Delete
                        </Button>{" "}
                        <Button
                          variant="outlined"
                          onClick={() => this.handleRefresh(row)}
                        >
                          Refresh
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Typography>Note: Each Transcoder = 3 messages.</Typography>
      </React.Fragment>
    );
  }
}
export const TranscodersPage = {
  menu: {
    category: "transcoder",
    icon,
    name: "Transcoder",
    label: "Transcoders",
    url: "/transcoder",
  },
  route: {
    exact: true,
    view: withRouter(connectCommon(Transcoders)),
  },
};
