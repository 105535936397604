// Framework
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import DashboardIcon from "@material-ui/icons/AvTimer";
// Custom Components
import SubMenu from "./SubMenu";
// Data
import { categories, noCategory } from "./MenuItems";
import { authorized } from "../server";

const styles = {
  closed: {
    marginTop: 20,
  },
  open: {
    marginTop: 20,
    position: "fixed",
    overflowY: "auto",
    height: "90vh",
  },
};

const localStorageKey = "menu";

let shiftKeyIsDown = false;

window.addEventListener("keydown", e => {
  if (e.keyCode === 16) {
    shiftKeyIsDown = true;
  }
});

window.addEventListener("keyup", e => {
  if (e.keyCode === 16) {
    shiftKeyIsDown = false;
  }
});

function loadDefaultState() {
  const item = window.localStorage.getItem(localStorageKey);
  return item
    ? JSON.parse(item)
    : {
        isClosed: {
          /* [categoryName]: boolean */
        },
      };
}

class Menu extends React.PureComponent {
  static defaultProps = {
    onMenuClick: () => null,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  state = loadDefaultState();

  handleToggle(categoryName) {
    return () => {
      this.setState(
        state => {
          let isClosed; // e.g. { categoryName: boolean }
          const newValue = !state.isClosed[categoryName];
          if (!shiftKeyIsDown) {
            // Just modify the one category, as normal.
            isClosed = {
              ...state.isClosed,
              [categoryName]: newValue,
            };
          } else {
            // Set all categories to the same value as the one being toggled.
            isClosed = {};
            categories.forEach(({ name }) => (isClosed[name] = newValue));
          }
          return {
            ...state,
            isClosed,
          };
        },
        () => {
          const isClosed = { ...this.state.isClosed };
          window.localStorage.setItem(
            localStorageKey,
            JSON.stringify({
              isClosed,
            }),
          );
        },
      );
    };
  }

  render() {
    const { onMenuClick, open } = this.props;
    return (
      <div style={open ? styles.open : styles.closed}>
        {" "}
        <DashboardMenuItem leftIcon={<DashboardIcon />} onClick={onMenuClick} />
        {categories.map(this.renderCategory)}
        {noCategory.items.map(this.renderItem)}
      </div>
    );
  }
  /** @param {import("./MenuItems").MenuCategoryInfo} category */
  renderCategory = category => {
    const { icon, items, label, name, item_roles } = category;
    if (items.length < 1 || (item_roles && !authorized(item_roles))) {
      return null;
    }
    const { open: sidebarIsOpen } = this.props;
    const {
      isClosed: { [name]: isClosed },
    } = this.state;
    return (
      <SubMenu
        key={name}
        handleToggle={this.handleToggle(name)}
        icon={icon}
        isClosed={isClosed}
        sidebarIsOpen={sidebarIsOpen}
        name={label}
      >
        {items.map(this.renderItem)}
      </SubMenu>
    );
  };
  /** @param {import("./MenuItems").MenuItemInfo} item */
  renderItem = item => {
    const { icon: Icon, label, name, url, roles } = item;
    const { onMenuClick } = this.props;
    if (!authorized(roles)) {
      return null;
    }
    return (
      <MenuItemLink
        key={name}
        to={url}
        primaryText={label}
        leftIcon={<Icon />}
        onClick={onMenuClick}
      />
    );
  };
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {},
  ),
);

export default enhance(Menu);
