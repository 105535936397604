import React from "react";
import { Datagrid, List, TextField } from "react-admin";

export function DefaultListView(props) {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}
