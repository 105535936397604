import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  fetchUtils,
} from "react-admin";
import jsonServerProvider from "./ra-data-json-server";
import jwtDecode from "jwt-decode";
import uuidv4 from "uuid/v4";
import { authRoles } from "./authRoles";
import { authDisconnect } from "./socketIO";

export { authRoles };

const {
  NODE_ENV,
  REACT_APP_API_BASE_URL,
  REACT_APP_LOGIN_URL,
  REACT_APP_MOCK_ROLES,
} = process.env;

const __DEV__ = NODE_ENV === "development";

export const baseURL = REACT_APP_API_BASE_URL.endsWith("/")
  ? REACT_APP_API_BASE_URL.substr(0, REACT_APP_API_BASE_URL.length - 1)
  : REACT_APP_API_BASE_URL;

/** Authorized user info. */
export const authUser = {
  deviceId: "",
  id: 0,
  email: undefined,
  loggedIn: false,
  roles: [],
  isAdministrator: false,
  isAuthor: false,
  isCnsclipper: false,
  isNewsletterAdmin: false,
  isSubscriber: false,
  isKiosk: false,
  isPartner: false,
  isStatViewer: false,
  isClipperAdmin: false,
  isCampaignAdmin: false,
  isDoseEmailAdmin: false,
  /**
   * Loads `authUser` from the given token, if any, and returns a success bool.
   * @param {string} [token] Optional token, otherwise loaded from localStorage.
   */
  load(token) {
    try {
      if (!token) {
        token = authToken();
      }
      if (!token) {
        return false;
      }
      /** @type {AuthTokenInfo} */
      const tokenInfo = jwtDecode(token);
      if (tokenInfo) {
        const roles =
          __DEV__ && REACT_APP_MOCK_ROLES
            ? REACT_APP_MOCK_ROLES.split(",").map(role => role.trim())
            : tokenInfo.roles;
        authUser.id = tokenInfo.userId;
        authUser.email = tokenInfo.email;
        authUser.loggedIn = true;
        authUser.roles = roles;
        authRoles.forEach(ar => {
          authUser[ar.prop] = roles.indexOf(ar.id) > -1;
        });
        authUser.deviceId = getOrCreateDeviceUUID();
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  onLogin(handler) {
    authEventSubscribers.add("login", handler);
    return () => {
      authEventSubscribers.remove("login", handler);
    };
  },
  onLogout(handler) {
    authEventSubscribers.add("logout", handler);
    return () => {
      authEventSubscribers.remove("logout", handler);
    };
  },
  /**
   * Resets all `authUser` props.
   */
  reset() {
    authUser.id = 0;
    authUser.email = undefined;
    authUser.loggedIn = false;
    authUser.roles = [];
    authRoles.forEach(ar => (authUser[ar.prop] = false));
    authUser.deviceId = "";
  },
};

/** @param {string} relativeURL */
export function apiURL(relativeURL) {
  if (relativeURL.startsWith("/")) {
    return baseURL + relativeURL;
  }
  return baseURL + "/" + relativeURL;
}

/** An axios compatible fetch client using `authFetchJson`. */
export const authClient = {
  /** HTTP Get
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  get(url, options) {
    return authFetchJson(apiURL(url), { method: "GET", ...options });
  },
  /** HTTP Delete
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  delete(url, options) {
    return authFetchJson(apiURL(url), { method: "DELETE", ...options });
  },
  /** HTTP Post
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data] 
   * @param {RequestInit} [options] Request options.
   */
  post(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "POST",
      body: JSON.stringify(data),
      ...options,
    });
  },
  /** HTTP Put
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data] 
   * @param {RequestInit} [options] Request options.
   */
  put(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "PUT",
      body: JSON.stringify(data),
      ...options,
    });
  },
  putUnstringifiedData(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "PUT",
      body: data,
      ...options,
    });
  },
};

const authEventSubscribers = {
  login: [],
  logout: [],

  add(type, sub) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.push(sub);
  },
  notify(type, ...args) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.forEach(sub => sub(...args));
  },
  remove(type, sub) {
    const { [type]: subscribers } = authEventSubscribers;
    authEventSubscribers[type] = subscribers.filter(item => item !== sub);
  },
};
/** Adds an authorization header to react-admins `fetchUtils.fetchJson`.
 * @param {string} url Relative url to an API endpoint.
 * @param {RequestInit} [options] Request options.
 * @returns {Promise<FetchJsonResponse>}
 */
export function authFetchJson(url, options = {}) {
  const token = authToken();
  if (token) {
    const bearerToken = `Bearer ${token}`;
    if (options.headers) {
      options.headers.set("Authorization", bearerToken);
    } else {
      options.headers = new Headers({
        Accept: "application/json",
        Authorization: bearerToken,
      });
    }
  }
  return fetchUtils.fetchJson(url, options);
}
/** Handlers for different types of react-admin AUTH actions.
 * @type {{[action:string]: (params:object)=> Promise<any>}}
 */
const authHandlers = {
  [AUTH_CHECK](params) {
    if (!authUser.loggedIn && !authUser.load()) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  [AUTH_ERROR](params) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("jwtExpiry");
      authUser.reset();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  /** Function to provide user permissions when rendering resources with
   * `<Admin>{(permissions) => [<Resource ... />]}</Admin>`.
   * See https://marmelab.com/react-admin/Authorization.html
   * See App.renderResources where the result of this function is passed.
   */
  [AUTH_GET_PERMISSIONS](params) {
    if (!authUser.loggedIn) {
      return Promise.reject();
    }
    return Promise.resolve(authUser.roles);
  },
  [AUTH_LOGIN](params) {
    const { username, password } = params;
    const request = new Request(REACT_APP_LOGIN_URL, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, expiration }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("jwtExpiry", expiration);
        authUser.load(token);
        authEventSubscribers.notify("login");
        // NOTE: Subscribing to the login auth event may work for some cases,
        // but completely reloading the page after a login is the safest way
        // to ensure that the application uses current roles and permissions...
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });
  },
  [AUTH_LOGOUT](params) {
    localStorage.removeItem("token");
    localStorage.removeItem("jwtExpiry");
    authUser.reset();
    authEventSubscribers.notify("logout");
    authDisconnect();
    return Promise.resolve();
  },
};
/** React-admin authorization provider */
export function authProvider(type, params) {
  const handler = authHandlers[type];
  if (handler) {
    return handler(params);
  }
  return Promise.reject("Unknown method");
}
/** Returns `true` if the user is a full `administrator` or has one of the
 * given roles.
 * @param {string[]} [roles] The roles to check for.
 * @param {{[role:string]:string[]}} [permissions] Optional permissions by role.
 * @param {"create"|"edit"|"list"} [permission] Permission to check for.
 */
export function authorized(roles, permissions, permission) {
  if (!authUser.loggedIn) {
    return false;
  }
  if (authUser.isAdministrator) {
    return true;
  }
  if (roles) {
    const { roles: userRoles } = authUser;
    if (userRoles) {
      if (
        roles.filter(
          r =>
            // Are we authorized in the requested role?
            userRoles.indexOf(r) > -1 &&
            // If a specific permission was requested and specific permissions
            // were declared on the resource, does the role have permission?
            (!permission ||
              !permissions ||
              (permissions[r] && permissions[r].indexOf(permission) > -1)),
        ).length > 0
      ) {
        return true;
      }
    }
  }
  return false;
}
/** Returns the auth token, if any. */
export function authToken() {
  return localStorage.getItem("token");
}

const DATA_URL_PREFIX_ENDING = "base64,";
const DATA_URL_PREFIX_ENDING_LENGTH = DATA_URL_PREFIX_ENDING.length;

const jsonRequest = jsonServerProvider(apiURL("/admin"), authFetchJson);

/** Converts an inputted `File` to a data url string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export function convertFileToDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}
/** Converts an inputted `File` to a base64 string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export async function convertFileToBase64(file) {
  const dataURL = await convertFileToDataURL(file);
  const base64 = dataURL.substr(
    dataURL.indexOf(DATA_URL_PREFIX_ENDING) + DATA_URL_PREFIX_ENDING_LENGTH,
  );
  // console.log(`Converted file ${file.name} to base64:`, base64);
  return base64;
}
/**
 * Converts the given data objects `FILE_*` keys (if any) to upload.
 */
export async function convertFilesToUpload(data) {
  let dataOut;
  const keys = Object.keys(data);
  const keysLen = keys.length;
  for (let i = 0; i < keysLen; i++) {
    const key = keys[i];
    // Only process `params.data` fields that hold files.
    if (!key.startsWith("FILE_")) {
      continue;
    }
    // Initialize `dataOut` and prepare to convert the `fileProp` field.
    if (!dataOut) {
      dataOut = {
        ...data,
      };
    }
    const fileProp = data[key];
    if (!fileProp) {
      // Cleanup and skip.
      delete dataOut[key];
      continue;
    }
    if (Array.isArray(fileProp)) {
      // Multiple files field.
      const converted = await convertMultipleFilesToUpload(fileProp);
      if (converted.length < 1) {
        delete dataOut[key];
      } else {
        dataOut[key] = converted;
      }
    } else {
      // Single file field.
      if (!fileProp.rawFile) {
        // Cleanup and skip.
        delete dataOut[key];
      } else {
        dataOut[key] = await convertFileToUpload(fileProp);
      }
    }
  }
  return dataOut || data;
}
/**
 * Converts a single file prop for upload.
 * @param {{rawFile:File}} fileProp
 */
async function convertFileToUpload(fileProp) {
  /** @type {File} */
  const rawFile = fileProp.rawFile;
  const base64 = await convertFileToBase64(rawFile);
  return {
    name: rawFile.name,
    size: rawFile.size,
    type: rawFile.type,
    data: base64,
  };
}
/**
 * Converts an array of file props for upload.
 * @param {{rawFile:File}[]} filesProp
 */
async function convertMultipleFilesToUpload(filesProp) {
  const { length } = filesProp;
  /** @type {{name:string,size:number,type:string,data:string}[]} */
  const converted = [];
  for (let i = 0; i < length; i++) {
    const fileProp = filesProp[i];
    /** @type {File} */
    const rawFile = fileProp.rawFile;
    if (!rawFile) {
      continue;
    }
    const base64 = await convertFileToBase64(rawFile);
    converted.push({
      name: rawFile.name,
      size: rawFile.size,
      type: rawFile.type,
      data: base64,
    });
  }
  return converted;
}
/** Gets or creates a local device UUID from localStorage. */
function getOrCreateDeviceUUID() {
  const KEY = "device_uuid";
  let deviceId = localStorage.getItem(KEY);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(KEY, deviceId);
  }
  return deviceId;
}
/**
 * Custom data action handlers by resource and action type.
 * @type {{[resource:string]:{[action:string]:DataActionHandler}}}
 */
const dataActionHandlers = {};
/**
 * Register a custom action handler.
 * @param {string} resource Name of the resource.
 * @param {DataActions} type
 * @param {DataActionHandler} handler
 */
export function onDataAction(resource, type, handler) {
  dataActionHandlers[resource] = {
    ...dataActionHandlers[resource],
    [type]: handler,
  };
  return function removeDataActionHandler() {
    const { [type]: _removed, ...rest } = dataActionHandlers[resource];
    dataActionHandlers[resource] = rest;
  };
}
/**
 * Custom JSON data provider. Wraps `ra-data-json-server` with additional
 * functionality such as `File` uploads.
 */
export async function jsonDataProvider(type, resource, params) {
  // Convert file params.
  switch (type) {
    case "CREATE":
    case "UPDATE":
      params.data = await convertFilesToUpload(params.data);
      break;
    default:
      break;
  }
  // Run a custom action handler.
  const handlers = dataActionHandlers[resource];
  if (handlers) {
    const actionHandler = handlers[type];
    if (actionHandler) {
      let submitted = false;
      const submit = (...args) => {
        submitted = true;
        return jsonRequest(...args);
      };
      const result = actionHandler(type, resource, params, submit);
      if (submitted) {
        // Handler called submit. Done.
        return result;
      } else if (result instanceof Promise) {
        // Handler didn't call submit. Continue below.
        await result;
      }
    }
  }
  return jsonRequest(type, resource, params);
}

// Try to load the user information immediately if the token exists.
authUser.load();

export * from "./socketIO";

// #region Typedefs
/** @typedef {"GET_LIST" | "GET_ONE" | "GET_MANY" | "GET_MANY_REFERENCE" | "CREATE" | "UPDATE" | "UPDATE_MANY" | "DELETE" | "DELETE_MANY"} DataActions */
/**
 * @typedef {(type:string,resource:string,params:any,submit:(type:string,resource:string,params:any)=>Promise)=>Promise} DataActionHandler
 */
/**
 * @typedef {object} AuthTokenInfo
 * @property {any} claims
 * @property {number} expiration
 * @property {number} iat
 * @property {boolean} loggedIn
 * @property {string[]} roles
 * @property {number|string} userId
 */
/**
 * @typedef {object} FetchJsonResponse
 * @property {number} status HTTP status code of the response.
 * @property {Headers} headers Standard Headers object for the response.
 * @property {string} body Text of the response body.
 * @property {object} [json] JSON parsed from the body text, if any.
 */
// #endregion
