import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MuiToolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Title } from "react-admin";
import { withRouter } from "react-router-dom";
import { authClient } from "../server";
import { connectCommon } from "../components";

import DragRowIcon from "@material-ui/icons/SwapVert";

const SortableItem = SortableElement(({ value, show, custom }) => {
  return (
    <div
      style={{
        height: 42,
        outline: "1px solid #eee",
        paddingRight: 80,
        lineHeight: "42px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "0.875rem",
        zIndex: 500,
        boxShadow: custom ? "inset 0 0 9px 1px #7084b3" : "none",
        cursor: "pointer",
      }}
    >
      <div style={{ opacity: show ? 1 : 0.3 }}>
        <span
          style={{
            position: "relative",
            top: 8,
            paddingLeft: 150,
            paddingRight: 25,
          }}
        >
          <DragRowIcon />
        </span>
        {value}
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={value.id}
          index={index}
          value={value.title}
          show={value.show}
          custom={value.is_custom}
        />
      ))}
    </div>
  );
});

class HomeRowsReorder extends Component {
  state = {
    items: [],
    changed: false,
  };

  getRows = () => {
    this.props.fetchStart();
    authClient
      .get("/admin/homepage-rows?_end=100&_order=ASC&_sort=row_order&_start=0")
      .then(res => {
        this.setState({
          items: res.json.sort((a, b) => a.row_order - b.row_order),
        });
      })
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
  };
  saveRows = async () => {
    if (this.state.changed) {
      let rows = [...this.state.items];
      const rowArray = rows.map(row => row.id);
      this.props.fetchStart();
      await authClient
        .put("admin/homepage-rows/reorder", rowArray)
        .catch(this.props.showError)
        .finally(this.props.fetchEnd);
      this.props.history.replace("/homepage-rows");
    }
  };
  restoreDefault = () => {
    const initialRows = [...this.state.items].sort((a, b) => a.id - b.id);
    this.setState({ items: initialRows, changed: true });
  };

  componentDidMount() {
    this.getRows();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
      changed: true,
    }));
  };

  render() {
    return (
      <Card>
        <Title title="Reorder Rows" />
        <CardContent style={{ padding: 0 }}>
          <div
            style={{
              lineHeight: "100px",
              fontWeight: 600,
              fontSize: 20,
              color: "#555",
              paddingLeft: 150,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              marginBottom: 20,
            }}
          >
            Drag rows to reorder, then click "Save."
          </div>
          <SortableList
            items={this.state.items}
            onSortEnd={this.onSortEnd}
            hideSortableGhost={true}
          />
          <MuiToolbar
            role="toolbar"
            style={{
              backgroundColor: "white",
              marginTop: 0,
              justifyContent: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color={this.state.changed ? "primary" : "default"}
              size="small"
              onClick={this.saveRows}
              style={{ margin: 10 }}
            >
              Save
            </Button>
            <Link to="/homepage-rows" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 10 }}
              >
                Cancel
              </Button>
            </Link>
            <Button
              variant="contained"
              color="default"
              size="small"
              onClick={this.restoreDefault}
              style={{ margin: 10 }}
            >
              Restore Default Order
            </Button>
          </MuiToolbar>
        </CardContent>
      </Card>
    );
  }
}

export const HomeRowsReorderPage = {
  route: {
    exact: true,
    path: "/homepage-rows/reorder",
    view: withRouter(connectCommon(HomeRowsReorder)),
  },
};
