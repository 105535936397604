import React from "react";
import {
  Create,
  SimpleForm,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
  BooleanInput,
  DateField,
  showNotification,
  refreshView,
} from "react-admin";
import { connect } from "react-redux";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
} from "../components";

import { BoolNumField } from "../components/field/BoolNumField";
import { Button } from "@material-ui/core";
import { authClient } from "../server";

function CreateView(props) {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <TextInput source="lecture_id" label="Lecture ID" />
        {/* <TextField source="total_amount" label="Amount" /> */}
        <TextInput source="payment_status" label="Payment Status" />
        <TextInput source="first_name" label="First Name" />
        <TextInput source="last_name" label="Last Name" />
        <TextInput source="email" label="Email" />
        <TextInput source="phone" label="Phone" />
        <TextInput source="address_line_1" label="Address Line 1" />
        <TextInput source="address_line_2" label="Address Line 2" />
        <TextInput source="city" label="City" />
        <TextInput source="state" label="state" />
        <TextInput source="zip" label="zip" />
        <TextInput source="country" label="country" />
        <TextInput source="dedication_text" label="Dedication Text" />
        <BooleanInput source="is_approved" label="Approved?" />
      </SimpleForm>
    </Create>
  );
}

function SendThankYouButton({ data, showNotif, refreshview }) {
  return data && !data.sent_thank_you_email ? (
    <Button
      onClick={() =>
        authClient
          .post(`admin/lecture-sponsors/send-thank-you-email`, { ...data })
          .then(() => {
            showNotif("Thank you email sent.");
            refreshview();
          })
      }
    >
      Send Thank You Email
    </Button>
  ) : (
    ""
  );
}

function EditView(props) {
  const { showNotif, refreshview, ...rest } = props;
  return (
    <div>
      <Edit
        {...rest}
        undoable={false}
        actions={
          <SendThankYouButton showNotif={showNotif} refreshview={refreshview} />
        }
      >
        <SimpleForm redirect={false} toolbar={<EditorToolbar showDelete />}>
          <TextInput source="lecture_id" label="Lecture ID" />
          {/* <TextField source="total_amount" label="Amount" /> */}
          <TextInput source="payment_status" label="Payment Status" />
          <TextInput source="first_name" label="First Name" />
          <TextInput source="last_name" label="Last Name" />
          <TextInput source="email" label="Email" />
          <TextInput source="phone" label="Phone" />
          <TextInput source="address_line_1" label="Address Line 1" />
          <TextInput source="address_line_2" label="Address Line 2" />
          <TextInput source="city" label="City" />
          <TextInput source="state" label="state" />
          <TextInput source="zip" label="zip" />
          <TextInput source="country" label="country" />
          <TextInput source="dedication_text" label="Dedication Text" />
          <BooleanInput source="is_approved" label="Approved?" />
        </SimpleForm>
      </Edit>
    </div>
  );
}

function FiltersView(props) {
  return <Filter {...props}></Filter>;
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Lecture Sponsors"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="lecture_id" label="Lecture ID" />
        <BoolNumField source="is_approved" label="Approved" />
        {/* <TextField source="total_amount" label="Amount" /> */}
        <TextField source="payment_status" label="Payment Status" />
        <TextField source="lecture.speaker_name" label="Speaker" />
        <TextField source="lecture.duration_str" label="Duration" />
        <TextField source="lecture.categories[0].name" label="Category" />
        <TextField source="lecture.subcategories[0].name" label="SubCategory" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Phone" />
        <TextField source="city" label="City" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
      </Datagrid>
    </List>
  );
}

export const LectureSponsors = {
  category: "database",
  name: "manage-lecture-sponsors",
  list: ListView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Lecture Sponsors" },
  edit: connect(
    undefined,
    { showNotif: showNotification, refreshview: refreshView },
  )(EditView),
};
