import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class CategoryInput extends React.PureComponent {
  render() {
    const {
      label = "Category",
      source = "category",
      reference = "categories",
      filter = { parent: 6 },
      filterToQuery = filterByName,
      perPage = 100,
      sort = { field: "name", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        filter={filter}
        filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    );
  }
}
