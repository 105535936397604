import CampaignsIcon from "@material-ui/icons/PermPhoneMsg";
import DatabaseIcon from "@material-ui/icons/Dns";
import EmailIcon from "@material-ui/icons/Email";
import ManageIcon from "@material-ui/icons/SettingsApplications";
import MiscIcon from "@material-ui/icons/AllInclusive";
import StatsIcon from "@material-ui/icons/Timeline";
import TANewIcon from "@material-ui/icons/FiberNew";
import BannersIcon from "@material-ui/icons/DeveloperBoard";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import ClearAllIcon from "@material-ui/icons/ClearAll";

export default [
  {
    name: "manage",
    icon: ManageIcon,
  },
  {
    label: "Corporate Sponsors",
    name: "corporate_sponsors",
    icon: BusinessCenter,
  },
  {
    label: "Daily Dose",
    name: "dose",
    icon: ManageIcon,
  },
  {
    name: "banners",
    icon: BannersIcon,
  },
  {
    name: "database",
    icon: DatabaseIcon,
  },
  {
    name: "stats",
    icon: StatsIcon,
  },
  {
    name: "email",
    icon: EmailIcon,
  },
  {
    name: "campaigns",
    icon: CampaignsIcon,
  },
  {
    name: "misc",
    icon: MiscIcon,
  },
  {
    label: "TorahAnytime 3.0",
    name: "tanext",
    icon: TANewIcon,
  },
  {
    label: "Tisha B'av",
    name: "tbav",
    icon: ClearAllIcon,
  },
];
