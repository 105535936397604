import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  FormTab,
  ImageField,
  List,
  LongTextInput,
  TabbedForm,
  TextField,
  TextInput,
  NumberInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import resourceIcon from "@material-ui/icons/Group";
import {
  BoolNumField,
  BoolNumInput,
  ColorInput,
  EditorToolbar,
  ListActions,
} from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <BoolNumInput label="Active" source="display_active" alwaysOn />
      <TextInput source="id" alwaysOn />
      <TextInput label="Name" source="name" alwaysOn />
    </Filter>
  );
}

function renderEditForm({ redirect }) {
  return (
    <TabbedForm redirect={redirect} toolbar={<EditorToolbar />}>
      <FormTab label="Summary" replace>
        <ImageField
          source="logo_url"
          title="Photo"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <TextInput source="name" label="Name" />
        <TextInput source="logo" label="Logo" />
        <BoolNumInput source="display_active" label="Active" />
        <BoolNumInput source="are_lectures_not_discoverable_by_default" label="Hidden" />
        <BoolNumInput source="is_host" label="Host" />
        <LongTextInput source="dedication" label="Dedication" />
        <NumberInput source="dial_in_ext" label="Dial In Extension" />
      </FormTab>
      <FormTab label="Description" replace>
        <RichTextInput source="desc" addLabel={false} />
      </FormTab>
      <FormTab label="Theme" replace>
        <LongTextInput source="theme.bg_image" label="Background image" />
        <ColorInput source="theme.bar_bg_color" label="Bar background color" />
        <ColorInput source="theme.color" label="Color" />
        <ColorInput source="theme.text_color" label="Text color" />
        <ColorInput source="theme.title_color" label="Title color" />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={<FiltersView />}
      title="Organizations"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <ImageField source="logo_url" label="Logo" />
        <TextField source="name" style={{ minWidth: 240 }} label="Name" />
        <BoolNumField source="display_active" label="Active" />
        <BoolNumField source="is_host" label="Host" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Organizations = {
  category: "database",
  name: "organizations",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
};
