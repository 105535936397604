import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiToolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// import Typography from "@material-ui/core/Typography";
import { Title } from "react-admin";
import { withRouter } from "react-router-dom";
// import classNames from "classnames";
import { connectCommon, getInputValue } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/Message";

const notificationTypes = {
  tempone: "Standard Message using Notification Bar",
  temptwo: "Pop-Up with OK button",
  tempthree: "Pop-Up with custom button text. Play Store",
  tempfour: "Pop-Up with custom button text. Donate Page",
  tempfive: "Pop-Up with custom button text. Message Center",
  tempsix: "Pop-Up with custom button text. Live Lectures",
  tempseven: "Pop-Up with custom button text. Register Page",
};

const showUrlFieldTypes = ["tempone", "temptwo"];

const platforms = {
  all: "All",
  android: "Android",
  ios: "iOS",
};
const apps = {
  all: "ALL",
  old: "2.0 App",
  new: "3.0 App",
};
const defaultState = {
  type: "tempone",
  msg: "",
  title: undefined,
  url: undefined,
  buttontxt: "OK",
  platform: "all",
  apps: "all",
  sending: false,
};

class PushNotify extends React.Component {
  state = { ...defaultState };

  get canSend() {
    const { state } = this;
    return !state.sending && !!state.msg && !!state.buttontxt;
  }

  /**
   * @param {string} field The field name.
   * @param {(e:React.SyntheticEvent<HTMLInputElement>)=>any} [getValue] */
  onChangeField = (field, getValue = getInputValue) => {
    return (...args) => {
      const value = getValue(...args);
      this.setState({
        [field]: value,
      });
    };
  };

  // resetState = () => {
  //   this.setState({ ...defaultState });
  // };

  send = testing => {
    return () => {
      if (!this.canSend) {
        return;
      }
      const {
        state: { sending: _sending, ...data },
      } = this;

      // We don't want to include the URL if it won't be supported.
      // Since the user may have filled in the URL field, and then
      // changed to another notification type, we just delete it before
      // submitting it.
      if (!!showUrlFieldTypes.includes(data.type)) {
        delete data.url;
      }

      data.testing = !!testing;
      this.setState({
        sending: true,
      });
      this.props.fetchStart();
      authClient
        .put("/admin/push-notify", data)
        .then(result => {
          const { count } = result.json;
          if (count < 1) {
            window.alert("No devices found.");
          } else {
            window.alert(
              `OK, sent ${count} ${testing ? "test " : ""} notifications.`,
            );
          }
          this.setState({
            sending: false,
          });
          return Promise.resolve();
        })
        .catch(err => {
          this.setState({
            sending: false,
          });
          this.props.showError(err);
        })
        .finally(this.props.fetchEnd);
    };
  };

  render() {
    const { canSend, state } = this;
    return (
      <Card>
        <Title title="Push Notification" />
        <CardContent>
          <div>
            <NativeSelect
              value={state.type}
              onChange={this.onChangeField("type")}
            >
              {Object.keys(notificationTypes).map(key => (
                <option key={key} value={key}>
                  {notificationTypes[key]}
                </option>
              ))}
            </NativeSelect>
            <div style={{ height: 20 }} />
            <TextField
              label="Message Title"
              placeholder="TorahAnytime"
              value={state.title}
              onChange={this.onChangeField("title")}
              style={{ width: "100%" }}
            />
            <TextField
              label="Message Text"
              placeholder="Message Text"
              value={state.msg}
              onChange={this.onChangeField("msg")}
              multiline={true}
              rows={15}
              style={{ width: "100%" }}
            />
            {showUrlFieldTypes.includes(state.type) && (
              <TextField
                label="URL"
                placeholder="https://torahanytime.com/donate"
                value={state.url}
                onChange={this.onChangeField("url")}
                style={{ width: "100%" }}
              />
            )}
            <div style={{ height: 20 }} />
            <TextField
              label="Button Text"
              placeholder="Button Text"
              value={state.buttontxt}
              onChange={this.onChangeField("buttontxt")}
            />
            <div style={{ height: 20 }} />
            <InputLabel htmlFor="apps">Send to</InputLabel>
            <br />
            <RadioGroup
              value={state.apps}
              onChange={this.onChangeField("apps")}
              name="apps"
              style={{ width: 220 }}
            >
              {Object.keys(apps).map(key => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio color="primary" />}
                  label={apps[key]}
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
            <br />
            <InputLabel htmlFor="platform">Platform</InputLabel>
            <br />
            <RadioGroup
              value={state.platform}
              onChange={this.onChangeField("platform")}
              name="platform"
              style={{ width: 220 }}
            >
              {Object.keys(platforms).map(key => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio color="primary" />}
                  label={platforms[key]}
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
            <div style={{ height: 20 }} />
          </div>
          <MuiToolbar role="toolbar">
            <Button
              variant="contained"
              color={canSend ? "primary" : "default"}
              size="small"
              onClick={this.send(true)}
              // className={css.sendButton}
              style={{ marginRight: 20 }}
              disabled={state.sending}
            >
              Test Send to myself
            </Button>
            <Button
              variant="contained"
              color={canSend ? "primary" : "default"}
              size="small"
              onClick={this.send(false)}
              // className={css.sendButton}
              style={{ marginRight: 20 }}
              disabled={state.sending}
            >
              Send
            </Button>
          </MuiToolbar>
        </CardContent>
      </Card>
    );
  }
}

export const PushNotifyPage = {
  menu: {
    category: "misc",
    icon,
    name: "push-notify",
    label: "Push Notifications",
    url: "/push-notify",
  },
  route: {
    exact: true,
    view: withRouter(connectCommon(PushNotify)),
  },
};
