import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput,
  DateInput,
  DateField,
  FileInput,
  FileField,
  BooleanInput,
  required,
} from "react-admin";

import resourceIcon from "@material-ui/icons/PictureAsPdf";
import { EditorToolbar, BoolNumField } from "../../components";
import RichTextInput from "ra-input-rich-text";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false} sort="id">
      <Datagrid rowClick="edit">
        <NumberField source="id" />

        <TextField source="parsha" />

        <DateField source="newsletter_date" />
        <BoolNumField source="display_active" label="visible" />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" label="Visible" />
      <TextInput source="parsha" validate={required()} />
      <div style={{ margin: "32px 0" }}>
        <TextInput source="pdf_url" />
        <FileInput
          source="FILE_data"
          label="PDF Upload"
          accept="application/pdf"
          placeholder={<p>Click or drop your file here.</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </div>
      <div style={{ margin: "32px 0" }}>
        <DateInput source="newsletter_date" />
        <NumberInput source="hebrew_year" />
      </div>

      <TextInput source="image" label="Header Image" />
      <FileInput
        source="FILE_image_data"
        label="Header Image"
        accept="image/*"
        placeholder={<p>Click or drop your file here.</p>}
      >
        <FileField source="src-image" title="title" />
      </FileInput>

      <RichTextInput source="content" Label="Content" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const Newsletters = {
  category: "manage",
  roles: ["newsletter_admin", "admin"],
  name: "newsletters",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Weekly Newsletters" },
};
