import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  UrlField,
  List,
  NumberField,
  ReferenceField,
  SimpleForm,
  TextField,
  BooleanInput,
  NumberInput,
  TextInput,
  LongTextInput,
  ReferenceInput,
  DateTimeInput,
  SelectInput,
  required,
  regex,
  ReferenceManyField,
  Filter,
  SearchInput,
} from "react-admin";

import resourceIcon from "@material-ui/icons/Movie";
import {
  BoolNumField,
  DateTimeField,
  // DateTimeInput,
  EditorToolbar,
  WideCard,
} from "../components";
const validate = values => {
  const tishaBavEve = new Date(2023, 6, 26, 0, 0, 0);
  const tishaBavDate = new Date(2023, 6, 27, 23, 59, 59);

  const errors = {};
  if (!values.start_time) {
    errors.start_time = ["Date start is required"];
  }
  if (!values.end_time) {
    errors.end_time = ["Date end is required"];
  }
  if (new Date(values.start_time) < tishaBavEve) {
    errors.start_time = [`Date start should be after ${tishaBavEve}`];
  }
  if (new Date(values.end_time) > tishaBavDate) {
    errors.end_time = [`Date end should be before ${tishaBavDate}`];
  }
  if (new Date(values.start_time) > new Date(values.end_time)) {
    errors.start_time = ["Date start must be less than Date end!"];
    errors.end_time = ["Date end must be greater than Date start!"];
  }

  // console.log(errors);
  return errors;
};
function FiltersView(props) {
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn />
      <TextInput source="tisha_bav_program" label="Program ID" alwaysOn />
    </Filter>
  );
}
function ListView(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort="start_time"
      filters={<FiltersView />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <ReferenceField
          source="tisha_bav_program"
          reference="tisha_bav_programs"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <DateTimeField source="start_time" />
        <DateTimeField source="end_time" />
        <BoolNumField source="display_active" />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm
      validate={validate}
      redirect={redirect}
      toolbar={<EditorToolbar />}
    >
      <BooleanInput source="display_active" defaultValue={0} />
      <ReferenceInput
        reference="tisha_bav_programs"
        source="tisha_bav_program"
        validate={required()}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} />

      <h4 style={{ marginBottom: "-20px", width: "100%", textAlign: "right" }}>
        Other classes for this program: Please ensure there are no scheduling
        conflicts
      </h4>
      <ReferenceManyField
        label=""
        reference="tisha_bav_classes"
        target="tisha_bav_program"
        source="tisha_bav_program"
        className="program_table"
        sort={{ field: "start_time", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="name" />
          <DateTimeField
            source="start_time"
            options={{
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            }}
          />
          <DateTimeField
            source="end_time"
            options={{
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            }}
          />
        </Datagrid>
      </ReferenceManyField>
      <p>Note: times will be rounded to closest half hour</p>
      <DateTimeInput
        // min={tishaBavEve}
        source="start_time"
        validate={required()}
      />
      <DateTimeInput source="end_time" validate={required()} />
      <BooleanInput source="is_female" defaultValue={false} />
      <br />
      <br />
      <h4 style={{ width: 400 }}>
        For all day, pre-recorded classes, put the length of the class (in
        minutes). Please make sure the end time is the FINAL end time.
      </h4>
      <NumberInput
        source="recurring_interval"
        label="Length (recurring interval)"
      />
      <LongTextInput source="speakers" label="Speakers (optional)" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const TbavClasses = {
  category: "tbav",
  name: "tisha_bav_classes",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Tisha Bav Classes" },
};
