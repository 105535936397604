import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MuiToolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import RichTextInput from "ra-input-rich-text";
// import Typography from "@material-ui/core/Typography";
import { FileField, Title } from "react-admin";
import { withRouter } from "react-router-dom";
// import classNames from "classnames";
import {
  connectCommon,
  getInputValue,
  returnArg,
  SimpleFileInput,
} from "../../components";
import { authClient, convertFilesToUpload } from "../../server";
import icon from "@material-ui/icons/PictureAsPdf";

const singleEmailHelperText = (
  <span style={{ color: "navy", opacity: 0.5 }}>
    Enter a single email address preceded by a display name in angle brackets.
    Example:
    <strong>{" <Display Name> email@address.com "}</strong>
  </span>
);

const multiEmailHelperText = (
  <span style={{ color: "navy", opacity: 0.5 }}>
    Enter one or more email addresses separated by semi-colons. Each email
    address may be preceded by a display name in angle brackets. Example:
    <strong>
      {" <Display Name 1> email1@address.com; " +
        "<Display Name 2> email2@address.com "}
    </strong>
  </span>
);

const styles = {
  field: { width: "100%", margin: 6 },
};

class EmailPDF extends React.Component {
  state = {
    defaultValue: {},
    loading: true,
    subscribers: "0",

    email_subject: "",
    email_from: "",
    email_admins: "",
    email_body: "",
    email_preview: "",
    FILE_attach: [],
    is_preview: false,
  };

  loadDefaults = async () => {
    const res = await authClient.get("/admin/email_pdf/init");
    const {
      json: {
        defaultValue: data,
        stats: { subscribers },
      },
    } = res;
    this.setState({
      loading: false,
      subscribers: subscribers.toLocaleString(),
      ...data,
    });
  };

  /**
   * @param {string} field The field name.
   * @param {(e:React.SyntheticEvent<HTMLInputElement>)=>any} [getValue] */
  onChangeField = (field, getValue = getInputValue) => {
    return (...args) => {
      const value = getValue(...args);
      this.setState({
        [field]: value,
      });
    };
  };

  onChangedFiles = files => {
    this.setState({
      FILE_attach: files,
    });
  };

  sendPreview = () => {
    this.send(true);
  };

  sendToSubscribers = () => {
    this.send(false);
  };

  send(is_preview) {
    this.setState(
      {
        is_preview,
      },
      async () => {
        let {
          props: { fetchEnd, fetchStart, showError },
          state: { defaultValue, loading, subscribers, ...data },
        } = this;
        const msg = is_preview
          ? `Send preview email to ${data.email_preview}?`
          : `Send email to ${subscribers} subscribers?`;
        if (!window.confirm(msg)) {
          return;
        }
        data = await convertFilesToUpload(data);
        fetchStart();
        authClient
          .post("/admin/email_pdf", data)
          .catch(showError)
          .finally(fetchEnd);
      },
    );
  }

  componentDidMount() {
    const {
      props: { fetchEnd, fetchStart, showError },
    } = this;
    fetchStart();
    this.loadDefaults()
      .catch(showError)
      .finally(fetchEnd);
  }

  render() {
    // NOTE: Passing `defaultValue` to render labels properly.
    const { state } = this;
    return (
      <Card>
        <Title title="TorahAnytime PDF" />
        <CardContent>
          <div>
            <TextField
              label="Subject"
              value={state.email_subject}
              onChange={this.onChangeField("email_subject")}
              style={styles.field}
            />
            <TextField
              label="From Address"
              value={state.email_from}
              onChange={this.onChangeField("email_from")}
              style={styles.field}
              helperText={singleEmailHelperText}
            />
            <TextField
              label="Admin Addresses"
              value={state.email_admins}
              onChange={this.onChangeField("email_admins")}
              style={{ ...styles.field, marginBottom: 20 }}
              helperText={multiEmailHelperText}
            />
            <InputLabel
              htmlFor="email_body"
              style={{ fontSize: 12, marginLeft: 8 }}
            >
              Email Body
            </InputLabel>
            <RichTextInput
              id="email_body"
              label="Email Body"
              input={{
                onChange: this.onChangeField("email_body", returnArg),
                value: state.email_body,
              }}
              meta={{}}
            />
            <SimpleFileInput
              label="Attachments"
              input={{
                onBlur: this.onChangedFiles,
                value: state.FILE_attach,
              }}
              placeholder={<p>Click or drop your file here.</p>}
              multiple
            >
              <FileField source="src" title="title" />
            </SimpleFileInput>
            <TextField
              label="Preview Addresses"
              value={state.email_preview}
              onChange={this.onChangeField("email_preview")}
              style={styles.field}
              helperText={multiEmailHelperText}
            />
          </div>
          <MuiToolbar role="toolbar">
            <Button
              variant="contained"
              color={"primary"}
              size="small"
              onClick={this.sendPreview}
              disabled={state.loading}
            >
              Send Preview
            </Button>
            <Button
              variant="contained"
              color={"primary"}
              size="small"
              onClick={this.sendToSubscribers}
              disabled={state.loading}
              style={{ marginLeft: "auto", float: "right" }}
            >
              Send to Subscribers
            </Button>
          </MuiToolbar>
        </CardContent>
      </Card>
    );
  }
}

export const EmailPDFPage = {
  menu: {
    category: "email",
    icon,
    name: "email-pdf",
    label: "TorahAnytime PDF",
    url: "/email-pdf",
  },
  route: {
    exact: true,
    view: withRouter(connectCommon(EmailPDF)),
  },
};
