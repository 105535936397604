import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import { addField, FieldTitle } from "ra-core";

import sanitizeRestProps from "ra-ui-materialui/lib/input/sanitizeRestProps";

export class BoolNumInput extends Component {
  handleChange = (event, value) => {
    const numericValue = value ? 1 : 0;
    this.props.input.onChange(numericValue);
  };

  render() {
    const {
      className,
      id,
      input,
      isRequired,
      label,
      source,
      resource,
      options,
      ...rest
    } = this.props;

    const {
      value,
      // Remove onChange since we handle this.
      onChange: _onChange,
      // Remove onBlur since letting Switch trigger it causes a boolean value
      // to be applied.
      onBlur: _onBlur,
      ...inputProps
    } = input;

    return (
      <FormGroup className={className} {...sanitizeRestProps(rest)}>
        <FormControlLabel
          htmlFor={id}
          control={
            <Switch
              id={id}
              color="primary"
              checked={!!value}
              {...inputProps}
              {...options}
              onChange={this.handleChange}
            />
          }
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
        />
      </FormGroup>
    );
  }
}

BoolNumInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
};

BoolNumInput.defaultProps = {
  options: {},
};

export default addField(BoolNumInput);
