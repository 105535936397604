/** Gets all the route params. */
export function getRouteParams(state) {
  const { match: { params = {} } = {} } = state;
  return params;
}
/** Gets the route param named `id`. */
export function getIdRouteParam(state, defaultValue) {
  const { match: { params: { id = defaultValue } } = {} } = state;
  return id;
}
