import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  TabbedForm,
  TextField,
} from "react-admin";
import resourceIcon from "@material-ui/icons/ViewAgenda";
import { EditorToolbar } from "../components";

function validate(values) {
  const errors = {};
  if (!values.title) {
    errors.title = ["TITLE IS REQUIRED!"];
  }
  return errors;
}

function renderEditForm({ redirect }) {
  return (
    <TabbedForm
      redirect={redirect}
      validate={validate}
      submitOnEnter={false}
      toolbar={<EditorToolbar />}
    >
      <FormTab label="Summary" replace>
        <ImageField
          source="photo"
          title="Default/Mobile Banner"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <ImageField
          source="photo_desktop_size"
          title="Wide/Desktop Banner"
          style={{
            float: "right",
            width: "70%",
          }}
        />
        <LongTextInput source="title" required />
        <LongTextInput source="sub_title" />
        <LongTextInput source="link" />
        <LongTextInput source="link_text" />
        <BooleanInput source="target_blank" label="Open in new window" />
        <LongTextInput source="photo" label="Default/Mobile Banner URL" />
        <ImageInput
          source="FILE_photo"
          label="Default/Mobile Banner Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <LongTextInput
          source="photo_desktop_size"
          label="Wide/Desktop Banner URL"
        />
        <ImageInput
          source="FILE_desktop"
          label="Wide/Desktop Banner Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Long Content" replace>
        <LongTextInput source="long_content" />
      </FormTab>
      <FormTab label="Short Content" replace>
        <LongTextInput source="short_content" />
      </FormTab>
      <FormTab label="HTML" replace>
        <LongTextInput source="html" />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ImageField
          source="photo"
          title="Photo"
          style={{
            float: "right",
            width: "100%",
          }}
        />
        <TextField source="title" />
        <TextField source="sub_title" />
        <TextField source="html" />
        <TextField source="link" />
        <BooleanField source="target_blank" />
        <TextField source="link_text" />
      </Datagrid>
    </List>
  );
}

export const Banners = {
  category: "tanext",
  name: "banners",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Banners" },
};
