import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/FeaturedVideo";

export const ManageBannersVideoPage = makeSortableFormPage({
  createItem() {
    return {
      image: "",
      link: "",
      onclick: "",
      speaker: "", // NOTE: Can be a blank string OR number!
      title: "",
    };
  },
  fetch() {
    return authClient.get("/admin/manage-banners/video");
  },
  load(res) {
    const data = res.json;
    return {
      items: data.items,
    };
  },
  menu: {
    category: "banners",
    icon,
    name: "video-banners",
    label: "Video",
    url: "/manage-banners/video",
  },
  renderItem({ actions, css, item, rowIndex }) {
    const { onChangeItem } = actions;
    return (
      <>
        <TextField
          label=""
          placeholder="Image URL"
          value={item.image}
          onChange={onChangeItem(rowIndex, "image")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Title"
          value={item.title}
          onChange={onChangeItem(rowIndex, "title")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Link URL"
          value={item.link}
          onChange={onChangeItem(rowIndex, "link")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Speaker ID to show exclusively"
          value={item.speaker}
          onChange={onChangeItem(
            rowIndex,
            "speaker",
            e => e.currentTarget.valueAsNumber || "",
          )}
          className={css.rowField}
          type="number"
        />
        <TextField
          label=""
          placeholder="JS onclick function()"
          value={item.onclick}
          onChange={onChangeItem(rowIndex, "onclick")}
          className={css.rowField}
        />
      </>
    );
  },
  renderFields() {},
  save(state) {
    return authClient.put("/admin/manage-banners/video", {
      items: [...state.items],
    });
  },
  subtitle: (
    <>
      NOTE: Only banners containing <strong>Image URL</strong> will be shown on
      the website.
    </>
  ),
  title: "Video Banners",
});
