import React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  SelectInput,
  FunctionField,
  Show,
  ReferenceField,
  SimpleShowLayout,
} from "react-admin";
import resourceIcon from "@material-ui/icons/MonetizationOn";
import { ListActions, DateTimeField, BoolNumField } from "../components";

const parseEmail = record => {
  return record.txn_response && record.txn_response.donor_email
    ? record.txn_response.donor_email
    : record.meta && record.meta.donor_email
    ? record.meta.donor_email
    : "";
};

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="Txn ID" source="txn_id" alwaysOn />
      <TextInput label="TransID" source="transaction_id" alwaysOn />
      <TextInput label="Email" source="donor_email" alwaysOn />
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        choices={[
          { id: "success", name: "Success" },
          { id: "failed", name: "Failed" },
        ]}
      />
      <TextInput source="id" alwaysOn />
      <SelectInput
        label="Verified"
        source="verified"
        alwaysOn
        choices={[{ id: 1, name: "Verified" }, { id: 0, name: "Not Verified" }]}
      ></SelectInput>
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Donations"
    >
      <Datagrid rowClick="show">
        <TextField source="txn_id" label="Txn ID" />
        <ReferenceField
          label="Campaign Package"
          source="campaign_pckg_id"
          reference="campaign_packages"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="campaign_pckg_id" label="Campaign Pckg ID" />
        <TextField source="amount" label="Amount" />
        <FunctionField
          label="Transaction ID"
          render={r => (r.txn_response ? r.txn_response.transaction_id : "")}
        />
        <FunctionField label="Email" render={parseEmail} />
        <TextField source="status" label="Status" />
        <DateTimeField source="created_at" label="Created At" />
        <TextField source="id" label="Id" />
        <BoolNumField source="verified" label="Verified" />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Packages"
    >
      <SimpleShowLayout>
        <TextField source="txn_id" label="Txn ID" />
        <TextField source="campaign_pckg_id" label="Campaign Pckg ID" />
        <TextField source="campaign_package.name" label="Campaign Pckg Name" />
        <TextField source="amount" label="Amount" />
        <TextField source="txn_response" label="Processor Response" />
        <FunctionField label="Email" render={parseEmail} />
        <TextField source="meta" label="Extra Info" />
        <TextField source="status" label="Status" />
        <TextField source="created_at" label="Created At" />
        <TextField source="id" label="Id" />
        <BoolNumField source="verified" label="verified" />
      </SimpleShowLayout>
    </Show>
  );
}

export const CampaignDonations = {
  category: "database",
  name: "campaign_donations",
  icon: resourceIcon,
  list: ListView,
  show: ShowView,
  options: { label: "Campaign Donations" },
  roles: ["campaign_admin"],
  permissions: {
    campaign_admin: ["create", "list", "view"],
  },
};
