import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Contacts";
import {
  DefaultBulkActionButtons,
  EditorToolbar,
  ListActions,
} from "../../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn label="Email Address" />
    </Filter>
  );
}

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar showDelete />}>
      <TextInput source="id" label="Email Address" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "list" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      title="Subscribers"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Email Address" />
      </Datagrid>
    </List>
  );
}

export const EmailSubscribers = {
  category: "email",
  name: "email_subscribers",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Subscribers" },
};
