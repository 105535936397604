import React from "react";
import PropTypes from "prop-types";
import DateTimePicker from "react-datetime-picker";
import { addField, FieldTitle } from "ra-core";

function format(v) {
  if (!v) {
    return null;
  }
  const d = new Date(v);
  if (Number.isNaN(d)) {
    return null;
  }
  return d;
}

class DateTimePickerInput extends React.PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    // className: PropTypes.string,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  onChange = date => {
    const {
      props: { input },
    } = this;
    // console.log("CHOSE DATE: ", date);
    input.onChange(date);
    input.onBlur(date);
  };

  render() {
    const {
      onChange,
      props: {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        // className,
        // meta,
      },
    } = this;
    // const { touched, error } = meta;
    // console.log("DATE: ", input.value);
    return (
      /*
        error={!!(touched && error)}
        helperText={touched && error}
      */
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          fontFamily: "Roboto Condensed, sans-serif",
          color: "#555",
        }}
      >
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
        <br />
        <DateTimePicker
          disableClock={true}
          name={source}
          {...options}
          required={isRequired}
          value={input.value}
          onChange={onChange}
        />
      </div>
    );
  }
}

export const DateTimeInput = addField(DateTimePickerInput, { format });
