// yyyy-MM-ddThh:mm
const matchISODateTimeWithoutTimezone = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

export function formatSec(duration) {
  var formatedSec = new Date(duration * 1000).toISOString().substr(14, 5);
  return formatedSec.substr(0, 1) === "0"
    ? formatedSec.substr(1, 4)
    : formatedSec;
}

export function getDurationStr(duration) {
  return `${duration / 3600 > 1 ? parseInt(duration / 3600) + " hr" : ""} ${duration / 60 > 1 ? parseInt((duration % 3600) / 60) + " min" : ""
    } ${parseInt(duration % 60)} sec`.trim();
}

export function commentChildrenURL(id) {
  id = ("" + id).replace(/"/g, "");
  return `/manage-user-comments?filter=%7B"parent"%3A"${id}"%7D&&order=DESC&page=1&perPage=25&sort=date_created`;
}

export function commentParentURL(parent) {
  parent = ("" + parent).replace(/"/g, "");
  if (parent === "0") {
    return "/manage-user-comments";
  }
  return `/manage-user-comments/${parent}`;
}

export function createLectureURL(lectureId) {
  return `https://www.torahanytime.com/#/lectures?v=${lectureId}`;
}

export function createDoseURL(doseId) {
  return `https://dose.torahanytime.com/dose/${doseId}`;
}

export function createDoseUserCommentsURL(doseNumber) {
  return `/manage-user-comments?filter={"comment_type":"all","dose_number":"${doseNumber}"}&order=DESC&page=1&perPage=10&sort=created_at`;
}

export function createMailToURL(email) {
  return `mailto:${email}`;
}

export function createEditUserURL(userId) {
  return `/users/${userId}`;
}

export function createEditLectureURL(lectureId) {
  return `/lectures/${lectureId}`;
}

export function createEditDoseURL(doseId) {
  return `/daily_dose/${doseId}`;
}

export function createResourceURL(resource, id, action) {
  return `/${resource}/${id}${action ? "/" + action : ""}`;
}

/** Returns a new blank object. */
export function createObject() {
  return {};
}
/** Creates a react-admin filter object to filter a resource by `name`. */
export function filterByName(searchText) {
  return {
    name: searchText,
  };
}
/** Formats the given date value using the current browser locale.
 * @param {number} value
 */
export function formatDate(value) {
  const date = typeof value instanceof Date ? value : new Date(value);
  return date.toLocaleDateString();
}
/** Formats the given date/time value using the current browser locale.
 * @param {number} value
 */
export function formatDateTime(value) {
  const date = typeof value instanceof Date ? value : new Date(value);
  return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}
/** Formats the given date/time value to a date string WITHOUT timezone, for use
 * in an `<input type="date/time" />`.
 * @param {any} value The date/time value.
 */
export function formatDateTimeInput(value) {
  // Skip undefined, null or blank.
  if (value == null || value === "") {
    return "";
  }
  // Skip already valid date strings.
  if (matchISODateTimeWithoutTimezone.test(value)) {
    return value;
  }
  const d = typeof value instanceof Date ? value : new Date(value);
  return d.toISOString().slice(0, -5); // Chop off the `.000Z`
}
/** Formats a date/time value to a date string WITHOUT timezone for use in an
 * `<input type="datetime-local" />`.
 * @param {any} value The date/time value.
 * Originally from [ra-ui-materialui DateTimeInput](https://github.com/marmelab/react-admin/blob/v2.7.3/packages/ra-ui-materialui/src/input/DateTimeInput.js#L36)
 */
export function formatDateTimeInputLocal(value) {
  // Skip undefined, null or blank.
  if (value == null || value === "") {
    return "";
  }
  // Skip already valid date strings.
  if (matchISODateTimeWithoutTimezone.test(value)) {
    return value;
  }
  const d = typeof value instanceof Date ? value : new Date(value);
  return formatDateTimeLocal(d);
}
/** Formats the given date value with local time.
 * @param {date} value The value to convert
 * @returns {string} A standardized datetime (yyyy-MM-ddThh:mm).
 */
export function formatDateTimeLocal(value) {
  if (!(value instanceof Date) || isNaN(value)) return "";
  const yyyy = leftPad(value.getFullYear(), 4);
  const MM = leftPad(value.getMonth() + 1, 2);
  const dd = leftPad(value.getDate(), 2);
  const hh = leftPad(value.getHours(), 2);
  const mm = leftPad(value.getMinutes(), 2);
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
}
/** Returns `e.currentTarget.value`.
 * @param {React.SyntheticEvent<HTMLInputElement>} e
 */
export function getInputValue(e) {
  return e.currentTarget.value;
}
/** Returns true if the current location is for the given tab number.
 * @param {number|string} tabNumber The tab number to match.
 * @param {string} [absPath] The absolute path to search. Defaults to
 * `window.location.pathname`.
 */
export function isTabLocation(tabNumber, absPath = window.location.pathname) {
  const tab = "" + tabNumber;
  if (!absPath.endsWith(tab)) {
    return false;
  }
  const parts = absPath.split("/");
  // e.g. "/users/{id}/{tab}".split('/') == ["", "users", "{id}", "{tab}"]
  if (parts.length < 4) {
    return false;
  }
  return (
    // Match for TabbedForm.
    parts[3] === tab ||
    // Match for TabbedShowLayout.
    (parts[3] === "show" && parts[4] === tab)
  );
}
/** Left pad the given value.
 * @param {string} value The value to pad.
 * @param {number} count The total number of characters to return.
 * @param {string} padding The padding character.
 */
export function leftPad(value, count, padding = "0") {
  return (padding.repeat(count) + value).slice(-count);
}
/** Parses the date time and converts to an int and adjusts to UTC timezone
 * for sending dates to the server.
 * See https://github.com/marmelab/react-admin/blob/master/docs/Inputs.md#transforming-input-value-tofrom-record
 * @param {string} v
 * @returns {number}
 */
export function parseDateTimeInt(v) {
  // console.log("PARSING DATETIME: ", v);
  if (!v) {
    return v;
  }
  const d = new Date(v);
  if (Number.isNaN(d)) {
    return v;
  }
  const int = d.getTime();
  // console.log("PARSED DATETIME: ", int);
  return int;
}
/** Parses the date time and converts to an int and adjusts to UTC timezone
 * for sending dates to the server.
 * See https://github.com/marmelab/react-admin/blob/master/docs/Inputs.md#transforming-input-value-tofrom-record
 * @param {string} v
 * @returns {number}
 */
export function parseDateTimeIntUTC(v) {
  // console.log("PARSING DATETIME: ", v);
  if (!v) {
    return v;
  }
  const d = new Date(v);
  if (Number.isNaN(d)) {
    return v;
  }
  const int = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
  // console.log("PARSED DATETIME UTC: ", int);
  return int;
}
/** Function that returns null. */
export function returnNull() {
  return null;
}
/** Function that returns the given argument. */
export function returnArg(arg) {
  return arg;
}
/** From https://github.com/marmelab/react-admin/blob/v2.7.3/packages/ra-ui-materialui/src/field/sanitizeRestProps.js */
export function sanitizeFieldProps({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  linkType,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...rest
}) {
  return rest;
}

export function sanitizeInputProps({
  alwaysOn,
  basePath,
  component,
  defaultValue,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  options,
  optionText,
  optionValue,
  record,
  resource,
  allowEmpty,
  source,
  textAlign,
  translate,
  translateChoice,
  ...rest
}) {
  return rest;
}

/** Formats the given string by capitalizing the first letter.
 * @param {string} s */
export function toProperCase(s) {
  return s.substr(0, 1).toUpperCase() + s.substr(1);
}
