import React from "react";
import get from "lodash/get";

export class JsonTextField extends React.PureComponent {
  static defaultProps = {
    addLabel: true,
  };

  render() {
    const { record, source } = this.props;
    const value = get(record, source);
    const text = JSON.stringify(value, null, 2);
    return <pre>{text}</pre>;
  }
}
