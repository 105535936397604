import React from "react";
import { AppBar } from "react-admin";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { menuItemsByName } from "./MenuItems";
import { MediaUploadTool } from "../uploader";

const styles = {
  crumbs: {
    flex: 1,
    overflow: "hidden",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  crumb: {
    display: "inline",
  },
};

// #region Example Custom User Menu
// - Pass to `<AppBar userMenu={<CustomUserMenu />}>`
//
// const CustomUserMenu = translate(({ translate, ...props }) => (
//   <UserMenu {...props}>
//     <MenuItemLink
//       to="/configuration"
//       primaryText={translate("pos.configuration")}
//       leftIcon={<SettingsIcon />}
//     />
//   </UserMenu>
// ));
// #endregion

class TitleContentView extends React.PureComponent {
  render() {
    const {
      location: { pathname },
    } = this.props;
    const parts = pathname.split("/");
    const menuItem = menuItemsByName[parts[1]];
    let parentTitle;
    let parentURL;
    if (menuItem) {
      if (parts.length > 2) {
        if (menuItem.hasList) {
          parentTitle = menuItem.label;
          parentURL = menuItem.url;
        }
      }
      window.document.title = `${menuItem.label} | TA Admin`;
    } else {
      window.document.title = `TA Admin`;
    }
    return (
      <div style={styles.crumbs}>
        {parentTitle && (
          <>
            <Link
              to={parentURL}
              style={{ color: "inherit", cursor: "pointer" }}
            >
              <ArrowLeftIcon
                style={{ marginTop: -5, verticalAlign: "middle" }}
              />
              <Typography variant="title" color="inherit" style={styles.crumb}>
                {parentTitle}
              </Typography>
            </Link>
            <Typography variant="title" color="inherit" style={styles.crumb}>
              &nbsp;&nbsp;/&nbsp;&nbsp;
            </Typography>
          </>
        )}
        <Typography
          variant="title"
          color="inherit"
          id="react-admin-title"
          style={styles.crumb}
        />
      </div>
    );
  }
}

const TitleContent = withRouter(TitleContentView);

export default class CustomAppBar extends React.Component {
  render() {
    return (
      <AppBar {...this.props} position="fixed">
        <TitleContent />
        <MediaUploadTool />
      </AppBar>
    );
  }
}
