import resourceIcon from "@material-ui/icons/ChatBubbleOutline";
import UserCommentsList from "./UserCommentsList";

export const UserComments = {
  category: "manage",
  name: "manage-user-comments",
  icon: resourceIcon,
  list: UserCommentsList,
  options: { label: "Comments" },
};
