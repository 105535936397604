import React from "react";
import {
  Create,
  SimpleForm,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
  validation,
} from "../components";
import SanitizedP from "../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};

function CreateView(props) {
  return (
    <Create {...props} title="Upload Clip">
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <SanitizedP>
          TorahAnytime URL ending goes here. Only enter from "/". Do not include
          torahanytime.com (Ex. /live-event)
        </SanitizedP>
        <TextInput
          fullWidth
          source="original_url"
          label="TorahAnytime Url"
          validate={validate.required}
        />
        <SanitizedP>
          Enter the FULL URL to redirect users to (Ex.
          https://google.com/yadada)
        </SanitizedP>
        <TextInput
          fullWidth
          source="redirect_url"
          label="Redirect Url"
          validate={validate.required}
        />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<EditorToolbar showDelete />}>
        <TextField source="id" />
        <SanitizedP>
          TorahAnytime URL ending goes here. Only enter from "/". Do not include
          torahanytime.com (Ex. /live-event)
        </SanitizedP>
        <TextInput
          fullWidth
          source="original_url"
          label="Original Url"
          validate={validate.required}
        />
        <SanitizedP>
          Enter the FULL URL to redirect users to (Ex.
          https://google.com/yadada)
        </SanitizedP>
        <TextInput
          fullWidth
          source="redirect_url"
          label="Redirect Url"
          validate={validate.required}
        />
      </SimpleForm>
    </Edit>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="original_url" alwaysOn />
      <TextInput source="redirect_url" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Redirects"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="original_url" label="Original Url" />
        <TextField source="redirect_url" label="Redirect Url" />
      </Datagrid>
    </List>
  );
}

export const Redirects = {
  category: "manage",
  name: "manage-redirects",
  list: ListView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Redirects" },
  edit: EditView,
};
