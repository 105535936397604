import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Button } from "@material-ui/core";
import { authClient } from "../../server";

export const ManagePlayerSettings = {
  route: {
    name: "manage-player",
    path: "/misc/manage-player-settings/:id",
    view: JSONEditor,
  },
};

function JSONEditor() {
  const [settings, setSettings] = useState({});
  const [settingsToSave, setSettingsToSave] = useState({});
  const [playerName, setPlayerName] = useState("");
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [versions, setVersions] = useState([]);
  const [editVersionId, setEditVersionId] = useState();

  const saveSettings = async () => {
    setSettingsChanged(false);
    await authClient.put("/admin/manage-player-settings", {
      data: settingsToSave,
      playerName,
    });
  };

  useEffect(() => {
    (async function() {
      let _playerName = window.location.pathname.split("/")[3];
      setPlayerName(_playerName);
      const [_settingsResponse, _versionsResponse] = await Promise.all([
        authClient.get(
          `/admin/manage-player-settings?playerName=${_playerName}${
            editVersionId ? `&version_id=${editVersionId}` : ""
          }`,
        ),
        authClient.get(`/admin/manage-player-settings/${_playerName}/versions`),
      ]);
      setSettings(JSON.parse(_settingsResponse.body));
      console.log("JSONEditor", JSON.parse(_settingsResponse.body));
      setVersions(_versionsResponse.json);
      console.log(_versionsResponse.json);
    })();
  }, [playerName, editVersionId]);

  return (
    <div>
      <h1>Settings for the player {playerName}</h1>
      <div>
        Player located at{" "}
        <a
          target="_blank"
          href={`https://player.torahanytime.com/?player_name=${playerName}`}
          rel="noopener noreferrer"
        >{`https://player.torahanytime.com/?player_name=${playerName}`}</a>
      </div>
      <br />
      {editVersionId && (
        <div>
          Current values are based on version saved on{" "}
          {new Date(
            versions.find(v => v.VersionId === editVersionId).LastModified,
          ).toLocaleString()}
        </div>
      )}
      <JSONInput
        style={{
          width: "100%",
        }}
        id="a_unique_id"
        placeholder={settings}
        // colors={darktheme}
        locale={locale}
        height="550px"
        width="100%"
        onChange={e => {
          setSettingsChanged(true);
          setSettingsToSave(e.json);
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <Button
          disabled={!settingsChanged}
          variant={"outlined"}
          onClick={saveSettings}
        >
          Save Changes
        </Button>
      </div>

      <h3>Version history</h3>
      {versions.map(v => (
        <div key={v.VersionId}>
          <span>
            <span style={{ fontWeight: v.IsLatest ? "bold" : undefined }}>
              {" "}
              {new Date(v.LastModified).toLocaleString()}{" "}
            </span>
            <a
              target="_blank"
              href={`https://player.torahanytime.com?player_name=${playerName}&version_id=${v.VersionId}`}
            >
              Link
            </a>{" "}
            <button
              onClick={() => {
                if (v.IsLatest) {
                  setEditVersionId(undefined);
                } else {
                  setEditVersionId(v.VersionId);
                }
              }}
            >
              Load version
            </button>
          </span>
        </div>
      ))}
    </div>
  );
}
