/**
 * Open a preview window for html
 *
 * This can be used to preview email templates
 *
 * @param {string} html
 */
export const openPreview = html => {
  var wnd = window.open("about:blank", "", "_blank");
  wnd.document.write(html);
};
