import React from "react";
import { DateField } from "react-admin";
import get from "lodash/get";

export class TimeField extends React.PureComponent {
  render() {
    const { props } = this;
    const { record, source } = props;
    if (!record) return null;
    const value = get(record, source);
    if (value == null) return null;

    const date = value instanceof Date ? value : new Date(value);
    const dateString = date.toLocaleString();

    return (
      <DateField
        locale="en-US"
        options={{
          hour: "numeric",
          hour12: true,
          minute: "numeric",
        }}
        showTime
        title={dateString}
        {...props}
      />
    );
  }
}
