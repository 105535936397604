import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import { authClient } from "../../server";

export default function UpdateType(props) {
  const [clipTypes, setClipTypes] = useState([]);
  const [clipType, setClipType] = useState([]);
  const { selectedIds, open, onClose, handleUpdate } = props;
  useEffect(() => {
    (async function() {
      const types = await authClient.get("admin/clipnshare/types");
      console.log("types", types);
      setClipTypes(JSON.parse(types.body));
    })();
  }, []);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Update</DialogTitle>
      <DialogContent>
        <DialogContentText>Please choose the clip type.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          select
          label="Type"
          type="email"
          fullWidth
          variant="outlined"
          onChange={e => setClipType(e.target.value)}
          value={clipType}
        >
          {clipTypes.map((type, _i) => (
            <MenuItem key={_i} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleUpdate(clipType)} color="primary">
          Update {selectedIds.length} record{selectedIds.length > 1 && "s"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
