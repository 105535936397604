import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import resourceIcon from "@material-ui/icons/InsertDriveFile";
import { BoolNumInput, EditorToolbar, ListActions } from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <BoolNumInput label="Active" source="display_active" alwaysOn />
      <TextInput source="id" alwaysOn />
      <TextInput source="title" alwaysOn />
      <TextInput source="slug" alwaysOn />
      <TextInput source="author_name" alwaysOn />
      <TextInput source="author_email" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "created_at", order: "DESC" }}
      title="Posts"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <DateField source="created_at" showTime />
        <TextField source="title" />
        <TextField source="slug" />
        <TextField source="author_name" />
        <TextField source="author_email" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm
        redirect={false}
        onChange={value => {
          if (!value.slug) {
            value.slug =
              value.title && value.title.replace(/\s/g, "-").toLowerCase();
          }
        }}
        toolbar={<EditorToolbar />}
      >
        <DateField source="created_at" showTime />
        <TextField source="author_name" />
        <TextField source="author_email" />
        <UrlField
          source="url"
          label="Website"
          target="_blank"
          rel="noopener noreferrer"
        />
        <BoolNumInput source="display_active" />
        <TextInput source="title" />
        <TextInput source="slug" label="Slug" />
        <BoolNumInput source="comments_allow" />
        <BoolNumInput source="comments_show" />
        <BoolNumInput source="needs_password" />
        <TextInput source="password" />
      </SimpleForm>
    </Edit>
  );
}

export const Posts = {
  category: "database",
  name: "posts",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Posts" },
  edit: EditView,
};
