import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class SpeakerInput extends React.PureComponent {
  render() {
    const {
      label = "Speaker",
      source = "speaker",
      reference = "speakers",
      filterToQuery = filterByName,
      perPage = 100,
      sort = { field: "name", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput
          optionText={o => `${o.name} ${o.display_active ? "" : "- inactive"}`}
        />
      </ReferenceInput>
    );
  }
}
