import React from "react";
import { TextField } from "@material-ui/core";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";

export const ManagePlayerPage = makeSortableFormPage({
  createItem() {
    return {
      code: "",
      link: "",
      title: "",
    };
  },
  fetch() {
    let playerName = window.location.pathname.split("/")[3];
    return authClient.get(`/admin/manage-player?playerName=${playerName}`);
  },
  load(res) {
    const data = res.json;
    return {
      items: data.items,
    };
  },
  route: { name: "manage-player", path: "/misc/manage-player/:id" },
  renderItem({ actions, css, item, rowIndex }) {
    const { onChangeItem } = actions;
    return (
      <>
        <TextField
          label=""
          placeholder="Title"
          value={item.title}
          onChange={onChangeItem(rowIndex, "title")}
          className={css.rowField}
          required
          error={!item.title}
        />
        <TextField
          label=""
          placeholder="Link URL"
          value={item.link}
          onChange={onChangeItem(rowIndex, "link")}
          className={css.rowField}
          required
          error={!item.link}
        />
        <TextField
          label=""
          placeholder="code (for use in url - no spaces and all lowercase)"
          value={item.code}
          onChange={onChangeItem(rowIndex, "code")}
          className={css.rowField}
          required
          error={!item.code}
        />
      </>
    );
  },
  save(state) {
    let playerName = window.location.pathname.split("/")[3];
    return authClient.put("/admin/manage-player", {
      items: [...state.items],
      playerName,
    });
  },
  subtitle: "",
  title: "Manage Embedded Video Player",
});
