import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Title } from "react-admin";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { connectCommon } from "../connectCommon";
import { createObject, getInputValue, returnNull } from "../utils";

import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import DragRowIcon from "@material-ui/icons/SwapVert";

import css from "./SortableFormPage.module.scss";

const DragHandle = SortableHandle(() => (
  <span className={classNames("hover50", css.rowHandle)} title="Drag to move.">
    <DragRowIcon />
  </span>
));

function getDefaultInitialState() {
  return {
    items: [],
  };
}

/** Creates a new sortable form page.
 * @param {SortableFormPageOptions} options
 */
export function makeSortableFormPage(options) {
  if (!options.getInitialState) {
    options.getInitialState = getDefaultInitialState;
  }

  const SortableItem = SortableElement(props => {
    const { renderItem, ...rest } = props;
    const { actions, rowIndex } = rest;
    const { clearRow, deleteRow } = actions;
    const [focusedField, setFocusedField] = React.useState("");
    const { maxItems } = options;
    return (
      <div className={css.row}>
        <DragHandle />
        <div className={css.rowBody}>
          <div className={css.rowButtons}>
            <FormControlLabel
              control={<ClearIcon />}
              label="Clear"
              className={classNames("hover50", css.rowButton)}
              title="Clear all fields."
              onClick={clearRow(rowIndex)}
            />
            {(!maxItems || maxItems > 1) && (
              <FormControlLabel
                control={<DeleteIcon />}
                label="Delete"
                className={classNames("hover50", css.rowButton)}
                title="Delete this row."
                onClick={deleteRow(rowIndex)}
              />
            )}
          </div>
          {renderItem({
            ...rest,
            actions: {
              ...actions,
              setFocusedField,
            },
            css,
            focusedField,
          })}
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items, actions }) => {
    const { createItem = createObject, renderItem = returnNull } = options;
    return (
      <div>
        {items.map((item = createItem(), index) => (
          <SortableItem
            key={index}
            index={index}
            actions={actions}
            item={item}
            renderItem={renderItem}
            rowIndex={index}
          />
        ))}
      </div>
    );
  });

  class SortableFormPage extends React.Component {
    state = options.getInitialState();

    addRow = () => {
      this.setState(state => {
        return {
          ...state,
          items: [...state.items, options.createItem()],
          changed: true,
        };
      });
    };

    clearRow = index => {
      return () => {
        this.setState(state => {
          const { items } = state;
          return {
            ...state,
            items: items.map((v, i) =>
              i !== index ? v : options.createItem(),
            ),
            changed: true,
          };
        });
      };
    };

    deleteRow = index => {
      return () => {
        if (!window.confirm("Are you sure?")) {
          return;
        }
        this.setState(state => {
          const { items } = state;
          return {
            ...state,
            items: items.filter((v, i) => i !== index),
            changed: true,
          };
        });
      };
    };

    loadState = res => {
      this.setState({
        ...options.load(res),
        changed: false,
        invalidFields: [],
      });
    };

    /**
     * @param {string} field The field name.
     * @param {(e:React.SyntheticEvent<HTMLInputElement>)=>any} [getValue] */
    onChangeField = (field, getValue = getInputValue) => {
      return (...args) => {
        const value = getValue(...args);
        this.setState({
          [field]: value,
          changed: true,
        });
      };
    };

    /**
     * @param {number} index The item index.
     * @param {string} field The field name.
     * @param {(e:React.SyntheticEvent<HTMLInputElement>)=>any} [getValue] */
    onChangeItem = (index, field, getValue = getInputValue) => {
      return (...args) => {
        //TODO figure out how to add validation
        // let invalidFields = new Set(this.state.invalidFields);
        // console.log(this.state);
        // if (args[0].target.validity.valid) {
        //   invalidFields.add(field + "_" + index);
        // } else {
        //   invalidFields.delete(field + "_" + index);
        // }

        const value = getValue(...args);
        this.setState(state => {
          const items = [...state.items];
          items[index] = {
            ...items[index],
            [field]: value,
          };
          return {
            ...state,
            //invalidFields: Array.from(invalidFields),
            items,
            changed: true,
          };
        });
      };
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
      this.setState(({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
        changed: true,
      }));
    };

    save = () => {
      const { state } = this;
      //console.log(state);
      if (state.changed) {
        this.props.fetchStart();
        options
          .save(state)
          .then(this.loadState)
          .catch(this.props.showError)
          .finally(this.props.fetchEnd);
      }
    };

    componentDidMount() {
      this.props.fetchStart();
      options
        .fetch()
        .then(this.loadState)
        .catch(this.props.showError)
        .finally(this.props.fetchEnd);
    }

    render() {
      const state = this.state;
      const { maxItems, renderFields, subtitle, title } = options;
      return (
        <Card>
          <Title title={title} />
          <CardContent className={css.content}>
            <div className={css.topbar}>
              {(!maxItems || maxItems > 1) && (
                <Button variant="contained" size="small" onClick={this.addRow}>
                  Add
                </Button>
              )}
              {subtitle && (
                <Typography variant="caption" className={css.subtitleText}>
                  {subtitle}
                </Typography>
              )}
            </div>
            <SortableList
              items={state.items}
              actions={this}
              onSortEnd={this.onSortEnd}
              hideSortableGhost={true}
              useDragHandle={true}
            />
            {renderFields && (
              <div className={css.formFields}>
                {renderFields({
                  actions: this,
                  css,
                  state,
                })}
              </div>
            )}
            <MuiToolbar role="toolbar" className={css.toolbar}>
              <Button
                variant="contained"
                color={state.changed ? "primary" : "default"}
                size="small"
                onClick={this.save}
                className={css.saveButton}
              >
                Save
              </Button>
            </MuiToolbar>
          </CardContent>
        </Card>
      );
    }
  }
  SortableFormPage.displayName = `SortableFormPage(${
    options.menu ? options.menu.name : options.route.name
  })`;
  return {
    menu: options.menu,
    route: {
      exact: true,
      ...options.route,
      view: withRouter(connectCommon(SortableFormPage)),
    },
  };
}

// #region Typedefs
/** @typedef {import("../../server").FetchJsonResponse} FetchJsonResponse */
/** @typedef {object} SortableFormPageOptions
 * @property {()=>any} createItem
 * @property {()=>Promise<>} fetch
 * @property {()=>{items:object[]}} getInitialState
 * @property {(res:FetchJsonResponse)=>any} load
 * @property {number} [maxItems]
 * @property {import("../../layout/MenuItems").MenuItemInfo} menu
 * @property {React.ReactNode} title
 * @property {()=>React.ReactNode} renderFields
 * @property {()=>React.ReactNode} renderItem
 * @property {(state:any)=>Promise<FetchJsonResponse>} save
 * @property {React.ReactNode} subtitle
 */
// #endregion
