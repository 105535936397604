import React from "react";
import {
  Filter,
  DateTimeInput,
  DateInput,
  TextInput,
  SelectInput,
  Edit,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
} from "react-admin";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { EditorToolbar } from "../../components";

function EditView(props) {
  return (
    <Edit {...props} title="Notify Bar">
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <DateTimeInput
          source="run_at"
          label="Schedule Date &amp; Time (New York)"
        />
      </SimpleForm>
    </Edit>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput source="lecture_id" alwaysOn />
      <TextInput source="speaker_id" alwaysOn />
      <DateInput source="run_at" alwaysOn />
      <SelectInput
        alwaysOn
        source="status"
        choices={[
          { id: "pending", name: "Pending" },
          { id: "in_progress", name: "In Progress" },
          { id: "done", name: "Done" },
          { id: "failed", name: "Failed" },
        ]}
      />
      <SelectInput
        alwaysOn
        source="handler"
        label="Schedule Type"
        choices={[
          { id: "schedulePublishLecture", name: "Publish Lecture" },
          { id: "emailDoseBroadcast", name: "Dose Emails" },
          { id: "scheduleDisplayDailyDose", name: "Dose Releases" },
          { id: "publishDosePodcast", name: "Dose Podcast" },
        ]}
      />
      <TextInput source="note" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      // actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ status: "pending" }}
      filters={<FiltersView />}
      title="Schedules"
      sort={{ field: "run_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          source="status"
          label="Status"
          render={record => {
            const { status } = record;
            if (!status) return null; // Can be null?
            let display = status;
            const split = status.split("_");
            if (split.length > 1) {
              display = `${split[0]} ${split[1]}`;
            }
            return (
              <span style={{ textTransform: "capitalize" }}>{display}</span>
            );
          }}
        />
        <DateField source="run_at" label="Schedule Date" showTime />
        <TextField source="speaker_name" label="Speaker" />
        <TextField source="file_name" label="Filename" />
        <TextField source="note" label="Note" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Schedules = {
  category: "manage",
  name: "manage-schedules",
  edit: EditView,
  list: ListView,
  icon: ScheduleIcon,
  options: { label: "Schedules" },
  roles: ["dose_email_admin", "schedules_admin"],
  permission: {
    schedules_admin: ["list", "edit"],
  },
};
