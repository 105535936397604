import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  // Edit,
  FileField,
  FileInput,
  Filter,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/PictureAsPdf";
import { EditorToolbar, UrlTextField } from "../../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
      <TextInput source="id" alwaysOn />
    </Filter>
  );
}

function CreateView(props) {
  return (
    <Create {...props}>
      <SimpleForm redirect="edit" toolbar={<EditorToolbar />}>
        <FileInput
          source="FILE_data"
          label="PDF Upload"
          accept="application/pdf"
          placeholder={<p>Click or drop your file here.</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

function ShowView(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="date_created" label="Created" />
        <UrlTextField
          source="url"
          sourceText="name"
          label="Name"
          target="_blank"
          rel="noopener noreferrer"
        />
        <TextField source="size" label="Size" />
        <TextField source="uploader_email" label="Uploader" />
        <TextField source="uploader_name" label="" />
        <TextField source="id" label="Id" />
      </SimpleShowLayout>
    </Show>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      exporter={false}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "date_created", order: "DESC" }}
      title="Newsletter PDFs"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <DateField source="date_created" label="Created" />
        <TextField source="name" label="Name" />
        <TextField source="size" label="Size" />
        <TextField source="uploader_email" label="Uploader" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const NewsletterPDFs = {
  category: "manage",
  name: "newsletter-pdfs",
  roles: ["newsletter_admin"],
  create: CreateView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Newsletter PDFs" },
  show: ShowView,
};
