import React from "react";
import {
  Create,
  SimpleForm,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
  BooleanInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
  validation,
} from "../components";
import SanitizedP from "../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};
const imgNameMessage =
  "Image name with extension goes here (e.g. test.png). The image must be on https://cloud.digitalocean.com/spaces/torahanytime-cdn?i=955929&path=images%2F. Alternatively, an icon can be specified.";
function CreateView(props) {
  return (
    <Create {...props} title="Upload Clip">
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
        <TextInput fullWidth source="position" label="Position" type="number" />
        <TextInput source="emoji" label="Emoji" />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<EditorToolbar showDelete />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
        <TextInput fullWidth source="position" label="Position" type="number" />
        <TextInput source="emoji" label="Emoji" />
      </SimpleForm>
    </Edit>
  );
}

function FiltersView(props) {
  return <Filter {...props}></Filter>;
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Dose Dedication types"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="icon_string" label="Image Name" />
        <TextField source="primary_type" label="Primary Type?" />
        <TextField source="emoji" label="Emoji" />
        <TextField source="label" label="Label" />
        <TextField source="position" label="Position" />
      </Datagrid>
    </List>
  );
}

export const DoseDedicationTypes = {
  category: "dose",
  name: "manage-dose-dedication-types",
  list: ListView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Dose Dedication Types" },
  edit: EditView,
};
