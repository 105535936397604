import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  Create,
  Filter,
  ImageField,
  List,
  LongTextInput,
  SimpleForm,
  TextField,
  TabbedForm,
  TextInput,
} from "react-admin";
import {
  BoolNumInput,
  DateTimeInput,
  EditorToolbar,
  ListActions,
  OrganizationInput,
  // validation,
} from "../../components";
import resourceIcon from "@material-ui/icons/Event";
import { BoolNumField } from "../../components/field/BoolNumField";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="Title" source="event_title" alwaysOn />
      <OrganizationInput
        label="Organization"
        source="organization"
        reference="organizations"
        alwaysOn
      />
    </Filter>
  );
}
const validateDateCreated = (values) => {
  const errors = {};
  if (!values.date_start) {
    errors.date_start = ["Date start is required"];
  }
  if (!values.date_end) {
    errors.date_end = ["Date end is required"];
  }
  if (values.date_start > values.date_end) {
    errors.date_start = ["Date start must be less than Date end!"];
    errors.date_end = ["Date end must be greater than Date start!"];
  }
  return errors;
};

function renderEditForm({ redirect, props }) {
  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
      validate={validateDateCreated}
    >
      <TextField source="id" />
      <TextInput source="event_title" />
      <BoolNumInput source="display_active" />
      <DateTimeInput source="date_start" />
      <DateTimeInput source="date_end" />
      <OrganizationInput source="organization_id" />
      <LongTextInput source="event_picture" label="Event Picture" />
    </SimpleForm>
  );
}
function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false, props: props })}
    </Edit>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}
function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "date_start", order: "DESC" }}
      title="Conventions"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="event_title" label="Title" />
        <BoolNumField source="display_active" label="Active" />
        <DateField
          source="date_start"
          showTime
          label="Start"
          style={{ minWidth: 160 }}
        />
      </Datagrid>
    </List>
  );
}

export const Conventions = {
  category: "manage",
  name: "manage-conventions",
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  create: CreateView,
  options: { label: "Conventions" },
};
