import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  LongTextInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Toc";
import { EditorToolbar, ListActions } from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput label="Entry" source="entry" alwaysOn />
    </Filter>
  );
}

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar showDelete />}>
      <LongTextInput
        source="entry"
        label="Entry"
        helperText={
          <>
            A list of synonym words or phrases, separated by commas.
            <br />
            <br />
            Example:{" "}
            <em style={{ fontWeight: 600 }}>
              funny, humor, humorous, comical, hilarious
            </em>
            <br />
            <br />
            Explicit mappings can be separated by arrows. For example:{" "}
            <em style={{ fontWeight: 600 }}>
              humorous, comical, hilarious =&gt; funny, humor
            </em>
            <br />
            <br />
            In this explicit mapping, a search for 'comical' will produce
            results containing 'funny' or 'humor' but not actually the word
            'comical' unless that word was also placed on the right side of the
            <em style={{ fontWeight: 600 }}>=&gt;</em>
            <br />
            <br />
            See more{" "}
            <a
              href="https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-synonym-tokenfilter.html#_solr_synonyms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgba(0,0,0,0.54)" }}
            >
              developer notes here
            </a>
            .
          </>
        }
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "list" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: "list" })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      title="Search Synonyms"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="entry" label="Entry" sortable={false} />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const SearchSynonyms = {
  category: "misc",
  name: "search-synonyms",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Search Synonyms" },
};
