import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  DisabledInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Label";
import {
  BoolNumField,
  BoolNumInput,
  EditorToolbar,
  ListActions,
  validation,
} from "../components";

const validate = {
  name: [validation.required(), validation.minLength(2)],
  order: [validation.required(), validation.number()],
  parent: [validation.required(), validation.number()],
  parent_order: [validation.required(), validation.number()],
};

function FiltersView(props) {
  return (
    <Filter {...props}>
      <BoolNumInput label="Active" source="display_active" alwaysOn />
      <TextInput source="parent" alwaysOn />
      <TextInput source="id" alwaysOn />
      <TextInput label="Name" source="name" alwaysOn />
    </Filter>
  );
}

function renderEditForm({ redirect }) {
  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
      onChange={(value) =>
        (value.slug =
          value.name && value.name.replace(/\s/g, "-").toLowerCase())
      }
    >
      <ImageField
        source="image_url"
        title="Image"
        style={{
          float: "right",
          width: "70%",
        }}
      />
      <TextInput source="name" label="Name" validate={validate.name} />
      <DisabledInput source="slug" label="Slug" />
      <SanitizedP>
        Order places a topic in a certain order on the topics page. A lower
        number will place it higher on the page. All topics with the same order
        number will be ordered in Alphabetical order. Also affects sub-topic
        placement, relative to other "sibling" sub-topics e.g. Parasha
        placement. Typical topic order is 3.
      </SanitizedP>
      <NumberInput
        source="order"
        label="Order"
        validate={validate.order}
        defaultValue={3}
      />
      <SanitizedP>
        Parent topic. All sub-topics have their parent topic set here. All
        parent topics on the site have parent set to 6.
      </SanitizedP>
      <NumberInput
        source="parent"
        label="Parent"
        validate={validate.parent}
        defaultValue={6}
      />
      <SanitizedP>
        Parent Order is used to "tell" the site whether there are sub-topics.
        E.g. Parasha has a parent order of 23. The pattern, when adding a new
        parent category, is to use the category ID as the parent order. All
        other topics should be set to -1.
      </SanitizedP>
      <NumberInput
        source="parent_order"
        label="Parent Order"
        validate={validate.parent_order}
        defaultValue={-1}
      />
      <BoolNumInput source="display_active" label="Active" defaultValue={1} />
      <LongTextInput
        source="image"
        label="Image File Name"
        title="(Clear this field and click Save to remove the image.)"
      />
      <ImageInput
        source="FILE_image"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop your file here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <NumberInput source="dial_in_ext" label="Dial In Extension" />

      <h4>JewishAnytime info</h4>
      <NumberInput
        source="ja_parent"
        label="JewishAnytime Parent"
        defaultValue={null}
      />
      <TextInput
        source="ja_icon"
        label="JewishAnytime Icon"
        defaultValue={null}
      />
      <TextInput
        source="english_name"
        label="English Display Name"
        defaultValue={null}
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}
// SanitizedP is how we resolve the below issue for <p></p> DOM element
// Warning: React does not recognize the `basePath` prop on a DOM element.  If you
// accidentally passed it from a parent component, remove it from the DOM
// element.
const SanitizedP = ({ basePath, ...props }) => {
  return <p {...props} />;
};
function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      title="Categories"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <ImageField source="image_url" label="Image" />
        <TextField source="name" label="Name" />
        <TextField source="slug" label="Slug" />
        <NumberField source="order" label="Order" />
        <NumberField source="parent" label="Parent" />
        <NumberField source="ja_parent" label="Jewish Anytime Parent" />
        <NumberField source="parent_order" label="Parent Order" />
        <BoolNumField source="display_active" label="Active" />
        <NumberField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Categories = {
  category: "database",
  name: "categories",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Categories" },
};
