// Manage
import { NotifyBar } from "./manage/notifybar";
import { HomepageCategories } from "./manage/homepage-categories";
import { MobileAppCategories } from "./manage/mobile-categories";
import { NewsletterPDFs } from "./manage/newsletter-pdfs";
import { Newsletters } from "./manage/newsletters";
import { LiveEvents } from "./manage/live-events";
import { Conventions } from "./manage/conventions";
import { UserComments } from "./user_comments";
import { Redirects } from "./redirects";
import { Schedules } from "./manage/schedules";
import { News_announcements } from "./manage/news_announcements";
import { FAQ } from "./manage/faq";
// Database
import { BetaUsers } from "./beta_users";
import { Categories } from "./categories";
import { CampaignDonations } from "./campaign_donations";
import { CampaignDedications } from "./campaign_dedications";
import { CampaignPackages } from "./campaign_packages";
import { Clips } from "./clips";
import { CorporateDoseSponsor } from "./corpSponsors/dose_dedications";
import { Dedications } from "./dedications";
import { Donations } from "./donations";
import { DoseDedicationTypes } from "./dose_dedication_types";
import { DoseDonors } from "./dose_donors";
import { DoseDedications } from "./dose_dedications";
import { DoseEmailSubscribers } from "./dose_email_subscribers";
import { DoseEmailSent } from "./dose_email_sent";
import { DoseDedicationsReporting } from "./dose_dedications_reporting";
import { DoseDiscountCodes } from "./dose_discount_codes";
import { DonationsLegacy } from "./donations_legacy";
import { Doses } from "../pages/email/daily_dose/DisplayDoses";
import { EmailTemplateEditor } from "./corpSponsors/email_template_editor";
import { Languages } from "./languages";
import { Lectures } from "./lectures";
import { Locations } from "./locations";
import { Organizations } from "./organizations";
import { Posts } from "./posts";
import { Projects } from "./projects";
import { SpeakerTitles } from "./speaker-titles";
import { Speakers } from "./speakers";
import { UploadsRendered } from "./uploads/rendered";
import { UploadsRendering } from "./uploads/rendering";
import { Users } from "./users";
// Stats
import { LectureStats } from "./stats/lectures";
import { SpeakerStats } from "./stats/speakers";
// Email
import { EmailSubscribers } from "./email/subscribers";
// Campaigns
import { MishloachManot } from "./campaigns/mishloach-manot";
// Misc
import { SearchSynonyms } from "./search_synonyms";
import { DbOperationLogs } from "./db_operation_logs";
// TorahAnytime 3.0
import { Currencies } from "./currencies";
import { DoseCurrenciesAmounts } from "./dose_currencies_amounts";
import { Banners } from "./banners";
import { Homepage } from "./homepage";
import { Settings, SettingsTypes } from "./settings";
import { Layouts } from "./layouts";
import { Partners } from "./partners";
import { Ads } from "./ads";
import { Series } from "./series";
import { Programs } from "./tisha_bav_programs";
import { TbavClasses } from "./tisha_bav_classes";
import { TbavSponsors } from "./tisha_bav_sponsors";
import { TbavAds } from "./tisha_bav_ads";
import { EmailTemplates } from "./email/email_templates";
import { LectureSponsors } from "./lecture_sponsors";
import { ClipNShare } from "./clipnshare";

// NOTE: The order of the default exported array determines the render order.

export default [
  // Manage
  NotifyBar,
  HomepageCategories,
  MobileAppCategories,
  LiveEvents,
  Conventions,
  NewsletterPDFs,
  Newsletters,
  Redirects,
  Schedules,
  News_announcements,
  FAQ,
  // Corporate Sponsors
  CorporateDoseSponsor,
  EmailTemplateEditor,
  // Daily Dose
  DoseCurrenciesAmounts,
  DoseDedicationTypes,
  DoseDedications,
  DoseDonors,
  DoseDiscountCodes,
  DoseDedicationsReporting,
  DoseEmailSubscribers,
  DoseEmailSent,
  EmailTemplates,

  // Database
  BetaUsers,
  Categories,
  CampaignDedications,
  CampaignDonations,
  CampaignPackages,
  Clips,
  ClipNShare,
  Dedications,
  Donations,
  DonationsLegacy,
  Doses,
  Languages,
  Lectures,
  LectureSponsors,
  Locations,
  Organizations,
  Posts,
  Projects,
  Speakers,
  SpeakerTitles,
  UploadsRendered,
  UploadsRendering,
  Users,
  UserComments,
  // Stats
  LectureStats,
  SpeakerStats,
  // Email
  EmailSubscribers,
  // Campaigns
  MishloachManot,
  // Misc
  SearchSynonyms,
  DbOperationLogs,
  // TorahAnytime 3.0
  Currencies,
  Banners,
  Homepage,
  Settings,
  SettingsTypes,
  Layouts,
  Partners,
  Ads,
  Series,
  Programs,
  TbavClasses,
  TbavSponsors,
  TbavAds,
];
