import React from "react";

export const PageInProgress = ({ legacyURL, legacyTitle }) => (
  <div
    style={{
      width: 240,
      margin: "1em",
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: 24,
    }}
  >
    <p>
      NOTE: Page in progress. Please use the legacy
      <br />
      <a href={legacyURL} target="_blank" rel="noopener noreferrer">
        {legacyTitle}
      </a>
      <br />
      page for now...
    </p>
  </div>
);
