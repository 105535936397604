import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/BorderTop";

export const ManageBannersTopPage = makeSortableFormPage({
  createItem() {
    return {
      image: "",
      link: "",
      onclick: "",
      title: "",
    };
  },
  fetch() {
    return authClient.get("/admin/manage-banners/top");
  },
  load(res) {
    const data = res.json;
    return {
      items: data.items,
    };
  },
  menu: {
    category: "banners",
    icon,
    name: "top-banners",
    label: "Top",
    url: "/manage-banners/top",
  },
  renderItem({ actions, css, item, rowIndex }) {
    const { onChangeItem } = actions;
    return (
      <>
        <TextField
          label=""
          placeholder="Image URL"
          value={item.image}
          onChange={onChangeItem(rowIndex, "image")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Title"
          value={item.title}
          onChange={onChangeItem(rowIndex, "title")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Link URL"
          value={item.link}
          onChange={onChangeItem(rowIndex, "link")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="JS onclick function()"
          value={item.onclick}
          onChange={onChangeItem(rowIndex, "onclick")}
          className={css.rowField}
        />
      </>
    );
  },
  save(state) {
    return authClient.put("/admin/manage-banners/top", {
      items: [...state.items],
    });
  },
  subtitle: (
    <>
      NOTE: Only banners containing <strong>Image URL</strong> will be shown on
      the website.
    </>
  ),
  title: "Top Banners",
});
