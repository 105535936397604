import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
} from "react-admin";
/**
 * Validation functions
 * See https://marmelab.com/react-admin/CreateEdit.html#validation
 */
export const validation = {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
};
