import React, { useState } from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  LongTextInput,
  addField,
} from "react-admin";
import { Switch, FormControlLabel } from "@material-ui/core";
import resourceIcon from "@material-ui/icons/Contacts";
import {
  DefaultBulkActionButtons,
  EditorToolbar,
  ListActions,
} from "../../components";
import AceEditor from "react-ace";
import beautify from "js-beautify";

import "ace-builds/src-min-noconflict/keybinding-vscode";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";

function FiltersView(props) {
  return <Filter {...props}></Filter>;
}

const AceHtmlInput = addField(({ input, meta: { touched, error } }) => {
  const { onChange, ...restInput } = input;
  const [html, setHtml] = useState("");
  const [split, setSplit] = useState(true);
  const onEditorChange = newValue => {
    setHtml(newValue);
    onChange(newValue);
  };
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={split}
              onChange={e => setSplit(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Split View"
        />
      </div>
      <div style={{ display: "flex" }}>
        <AceEditor
          setOptions={{ useWorker: false }}
          width={split ? "49%" : "100%"}
          height="90vh"
          mode="html"
          theme="monokai"
          showPrintMargin={false}
          {...restInput}
          onLoad={editor => setHtml(editor.getValue())}
          onChange={onEditorChange}
          onBlur={() => onEditorChange(beautify.html(html))} // otherwise was erasing some content (maybe was sanitizing it)
        />
        <div
          style={{
            width: "49%",
            height: "100vh",
            display: split ? undefined : "none",
          }}
        >
          <iframe
            style={{ width: "100%", height: "100vh" }}
            srcDoc={html}
            title="AceHtmlInput"
          />
        </div>
      </div>
    </div>
  );
});

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar showDelete />}>
      <TextInput source="name" label="Name" />
      <LongTextInput source="from" label="From" />
      <LongTextInput source="to" label="To" />
      <LongTextInput source="subject" label="Subject" />
      <AceHtmlInput source="content" label="Content" />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "list" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  );
}

export const EmailTemplates = {
  category: "dose",
  name: "email-templates",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Email Templates" },
};
