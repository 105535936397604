import React, {useState, useEffect} from "react";
import {
    Datagrid,
    List,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    DateField,
    DateInput,
    Create,
    BooleanInput,
    BooleanField,
    Filter,
} from "react-admin";
import {authClient} from "../server";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
    ListActions,
    MorePagination,
    DefaultBulkActionButtons,
} from "../components";

const cleanDedicationName = name =>
    name
        .split("<br>")
        .map(w => w.trim())
        .join(" ");

let sources = {
    dedicationName: "dedication_name",
    dedicationDate: "dedication_day",
    dedicationType: "dose_dedication_type.label",
    label: "label",
    dedicationTypeId: "dedication_type_id",
    dose: "is_dose",
    status: "is_status",
    approved: "is_approved",
    doubleDose: "is_double_dose"
};

let labels = {
    dedicationType: "Dedication Type",
    dedicationDate: "Dedication Date",
    dedicatedFor: "Dedication Name",
    status: "Status",
    approved: "Approval status",
    revoked: "Revoked",
    from: "From",
    doubleDose: "Double Dose"
};

let titles = {
    doseDedications: "Dose Dedications",
    createDoseDedications: "Create Dose Dedications",
    editDoseDedications: "Edit Dose Dedications",
};



function ListChoices() {

    const [dedicationsList, setDedicationsList] = useState([]);

    useEffect(() => {
        authClient.get("/admin/manage-dose-dedication-types?_start=0&_end=1000&order=ASC&_sort=created_at")
            .then(res => {
                setDedicationsList(res.json);
            })
            .catch(console.error);
    }, []);

    const listChoices = dedicationsList.map((dedication) => (
            {id: dedication.id, name: dedication.label}
        )
    );

    return (
        <SelectInput source={sources.dedicationTypeId} label={labels.dedicationType}
                     choices={listChoices}/>
    )
}

function FiltersView(props){
    return (
        <Filter {...props}>
            <DateInput label={labels.from} source={sources.dedicationDate} alwaysOn />
            <TextInput source={sources.dedicationName} alwaysOn />
            {/*this function needs a label to appear in the "Additional Filter" list*/}
            <ListChoices label={labels.dedicationType} />
        </Filter>
    )
}

function ListView(props) {
    return (
        <List
            {...props}
            actions={<ListActions style={{marginRight: 700}}/>}
            exporter={true}
            filters={<FiltersView />}
            perPage={50}
            pagination={<MorePagination/>}
            sort={{
                field: "created_at",
                order: "DESC",
            }}
            title={titles.doseDedications}
            bulkActionButtons={
                <DefaultBulkActionButtons style={{marginRight: 700}}/>
            }
        >

            <Datagrid rowClick="edit">
                <TextField source={sources.dedicationName}
                           label={cleanDedicationName(labels.dedicatedFor)}/>
                <TextField source={sources.dedicationType}
                           label={cleanDedicationName(labels.dedicationType)}/>
                <BooleanField source={sources.approved} label={labels.approved}/>
                <DateField source={sources.dedicationDate} label={labels.dedicationDate} options={{timeZone: "UTC"}}/>
            </Datagrid>
        </List>
    );
}

function CreateView(props) {

    return (
        <Create {...props} title={titles.createDoseDedications}>
            <SimpleForm redirect="list">
                <DateInput source={sources.dedicationDate} label={labels.dedicationDate}/>
                <TextInput source={sources.dedicationName}
                           label={cleanDedicationName(labels.dedicatedFor)}/>
                <BooleanInput source={sources.doubleDose} defaultValue={true} label={labels.doubleDose}/>
                <BooleanInput source={sources.approved} defaultValue={true} label={labels.approved}/>
                <ListChoices/>
                <TextInput disabled={true} source={sources.dose} label={labels.status} defaultValue={true} />
                <TextInput disabled={true} source={sources.status} label={labels.status} defaultValue={true} />
            </SimpleForm>
        </Create>
    );
}

function EditView(props) {

    return (
        <Edit {...props} title={titles.editDoseDedications}>
            <SimpleForm>
                <TextInput source={sources.dedicationName}
                           label={cleanDedicationName(labels.dedicatedFor)}/>
                <ListChoices/>
                <DateInput source={sources.dedicationDate} label={labels.dedicationDate}/>
                <BooleanInput source={sources.doubleDose} defaultValue={true} label={labels.doubleDose}/>
                <BooleanInput source={sources.approved} label={labels.approved}/>
            </SimpleForm>
        </Edit>
    );
}

export const DoseDedications = {
    category: "dose",
    name: "manage-dose-dedications",
    list: ListView,
    create: CreateView,
    edit: EditView,
    icon: resourceIcon,
    options: {label: "Dose Dedications"},
    roles: ["dose_dose_dedications_admin"],
    permissions: {
        dose_dedications_admin: ["create", "list", "edit"],
    },
};
