import React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  Show,
  SimpleShowLayout,
} from "react-admin";
import resourceIcon from "@material-ui/icons/People";
import { ListActions, DateTimeField } from "../components";
import SendEmailButton from "./betaUsers/SendEmailButton";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="First Name" source="first_name" alwaysOn />
      <TextInput label="Last Name" source="last_name" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
      <TextInput source="sent_email" label="Email Sent" alwaysOn />
      <TextInput source="completed_registration" label="Registered" alwaysOn />
      <TextInput source="id" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      bulkActionButtons={<SendEmailButton />}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Beta Users"
    >
      <Datagrid rowClick="show">
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField label="Phone" source="phone" />
        <TextField source="email" label="Email" />
        <TextField source="verify_attempts" label="Verification Attempts" />
        <TextField source="sent_email" label="Email Sent" />
        <TextField source="completed_registration" label="Registered" />
        <DateTimeField source="created_at" label="Submitted At" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Packages"
    >
      <SimpleShowLayout>
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField label="Phone" source="phone" />
        <TextField source="email" label="Email" />
        <TextField source="verify_attempts" label="Verification Attempts" />
        <TextField source="sent_email" label="Email Sent" />
        <TextField source="completed_registration" label="Registered" />
        <DateTimeField source="created_at" label="Submitted At" />
        <TextField source="id" label="Id" />
      </SimpleShowLayout>
    </Show>
  );
}

export const BetaUsers = {
  category: "database",
  name: "beta_users",
  icon: resourceIcon,
  list: ListView,
  show: ShowView,
  options: { label: "Beta Users" },
};
