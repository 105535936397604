import React from "react";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  LongTextInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/NotificationsActive";
import { EditorToolbar } from "../../components";

function EditView(props) {
  return (
    <Edit {...props} title="Notify Bar">
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <BooleanInput source="data.show" label="Show Notify Bar on Website" />
        <LongTextInput
          source="data.msg"
          label="Message (NOTE: Insert {{countdown}} where you want to coutdown timer to be.)"
        />
        <BooleanInput
          source="data.countdown_show"
          label="Show Countdown Timer"
        />
        <DateTimeInput
          source="countdown_datetime"
          label="Countdown Date &amp; Time (New York)"
        />
        <TextInput source="data.color_scheme" label="Color Scheme" />
      </SimpleForm>
    </Edit>
  );
}

export const NotifyBar = {
  category: "manage",
  name: "manage-notifybar",
  edit: EditView,
  editId: "notifybar",
  icon: resourceIcon,
  options: { label: "Notify Bar" },
};
