import React from "react";
import { Cached, AddCircleOutline } from "@material-ui/icons";
import { TextField, Button } from "@material-ui/core";
import { authClient, baseURL } from "../../server";

const commonUrls = [
  {
    name: "Home page rows",
    urls: [`${baseURL}/homepage-rows`],
  },
  {
    name: "Slideshow banners",
    urls: [`${baseURL}/slideshow_banners/homepage`],
  },
  {
    name: "Lecture",
    urls: [`${baseURL}/lectures/{lectureId}`],
    parameter: "lectureId",
  },
];

const StaticPathField = ({ conf, onClick }) => {
  return (
    <div>
      {conf.name}
      <Button onClick={() => onClick(conf.urls)}>
        <AddCircleOutline />
      </Button>
    </div>
  );
};

const DynamicPathField = ({ conf, onClick }) => {
  const [dynamicValue, setDynamicValue] = React.useState();
  const urls = conf.urls.map(url =>
    url.replace(new RegExp(`{${conf.parameter}}`), dynamicValue),
  );

  return (
    <div>
      {conf.name}
      <TextField
        placeholder={conf.parameter}
        onChange={e => setDynamicValue(e.target.value)}
      />
      <Button onClick={() => onClick(urls)}>
        <AddCircleOutline />
      </Button>
    </div>
  );
};

const ManageCache = () => {
  const [selectedUrls, setSelectedUrls] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);

  const onAddUrls = urls => {
    setSelectedUrls([...selectedUrls, ...urls]);
  };
  return (
    <div>
      <h1>Manage Cache</h1>

      <TextField
        placeholder="https://api.torahanytime.com/homepage-rows"
        multiline
        value={selectedUrls.join("\n")}
        onChange={e => {
          setSelectedUrls(e.target.value.split("\n"));
        }}
        rows={5}
        style={{ width: "100%" }}
      />

      {commonUrls.map(conf => (
        <div key={conf.name}>
          {conf.parameter ? (
            <DynamicPathField conf={conf} onClick={onAddUrls} />
          ) : (
            <StaticPathField conf={conf} onClick={onAddUrls} />
          )}
        </div>
      ))}

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={async () => {
          setSubmitted(true);
          const res = await authClient.post(`/admin/manage-cache`, {
            urls: selectedUrls.filter(Boolean),
          });

          setSubmitted(false);

          if (res.json.errors.length > 0) {
            alert(
              "Error while requesting cache invalidation. Please see console logs for more details",
            );
            console.log("Request invalidation response", res.json);
          } else {
            alert("Requested cache invalidation. Can take up to 30 seconds.");
          }
        }}
        style={{ marginRight: 20 }}
        disabled={submitted}
      >
        Submit
      </Button>
    </div>
  );
};
export const Cache = {
  menu: {
    category: "misc",
    icon: Cached,
    name: "manage-cache",
    url: "/misc/manage-cache",
    label: "Manage Cache",
  },
  route: {
    exact: true,
    view: ManageCache,
  },
};
