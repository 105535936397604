import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Create,
  SimpleForm,
  Datagrid,
  Filter,
  List,
  Show,
  TextField,
  TextInput,
  BooleanInput,
  FunctionField,
  Mutation,
  DateField,
  DateInput,
} from "react-admin";
import {
  TextField as MaterialTextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  DialogTitle,
} from "@material-ui/core";
import { CheckCircle, Launch } from "@material-ui/icons";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
  validation,
} from "../components";
import SanitizedP from "../components/field/SanitizedP";
import { authClient } from "../server";
import { useState } from "react";

const { REACT_APP_DO_DOSE_EMAIL_ASSETS_PREFIX } = process.env;

const ellipsesOverflowStyle = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const validate = {
  required: [validation.required()],
};
const cleanDedicationName = name =>
  name
    .split("<br>")
    .map(w => w.trim())
    .join(" ");

const imgNameMessage =
  "Image name with extension goes here (e.g. test.png). The image must be on https://cloud.digitalocean.com/spaces/torahanytime-cdn?i=955929&path=images%2F. Alternatively, an icon can be specified.";
function CreateView(props) {
  return (
    <Create {...props} title="Upload Clip">
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
      </SimpleForm>
    </Create>
  );
}

const SetPaymentCompleteButton = ({ record }) => {
  const payload = { id: record.id, data: { payment_status: "complete" } };
  return (
    <Mutation
      type="UPDATE"
      resource="update-dose-donor"
      payload={payload}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "doseDonors.updated",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "doseDonors.failed",
            level: "warning",
          },
        },
      }}
    >
      {approve => (
        <Tooltip enterDelay={1} enterTouchDelay={1} title="Mark as complete">
          <CheckCircle
            variant="outlined"
            color="primary"
            size="small"
            label="Add"
            onClick={approve}
            style={{ marginLeft: 5, height: 20 }}
            cursor="pointer"
          />
        </Tooltip>
      )}
    </Mutation>
  );
};

const ApproveDedicationButton = ({ id, is_approved }) => {
  const [isApproved, setIsApproved] = useState(is_approved);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsApproved(is_approved);
  }, [is_approved]);

  async function submitApproval() {
    setIsLoading(true);
    const response = await authClient.put(
      `/admin/manage-dose-dedications/${id}`,
      {
        is_approved: !isApproved,
      },
    );

    if (response.status === 200) {
      setIsApproved(!isApproved);
    }

    setIsLoading(false);
  }
  return (
    <div>
      <Button disabled={isLoading} onClick={submitApproval} color="primary">
        {isApproved ? "Revoke" : "Approve"}
      </Button>
    </div>
  );
};

const DoubleDoseToggle = ({ id, is_double_dose }) => {
  const [isDoubleDose, setIsDoubleDose] = useState(is_double_dose);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsDoubleDose(is_double_dose);
  }, [is_double_dose]);

  const submitUpdate = async () => {
    setIsLoading(true);

    const response = await authClient.put(
      `/admin/manage-dose-dedications/${id}`,
      {
        is_double_dose: !isDoubleDose,
      },
    );

    if (response.status === 200) {
      setIsDoubleDose(!isDoubleDose);
    }

    setIsLoading(false);
  };

  return (
    <Switch
      disabled={isLoading}
      checked={isDoubleDose}
      onChange={submitUpdate}
    />
  );
};

const EditDedicationName = ({ id, dedicationName }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState(dedicationName);
  const [isLoading, setIsLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  // Without this `useEffect`, when the React Admin is reloaded (not a window reload)
  // it initially loads with stale data. This causes the recently edited value to be
  // initialized as the value. When we get the correct data, since the component
  // is not recreated, the value which is cached in the Hook is not updated
  // with the new value.
  useEffect(() => {
    setUpdatedName(dedicationName);
  }, [dedicationName]);

  const submit = async () => {
    setIsLoading(true);
    const response = await authClient.put(
      `/admin/manage-dose-dedications/${id}`,
      {
        dedication_name: updatedName,
      },
    );

    if (response.status === 200) {
      setUpdateSuccess(true);
    }

    setIsLoading(false);
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
        <DialogTitle id="alert-dialog-title">Edit dedication name</DialogTitle>
        <DialogContent>
          <MaterialTextField
            fullWidth
            value={updatedName}
            onChange={event => setUpdatedName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={submit}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <div onClick={() => setDialogOpen(true)} style={ellipsesOverflowStyle}>
        {updateSuccess ? updatedName : dedicationName}
      </div>
    </>
  );
};

function ShowView(props) {
  return (
    <Show {...props}>
      <RenderShow />
    </Show>
  );
}

function RenderShow(props) {
  const { record } = props;
  return (
    <div style={{ marginLeft: 50 }}>
      <h1>Dedication(s)</h1>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dedication Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Day</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.dose_dedications.map((dd, i) => {
            const dedicationDate = new Date(dd.dedication_day);
            const dedicationDateStr = `${dedicationDate.getUTCMonth() +
              1}/${dedicationDate.getUTCDate()}/${dedicationDate.getUTCFullYear()}`;
            return (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {cleanDedicationName(dd.dose_dedication_type.label)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {dd.dedication_name}
                </TableCell>
                <TableCell align="right">{dedicationDateStr}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <h1>Donor</h1>
      <Table aria-label="simple table">
        <TableBody>
          {Object.keys(record)
            .filter(k => k !== "right_x_phone")
            .map(
              key =>
                typeof record[key] === "string" && (
                  <TableRow key={key} style={{ height: 30 }}>
                    <TableCell
                      style={{
                        width: "15%",
                        padding: 5,
                      }}
                    >
                      {key}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 5,
                      }}
                    >
                      {key === "created_at" || key === "updated_at" ? (
                        new Date(record[key]).toLocaleString("en-US", {
                          timeZone: "America/New_York",
                        })
                      ) : key === "slide_1_attachment_link" ||
                        key === "slide_2_attachment_link" ? (
                        <a
                          href={`${REACT_APP_DO_DOSE_EMAIL_ASSETS_PREFIX}${record[key]}`}
                        >
                          {REACT_APP_DO_DOSE_EMAIL_ASSETS_PREFIX}
                          {record[key]}
                        </a>
                      ) : (
                        record[key]
                      )}
                    </TableCell>
                  </TableRow>
                ),
            )}
        </TableBody>
      </Table>
    </div>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <DateInput label="From" source="dedication_date" alwaysOn />
      <TextInput source="email" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions style={{ marginRight: 700 }} />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Dose Donors"
      bulkActionButtons={
        <DefaultBulkActionButtons style={{ marginRight: 700 }} />
      }
    >
      <Datagrid
        rowStyle={(record, index) => ({
          backgroundColor: record.dose_dedications.every(
            d => d.is_approved === true,
          )
            ? "#f0f0f0"
            : record.payment_status === "complete"
            ? "#d6ffe1"
            : record.payment_status === "initiated"
            ? "#dbf2ff"
            : record.payment_status === "pending"
            ? "lightyellow"
            : record.payment_status === "user cancelled" ||
              record.payment_status === "declined" ||
              record.payment_status === "payment error"
            ? "#ffe3e3"
            : null,
        })}
      >
        <FunctionField
          render={record => {
            const { id } = record;
            return (
              <Button
                component={Link}
                to={`/manage-dose-donors/${id}/show`}
                color="primary"
              >
                View
              </Button>
            );
          }}
        />
        <TextField source="id" />
        <FunctionField
          label="Total Amount"
          render={record => parseFloat(record.total_amount || 0).toFixed()}
        />
        <FunctionField
          label="Additional Donation Amount"
          render={record => parseFloat(record.additional_amount || 0).toFixed()}
        />
        <TextField source="currency_id" label="Currency" />
        <TextField source="payment_type" label="Payment Type" />
        <FunctionField
          label="Payment Status"
          render={record => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{record.payment_status}</span>
              {record.payment_status !== "complete" && (
                <SetPaymentCompleteButton record={record} />
              )}
            </div>
          )}
        />
        <FunctionField
          style={{ width: 565 }}
          label="Dedication(s)"
          render={record => {
            return (
              <table>
                <tbody>
                  {record.dose_dedications.map((d, i) => {
                    const dedicationDate = new Date(d.dedication_day);

                    return (
                      <tr key={i}>
                        <td
                          style={{
                            width: 150,
                            maxWidth: 150,
                          }}
                        >
                          <Tooltip
                            enterDelay={1}
                            enterTouchDelay={1}
                            title={cleanDedicationName(
                              d.dose_dedication_type.label,
                            )}
                          >
                            <div style={ellipsesOverflowStyle}>
                              {cleanDedicationName(
                                d.dose_dedication_type.label,
                              )}
                            </div>
                          </Tooltip>
                        </td>
                        <td style={{ width: 200, maxWidth: 200 }}>
                          <Tooltip
                            enterDelay={1}
                            enterTouchDelay={1}
                            title={d.dedication_name}
                          >
                            <EditDedicationName
                              id={d.id}
                              dedicationName={d.dedication_name}
                            />
                          </Tooltip>
                        </td>
                        <td style={{ width: 45, textAlign: "end" }}>
                          {`${dedicationDate.getUTCMonth() +
                            1}/${dedicationDate.getUTCDate()}`}
                        </td>
                        {record.payment_status === "complete" && (
                          <>
                            <td>
                              <ApproveDedicationButton
                                id={d.id}
                                is_approved={d.is_approved}
                              />
                            </td>
                            <td>
                              <DoubleDoseToggle
                                id={d.id}
                                is_double_dose={d.is_double_dose}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }}
        />

        <TextField source="phone" label="Phone" />
        <TextField source="city" label="city" />
        <TextField source="state" label="state" />
        <TextField source="country" label="Country" />
        <FunctionField
          label="Lucky Orange"
          render={record =>
            record.ip && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.luckyorange.com/v3/dash.php?rwt=&f=${record.ip}#/recordings`}
              >
                <Launch />
              </a>
            )
          }
        />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
      </Datagrid>
    </List>
  );
}

export const DoseDonors = {
  category: "dose",
  name: "manage-dose-donors",
  list: ListView,
  show: ShowView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Dose Donors" },
  roles: ["dose_donor_admin"],
  permissions: {
    dose_donor_admin: ["create", "list", "view"],
  },
};
