import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/School";

export const ManageBannersTodaysLearningPage = makeSortableFormPage({
  createItem() {
    return {
      text: "",
    };
  },
  fetch() {
    return authClient.get("/admin/manage-banners/todays-learning");
  },
  getInitialState() {
    return {
      interval: 0,
      items: [],
    };
  },
  load(res) {
    const data = res.json;
    return {
      interval: data.interval / 1000,
      items: data.msgs.map(msg => ({ text: msg || "" })),
      changed: false,
    };
  },
  menu: {
    category: "banners",
    icon,
    name: "todays-learning",
    label: "Today's Learning",
    url: "/manage-banners/todays-learning",
  },
  renderFields({ actions, /* css, */ state }) {
    const { onChangeField } = actions;
    return (
      <>
        <TextField
          style={{ marginLeft: 125, width: 280 }}
          type="number"
          label="Seconds to display each message"
          value={state.interval}
          onChange={onChangeField(
            "interval",
            e => e.currentTarget.valueAsNumber || 0,
          )}
          InputLabelProps={{ shrink: !!state.interval }}
        />
      </>
    );
  },
  renderItem({ actions, css, item, rowIndex }) {
    const { onChangeItem } = actions;
    return (
      <TextField
        label=""
        placeholder="Message"
        value={item.text}
        onChange={onChangeItem(rowIndex, "text")}
        className={css.rowField}
      />
    );
  },
  save(state) {
    const data = {
      interval: state.interval * 1000,
      msgs: state.items
        .map(i => i.text)
        .filter(item => ("" + item).trim().length > 0),
    };
    return authClient.put("/admin/manage-banners/todays-learning", data);
  },
  title: "Today's Learning",
});
