/**
 * @file Originally from https://github.com/vascofg/react-admin-color-input
 * - Imported as our own file instead of adding as a package since the package
 * wasn't up to date (last published a year ago). Cleaned up and formatted.
 * - Styles were copied into `src/assets/styles.css`.
 * - Switched from dynamic `picker` prop to `PhotoshopPicker`. See changes in
 * the commit that contains this comment to switch it back.
 */
import React from "react";
import PropTypes from "prop-types";
import { addField, FieldTitle } from "ra-core";
import TextField from "@material-ui/core/TextField";
import { PhotoshopPicker } from "react-color";
import get from "lodash/get";
import pure from "recompose/pure";

const ColorFieldComponent = ({ source, record = {}, className }) => (
  <div style={{ display: "flex" }}>
    <div
      style={{
        width: "20px",
        height: "20px",
        background: get(record, source),
        marginRight: "5px",
      }}
    />
    <span className={className}>{get(record, source)}</span>
  </div>
);

ColorFieldComponent.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const PureTextField = pure(ColorFieldComponent);

PureTextField.defaultProps = {
  addLabel: true,
};

class ColorInputComponent extends React.Component {
  state = {
    color: this.props.input ? this.props.input.value : "",
    show: false,
  };

  handleOpen = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleChange = ({ hex }) => {
    this.setState({ color: hex });
  };
  acceptChange = () => {
    this.props.input.onChange(this.state.color);
    this.setState({ show: false });
  };
  cancelChange = () => {
    this.setState({
      color: this.props.input ? this.props.input.value : "",
      show: false,
    });
  };

  render() {
    const {
      label,
      source,
      meta,
      className,
      options,
      input,
      resource,
      isRequired,
      preview,
    } = this.props;

    const { touched, error } = meta;

    return (
      <div>
        <TextField
          {...input}
          margin="normal"
          onFocus={this.handleOpen}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
        />
        {preview && (
          <div
            style={{
              cursor: "pointer",
              border: "1px solid black",
              backgroundColor: input.value,
              position: "relative",
              left: 225,
              top: -40,
              width: 20,
              height: 20,
            }}
            onClick={this.handleOpen}
          >
            {" "}
          </div>
        )}
        {this.state.show ? (
          <div className="ColorInput-popup">
            <PhotoshopPicker
              {...options}
              color={this.state.color}
              onChangeComplete={this.handleChange}
              onAccept={this.acceptChange}
              onCancel={this.cancelChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  preview: PropTypes.bool,
};

ColorInputComponent.defaultProps = {
  options: {
    disableAlpha: true,
  },
  preview: true,
};

export const ColorField = PureTextField;
export const ColorInput = addField(ColorInputComponent);
