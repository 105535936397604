import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  UrlField,
  List,
  NumberField,
  SimpleForm,
  TextField,
  BooleanInput,
  TextInput,
  LongTextInput,
  ImageInput,
  required,
  regex,
} from "react-admin";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import { EditorToolbar, BoolNumField, WideCard } from "../components";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="link" />
        <ImageField source="img" sortable={false} />
        <BoolNumField source="display_on_player" label="Show on player" />
        <BoolNumField
          source="display_on_schedule"
          label="Show on schedule list"
        />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <BooleanInput
        source="display_on_player"
        label="Show on player (ads will rotate every 2 minutes and show for 15 seconds)"
      />
      <BooleanInput
        source="display_on_schedule"
        label="Show on schedule list"
      />
      <TextInput source="link" />
      <br />
      <br />
      <h4>Image</h4>
      <p style={{ width: 300 }}>
        Image aspect ratio 10:1 (ideal size 1400x140)
      </p>
      <LongTextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const TbavAds = {
  category: "tbav",
  name: "tisha_bav_ad",
  create: CreateView,
  edit: EditView,
  icon: StarBorderIcon,
  list: ListView,
  options: { label: "Ads" },
};
