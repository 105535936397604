import React, { useState, useEffect } from "react";

import {
  FileInput,
  UrlField,
  FileField,
  Edit,
  TextInput,
  NumberInput,
  BooleanInput,
  Create,
  DateInput,
  TabbedForm,
  FormTab,
  FunctionField,
  ImageField,
} from "react-admin";

import { WhatsAppTemplate } from "./WhatsAppTemplate";
import { InstagramTemplate } from "./InstagramTemplate";
import { EmailTab } from "./EmailTab";
import { SchedulesTab } from "./ScheduleTab";

import { baseURL } from "../../../server";

export function CreateDoseView(props) {
  const validate = function (values) {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    if (!values.dose_number) errors.dose_number = "Dose number  is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    if (!values.released_at)
      errors.released_at = "Released at date is required.";
    if (!values.FILE_audio) errors.FILE_audio = "Audio  is required.";
    if (!values.FILE_video) errors.FILE_video = "Video  is required.";

    return errors;
  };

  return (
    <Create {...props}>
      <TabbedForm {...props} validate={values => validate(values)}>
        <FormTab label="Create" {...props}>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" />
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <FileInput source="FILE_audio" accept="audio/*" label="Audio file">
            <FileField source="src" title="title" />
          </FileInput>
          <FileInput source="FILE_video" accept="video/*" label="Video file">
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

export function EditDoseView(props) {
  const {
    location: { pathname = "" },
    id,
  } = props;

  const [onEditTab, setOnEditTab] = useState(false);

  useEffect(() => {
    console.log({ pathname, id });
    const tabIndex = pathname.split("/daily_dose/" + id).pop();
    if (tabIndex === "") {
      setOnEditTab(true);
    } else {
      setOnEditTab(false);
    }
    console.log(onEditTab);
  }, [id, pathname]);

  const validate = function (values) {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    if (!values.dose_number) errors.dose_number = "Dose number  is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    if (!values.released_at)
      errors.released_at = "Released at date is required.";

    return errors;
  };

  return (
    <Edit {...props}>
      <TabbedForm
        {...props}
        validate={values => validate(values)}
        toolbar={onEditTab ? undefined : <div></div>}
      >
        <FormTab label="Edit" {...props} replace>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" />
          <FunctionField
            label="Speaker"
            render={record => (
              <a href={`/speakers/${record.speaker_id}`} target="_blank">
                {`${record.speaker_title_short} ${record.speaker_name_first} ${record.speaker_name_last}`}
              </a>
            )}
          />
          <UrlField source="lecture_url" target="_blank"></UrlField>
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <UrlField source="audioUrl" target="_blank"></UrlField>
          <FileInput source="FILE_audio" accept="audio/*" label="Audio file">
            <FileField source="src" title="title" />
          </FileInput>
          <UrlField source="videoUrl" target="_blank"></UrlField>
          <FileInput source="FILE_video" accept="video/*" label="Video file">
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="Photos">
          <ImageField
            source="speaker_dose_app_photo_url"
            label="Dose app photo"
          ></ImageField>
          <ImageField
            source="speaker_dose_email_photo_url"
            label="Dose email photo"
          ></ImageField>
          <ImageField
            source="speaker_double_dose_email_photo_url"
            label="Double Dose email photo"
          ></ImageField>
          <FunctionField
            label="Instagram photo"
            render={record => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${record.dose_number
                  }-instagram&url=${record.speaker_dose_instagram_photo_url
                  }&text=Dose %23${record.dose_number.toLocaleString(
                    "en",
                  )}&textX=617&textY=18&textFont=montserrat-regular-80-white&textMaxWidth=100`}
                alt=""
              />
            )}
          />
          <FunctionField
            label="Podcast photo"
            render={record => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${record.dose_number
                  }-podcast&url=${record.speaker_dose_podcast_photo_url
                  }&text=Dose %23${record.dose_number.toLocaleString(
                    "en",
                  )}&textX=497&textY=10&textFont=in901xl-63-blue&textMaxWidth=100`}
                alt=""
              />
            )}
          />
        </FormTab>

        <FormTab label="WhatsApp" replace fullWidth>
          <FunctionField
            label="WhatsApp text"
            render={record => <WhatsAppTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Instagram" replace fullWidth>
          <FunctionField
            label="Instagram text"
            render={record => <InstagramTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Schedules">
          <FunctionField
            label="Schedules"
            render={record => <SchedulesTab record={record} />}
          />
        </FormTab>
        <FormTab label="Email">
          <FunctionField
            label="Email"
            render={record => <EmailTab record={record} />}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
