import React from "react";
import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
} from "react-admin";
import { filterByName } from "../utils";

export class SubCategoryInput extends React.PureComponent {
  render() {
    const {
      // Primary props
      allowEmpty = true,
      filterToQuery = filterByName,
      label = "Sub-Category",
      parentSource = "category",
      perPage = 100,
      reference = "categories",
      sort = { field: "name", order: "ASC" },
      source = "subcategory",
      // Other props
      ...rest
    } = this.props;
    function renderSubCategoryInput(props) {
      const {
        // Primary props
        allowEmpty: _allowEmpty,
        filterToQuery: _filterToQuery,
        label: _label,
        parentSource: _parentSource,
        perPage: _perPage,
        reference: _reference,
        sort: _sort,
        source: _source,
        // Dependent props
        formData: { [parentSource]: parent } = {},
        filter = { parent },
        // Other props
        ...rest
      } = props;
      return (
        <ReferenceInput
          {...rest}
          // NOTE: Force a re-render when parent changes by setting `key`, so
          // that the correct set of sub-categories gets displayed on click.
          key={`${source}-subcategory-of-${parent}`}
          label={label}
          source={source}
          reference={reference}
          filter={filter}
          filterToQuery={filterToQuery}
          perPage={perPage}
          sort={sort}
          allowEmpty={allowEmpty}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      );
    }
    return (
      <FormDataConsumer {...rest}>{renderSubCategoryInput}</FormDataConsumer>
    );
  }
}
