import React from "react";
import ReactDOM from "react-dom";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import App from "./App";
import "./assets/css/styles.scss";

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById("jss-insertion-point"),
});

// There is probably a better way to override the default strings, but this
// is pretty efficient:
// import reactAdminEnglish from 'ra-language-english';
// reactAdminEnglish.ra.page.dashboard = 'Summary';

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <App />
  </JssProvider>,
  document.getElementById("root"),
);
