import React from "react";
import {
  EditController,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import {
  BoolNumInput,
  commentChildrenURL,
  commentParentURL,
  createEditLectureURL,
  createEditDoseURL,
  createEditUserURL,
  createLectureURL,
  createDoseURL,
  createDoseUserCommentsURL,
  createMailToURL,
  DateTimeField,
  EditorToolbar,
  FieldRow,
  LinkField,
  UrlTextField,
} from "../../components";

const styles = {
  root: {
    paddingTop: 40,
    width: 500,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  inlineFieldRight: {
    display: "inline-block",
    width: "50%",
    textAlign: "right",
  },
};

export default function UserCommentEdit({ onCancel, ...props }) {
    return (
    <EditController {...props} undoable={false}>
      {controllerProps => {
        if (!controllerProps.record) {
          return null;
        }
        const { parent = 0, child_count = 0, lecture_id } = controllerProps.record;
        const hasParent = parent !== 0 && parent !== "0";
        const isLecture = !!lecture_id;
        return (
          <div style={styles.root}>
            <div style={styles.title}>
              <Typography variant="title">
                <span>Comment</span>
              </Typography>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </div>
            <SimpleShowLayout
              basePath={controllerProps.basePath}
              record={controllerProps.record}
              version={controllerProps.version}
              resource="manage-user-comments"
            >
              <FieldRow>
                <InputLabel
                  shrink={true}
                  style={{
                    display: "block",
                    marginBottom: 8,
                    marginRight: -140,
                  }}
                >
                  Id
                  <span style={{ float: "right" }}>Created</span>
                </InputLabel>
                <TextField
                  record={controllerProps.record}
                  source="id"
                  label="Id"
                  style={{
                    ...styles.inlineField,
                    whiteSpace: "nowrap",
                    overflowX: "ellipsis",
                  }}
                />
                <DateTimeField
                  record={controllerProps.record}
                  source="created_at"
                  label="Created"
                  style={styles.inlineFieldRight}
                />
              </FieldRow>
              <FieldRow style={{ paddingBottom: 10 }}>
                <InputLabel
                  shrink={true}
                  style={{ display: "block", marginBottom: 8 }}
                >
                  {isLecture ? "Lecture" : "Dose"}
                </InputLabel>
                  { isLecture ? (
                    <LinkField
                      record={controllerProps.record}
                      source="lecture_id"
                      sourceText="lecture_id"
                      formatURL={createEditLectureURL}
                      style={styles.inlineField}
                      title="Edit Lecture"
                    />
                  ) : (
                    <LinkField
                    record={controllerProps.record}
                    source="dose_id"
                    formatURL={createEditDoseURL}
                    style={styles.inlineField}
                    title="Edit Dose">{"# " + controllerProps.record.daily_dose?.dose_number}</LinkField>
                  )}
                  {isLecture || (
                    <LinkField
                    record={controllerProps.record}
                    source="daily_dose.dose_number"
                    formatURL={createDoseUserCommentsURL}
                    style={styles.inlineFieldRight}
                    title="View All">{"View All: " + controllerProps.record.daily_dose?.dose_number}</LinkField>
                  )}

                <TextField
                  record={controllerProps.record}
                  source="speaker_name"
                  style={styles.inlineFieldRight}
                />
              </FieldRow>
              { isLecture ? (
              <UrlTextField
                source="lecture_id"
                sourceText="lecture_title"
                formatURL={createLectureURL}
                target="_blank"
                rel="noopener noreferrer"
                addLabel={false}
              />
                ) : (
                  <UrlTextField
                    source="dose_id"
                    sourceText="daily_dose.title"
                    formatURL={createDoseURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    addLabel={false}
                />
                )}
                  <FieldRow>
                <InputLabel
                  shrink={true}
                  style={{ display: "block", marginBottom: 8 }}
                >
                  User
                </InputLabel>
                <LinkField
                  record={controllerProps.record}
                  source="author"
                  sourceText="author_name"
                  formatURL={createEditUserURL}
                  addLabel={false}
                  style={styles.inlineField}
                  title="Edit user"
                />
                <UrlTextField
                  record={controllerProps.record}
                  source="user_email"
                  sourceText="user_email"
                  formatURL={createMailToURL}
                  addLabel={false}
                  style={styles.inlineFieldRight}
                  title="Email user"
                />
              </FieldRow>
              <FieldRow>
                <TextField
                  record={controllerProps.record}
                  source="author"
                  label="User Id"
                  title="User Id"
                  style={styles.inlineField}
                />
                <TextField
                  record={controllerProps.record}
                  source="ip"
                  label="IP Address"
                  title="IP Address"
                  style={styles.inlineFieldRight}
                />
              </FieldRow>
              <TextField
                source="content"
                label="Comment"
                style={{
                  maxHeight: 300,
                  overflowY: "auto",
                }}
              />
              <hr />
              <FieldRow>
                {hasParent ? (
                  <LinkField
                    record={controllerProps.record}
                    source="parent"
                    formatURL={commentParentURL}
                    style={styles.inlineField}
                    title="View the parent comment"
                  >
                    Parent comment
                  </LinkField>
                ) : (
                  <Typography
                    component="span"
                    variant="body1"
                    style={styles.inlineField}
                  >
                    No parent comment
                  </Typography>
                )}
                {child_count > 0 ? (
                  <LinkField
                    record={controllerProps.record}
                    source="id"
                    formatURL={commentChildrenURL}
                    style={styles.inlineFieldRight}
                    title="Find replies to this comment"
                  >
                    Replies ({child_count})
                  </LinkField>
                ) : (
                  <Typography
                    component="span"
                    variant="body1"
                    style={styles.inlineFieldRight}
                  >
                    No replies
                  </Typography>
                )}
              </FieldRow>
            </SimpleShowLayout>
            <SimpleForm
              basePath={controllerProps.basePath}
              record={{
                approved: controllerProps.record.approved,
                featured: controllerProps.record.featured,
                id: controllerProps.record.id,
              }}
              save={controllerProps.save}
              version={controllerProps.version}
              redirect="list"
              resource="manage-user-comments"
              toolbar={<EditorToolbar />}
            >
              <div style={{display: "flex", justifyContent: 'space-between', width: "100%"}}>
                <BoolNumInput source="approved" label="Approved" />
                { isLecture || (
                    <BoolNumInput source="featured" label="Featured" />
                )}
              </div>
            </SimpleForm>
          </div>
        );
      }}
    </EditController>
  );
}
