import React, { Children, Fragment } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import MuiToolbar from "@material-ui/core/Toolbar";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { SaveButton, DeleteButton, showNotification } from "react-admin";
import { connect } from "react-redux";

const styles = theme => ({
  toolbar: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing.unit * 2,
  },
  mobileToolbar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  spacer: {
    [theme.breakpoints.down("xs")]: {
      height: "5em",
    },
  },
});

const valueOrDefault = (value, defaultValue) =>
  typeof value === "undefined" ? defaultValue : value;

class EditorToolbarView extends React.PureComponent {
  handleSubmitWithRedirect = redirect => {
    const {
      handleSubmitWithRedirect,
      pristine,
      savePristine = false,
    } = this.props;
    if (pristine && !savePristine) {
      return () => undefined;
    }
    return handleSubmitWithRedirect(redirect);
  };

  componentDidMount() {
    const { registerTrigger } = this.props;
    if (registerTrigger) {
      registerTrigger(this);
    }
  }

  componentWillUnmount() {
    const { registerTrigger } = this.props;
    if (registerTrigger) {
      registerTrigger(null);
    }
  }

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      handleSubmit,
      handleSubmitWithRedirect: _handleSubmitWithRedirect,
      invalid,
      pristine,
      record,
      redirect,
      registerTrigger: _registerTrigger,
      resource,
      saving,
      showDelete = false,
      showNotification: _showNotification,
      showSave = true,
      submitOnEnter,
      undoable: _undoable,
      width,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <MuiToolbar
          className={classnames(
            classes.toolbar,
            {
              [classes.mobileToolbar]: width === "xs",
              [classes.desktopToolbar]: width !== "xs",
            },
            className,
          )}
          role="toolbar"
          {...rest}
        >
          <div className={classes.defaultToolbar}>
            {showSave && (
              <SaveButton
                handleSubmitWithRedirect={this.handleSubmitWithRedirect}
                invalid={invalid}
                redirect={redirect}
                saving={saving}
                submitOnEnter={submitOnEnter}
              />
            )}
            {Children.map(children, button =>
              button
                ? button.props.toolbarProps
                  ? React.cloneElement(button, {
                      basePath,
                      handleSubmit: valueOrDefault(
                        button.props.handleSubmit,
                        handleSubmit,
                      ),
                      handleSubmitWithRedirect: valueOrDefault(
                        button.props.handleSubmitWithRedirect,
                        this.handleSubmitWithRedirect,
                      ),
                      invalid,
                      pristine,
                      record,
                      saving,
                      submitOnEnter: valueOrDefault(
                        button.props.submitOnEnter,
                        submitOnEnter,
                      ),
                    })
                  : button
                : null,
            )}
            {showDelete && record && typeof record.id !== "undefined" && (
              <DeleteButton
                basePath={basePath}
                record={record}
                resource={resource}
                undoable={false}
              />
            )}
          </div>
        </MuiToolbar>
        <div className={classes.spacer} />
      </Fragment>
    );
  }
}

EditorToolbarView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSave: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  registerTrigger: PropTypes.func,
  resource: PropTypes.string,
  savePristine: PropTypes.bool,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  width: PropTypes.string,
};

EditorToolbarView.defaultProps = {
  submitOnEnter: true,
};

const enhance = compose(
  connect(
    undefined,
    { showNotification },
  ),
  withWidth(),
  withStyles(styles),
);
export const EditorToolbar = enhance(EditorToolbarView);
