import { useState, useEffect } from "react";

import { authClient } from "../../../server";

/**
 * @param {string} doseDate - Date if the Dose
 * @param {boolean} doubleDose - If the Dose is a Double Dose
 */
export function useDedicationsText(doseDate, doubleDose = false) {
    const [dedications, setDedications] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        (async function () {
            const response = await authClient.get(
                `/dose-dedication/whatsapp?dedication_date=${doseDate}&double_dose=${doubleDose}`
            )
            setDedications(response.json.text)
            setIsLoading(false)
        })()
    }, doseDate)

    return { dedications, isLoading }
}