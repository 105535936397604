import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  UrlField,
  List,
  NumberField,
  ReferenceManyField,
  Count,
  SimpleForm,
  TextField,
  BooleanInput,
  TextInput,
  LongTextInput,
  ImageInput,
  NumberInput,
  required,
  regex,
} from "react-admin";

import resourceIcon from "@material-ui/icons/LiveTv";
import {
  EditorToolbar,
  BoolNumField,
  WideCard,
  DateTimeField,
} from "../components";
import TotalField from "../components/field/TotalField";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <ImageField source="img" sortable={false} />
        <TextField source="name" />
        <ReferenceManyField
          label="Number of Classes"
          reference="tisha_bav_classes"
          target="tisha_bav_program"
          source="id"
          className="program_table"
        >
          <TotalField />
        </ReferenceManyField>
        <TextField source="phone_extension" />
        <BoolNumField source="display_active" />
      </Datagrid>
    </List>
  );
}
function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" defaultValue={0} />
      <NumberInput
        source="order"
        label="Order (optional)"
        defaultValue={0}
        helperText={
          "Default order by next class, Live classes are -1 (Lower numbers show first)"
        }
      />
      <TextInput source="name" validate={required()} />
      <TextInput
        source="slug"
        label="slug (name for URL)"
        validate={required()}
      />
      <TextInput source="phone_extension" />
      <TextInput source="iframe_url" validate={required()} />
      <br />
      <br />
      <h4>Image</h4>
      <p>Image aspect ratio 1:1 (square)</p>
      <LongTextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const Programs = {
  category: "tbav",
  name: "tisha_bav_programs",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Tisha Bav Programs" },
};
