import React, { Component, useEffect, useState } from "react";
import {
  Datagrid,
  Edit,
  SaveButton,
  Toolbar,
  List,
  NumberField,
  BooleanField,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  BooleanInput,
  DisabledInput,
  required,
  minValue,
  maxValue,
  Filter,
  RadioButtonGroupInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

import { Link } from "react-router-dom";

import resourceIcon from "@material-ui/icons/Home";
import Button from "@material-ui/core/Button";
import { authClient, baseURL } from "../server";
import { ReferenceField } from "ra-ui-materialui/lib/field";
import { CategoryInput, SubCategoryInput, validation } from "../components";

class ReorderLink extends React.PureComponent {
  render() {
    return (
      <Link to="/homepage-rows/reorder" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary" size="small">
          Reorder rows
        </Button>
      </Link>
    );
  }
}

const ListPaginationToolbar = () => {
  return (
    <Toolbar
      style={{
        backgroundColor: "white",
        marginTop: 0,
        justifyContent: "center",
      }}
    >
      <ReorderLink />
    </Toolbar>
  );
};

const customRowStyle = (record, index) => ({
  height: 42,
  opacity: record.show ? 1 : 0.3,
  boxShadow: record.is_custom ? "inset 0 0 9px 1px #7084b3" : "none",
});

const ConditionalTextField = ({ record, ...rest }) =>
  record && record.is_custom ? <TextField record={record} {...rest} /> : null;

const FiltersView = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Project"
        source="project_id"
        reference="projects"
        alwaysOn
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
    </Filter>
  );
};

function ListView(props) {
  return (
    <List
      {...props}
      sort={{ field: "row_order", order: "ASC" }}
      filters={<FiltersView />}
      perPage={100}
      pagination={<ListPaginationToolbar />}
      bulkActionButtons={false}
      title="Homepage Rows"
    >
      <Datagrid rowClick="edit" rowStyle={customRowStyle}>
        <ReferenceField
          label="Project"
          source={"project_id"}
          reference="projects"
          sortable={false}
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="row_order" sortable={false} />
        <TextField source="title" sortable={false} />
        <BooleanField source="show" sortable={false} />
        <NumberField source="limit" label="Items to show" />
        <BooleanField
          source="is_custom"
          label="Customizable?"
          sortable={false}
        />
        <ReferenceField
          label="Topic"
          source="topic_id"
          reference="categories"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ConditionalTextField
          source="topic_id"
          label="Topic ID"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}

const EditViewToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ConditionalAutocompleteInput = ({ record, ...rest }) =>
  record && record.is_custom ? (
    <>
      <CategoryInput
        validate={[validation.required()]}
        source="topic_id"
        record={record}
        {...rest}
      />
      <SubCategoryInput
        parentSource="topic_id"
        source="subtopic_id"
        record={record}
        {...rest}
      />
    </>
  ) : null;

const editForm = props => {
  const { redirect } = props;

  return (
    <SimpleForm redirect={redirect} toolbar={<EditViewToolbar />}>
      <BooleanInput source="show" />
      <DisabledInput
        source="row_order"
        validate={[required(), minValue(1), maxValue(16)]}
      />
      <NumberInput source="limit" label="Amount of items to show" />
      <DisabledInput source="name" label="Internal Name" />
      <TextInput source="title" validate={required()} />
      <ConditionalAutocompleteInput />
    </SimpleForm>
  );
};

const EditTitle = ({ record }) => <span>{`Homepage Row #${record.id}`}</span>;

class EditView extends Component {
  state = { categories: [] };

  topicId = null;
  setTopicId = record => {
    if (!this.topicId && record && record.topic_id)
      this.topicId = record.topic_id;
  };

  getCategories = async () => {
    await authClient
      .get("/topics/flat?min_lectures=6")
      .then(res => {
        this.setState({ categories: res.json });
      })
      .catch(er => console.log(er));
  };

  componentDidMount() {
    this.getCategories();
  }

  render() {
    return (
      <Edit
        {...this.props}
        title={<EditTitle />}
        categories={this.categories}
        undoable={false}
      >
        {editForm({
          ...this.props,
          categories: this.state.categories,
          setTopicId: this.setTopicId,
          redirect: "list",
        })}
      </Edit>
    );
  }
}

export const Homepage = {
  category: "tanext",
  name: "homepage-rows",
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Homepage" },
};
