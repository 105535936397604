import React from "react";
import { connectCommon } from "../components";

const headerStyle = {
  fontSize: 23,
  fontWeight: 400,
};

class NoLayoutDemo extends React.PureComponent {
  render() {
    return (
      <div>
        <h2 style={headerStyle}>No Layout Demo Page</h2>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
        <p>HELLO</p>
      </div>
    );
  }
}
export const NoLayoutDemoPage = {
  route: {
    exact: true,
    path: "/test/nolayout",
    view: connectCommon(NoLayoutDemo),
    noLayout: true,
  },
};
