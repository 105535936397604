import React from "react";
import { Datagrid, Filter, List, TextField, TextInput, RadioButtonGroupInput } from "react-admin";
import { connect } from "react-redux";
import { Route } from "react-router";
import { push } from "react-router-redux";
import Drawer from "@material-ui/core/Drawer";
import {
  BoolNumField,
  DateTimeField,
  ListActions,
  TextFieldEx,
} from "../../components";
import UserCommentEdit from "./UserCommentEdit";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="parent" alwaysOn />
      <TextInput source="id" alwaysOn />
      <TextInput label="Lecture ID" source="lecture_id" alwaysOn />
      <TextInput label="Dose Number" source="dose_number" alwaysOn />
      <TextInput label="User Name" source="author_name" alwaysOn />
      <RadioButtonGroupInput
        alwaysOn
        label="Comment Type"
        source="comment_type"
        initialValue="all"
        options={{
          row: true,
        }}
        choices={[
          { id: "all", name: "All" },
          { id: "lecture", name: "Lecture" },
          { id: "dose", name: "Dose" },
        ]}
      />
    </Filter>
  );
}

class UserCommentsList extends React.Component {
  handleClose = () => {
    this.props.push("/manage-user-comments");
  };
  render() {
    const props = this.props;
    return (
      <>
        <List
          {...props}
          actions={<ListActions />}
          exporter={true}
          filters={<FiltersView />}
          sort={{ field: "created_at", order: "DESC" }}
          title="Comments"
          bulkActionButtons={false}
          filterDefaultValues={{
            comment_type: "all",
          }}
        >
          <Datagrid rowClick="edit">
            <TextField
              source="author_name"
              label="User"
              style={{ minWidth: 120 }}
            />
            <DateTimeField
              source="created_at"
              label="Created"
              style={{ minWidth: 140 }}
            />
            <TextField
              source="user_email"
              label="Email"
              style={{ minWidth: 120 }}
            />
            <BoolNumField source="approved" label="Approved" />
            <TextFieldEx max={50} source="content" label="Comment" />
            <TextField source="lecture_id" label="Lecture" />
            <TextField source="daily_dose.dose_number" label="Dose" />
            <BoolNumField source="featured" label="Featured" />
          </Datagrid>
        </List>
        <Route path="/manage-user-comments/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";
            return (
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={this.handleClose}
                // CONSIDER: Let them see the list pager while editing:
                // PaperProps={{ style: { height: "90%" } }}
              >
                {isMatch ? ( // Conditional render to avoid route error.
                  <UserCommentEdit
                    id={match.params.id}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            );
          }}
        </Route>
      </>
    );
  }
}

export default connect(
  undefined,
  { push },
)(UserCommentsList);
