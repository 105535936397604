import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  DisabledInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Label";
import {
  BoolNumField,
  BoolNumInput,
  EditorToolbar,
  ListActions,
  validation,
} from "../components";

const validate = {
  name: [validation.required(), validation.minLength(2)],
  //description: [validation.required(), validation.number()],
};

function FiltersView(props) {
  return (
    <Filter {...props}>
    </Filter>
  );
}

function renderEditForm({ redirect }) {
  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
    >
      <TextInput source="name" label="Name" validate={validate.name} />
      <TextInput source="description" label="Description" validate={validate.name} />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}
// SanitizedP is how we resolve the below issue for <p></p> DOM element
// Warning: React does not recognize the `basePath` prop on a DOM element.  If you
// accidentally passed it from a parent component, remove it from the DOM
// element.
const SanitizedP = ({ basePath, ...props }) => {
  return <p {...props} />;
};
function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      title="Projects"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
      </Datagrid>
    </List>
  );
}

export const Projects = {
  category: "database",
  name: "projects",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Projects" },
};
