import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { filterByName } from "../utils";

export class HostInput extends React.PureComponent {
  render() {
    const {
      allowEmpty = true,
      label = "Host",
      source = "host",
      reference = "organizations",
      filterToQuery = filterByName,
      perPage = 100,
      sort = { field: "name", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        allowEmpty={allowEmpty}
        label={label}
        source={source}
        reference={reference}
        filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    );
  }
}
