import React from "react";
import { Layout, Sidebar } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "./AppBar";
import Menu from "./Menu";

const styles = theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: 10,
    padding: theme.spacing.unit * 3,
    paddingTop: 60,
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 5,
    },
    // NOTE: Removed this rule as it conflicts with a fixed app bar.
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0,
    // },
  },
});

const AppSidebar = props => <Sidebar {...props} size={260} />;
export const AppLayout = withStyles(styles)(props => (
  <Layout {...props} appBar={AppBar} menu={Menu} sidebar={AppSidebar} />
));
