import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Datagrid,
  DateInput,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
  LongTextInput,
  NumberInput,
  DateField,
  FunctionField,
  BooleanInput,
  BooleanField,
  SimpleForm,
  SelectArrayInput,
  Mutation,
  RadioButtonGroupInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import CloseIcon from "@material-ui/icons/Close";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  getDurationStr,
  formatDateTimeInput,
  parseDateTimeIntUTC,
  BoolNumInput,
} from "../components";
import {
  Button,
  withStyles,
  TextField as CoreTextField,
} from "@material-ui/core";
import UpdateTypeButton from "./clipnshare/UpdateTypeButton";
import CopySnippet from "./clipnshare/CopySnippet";
import { Launch } from "@material-ui/icons";

function EditView(props) {
  return (
    <Edit {...props}>
      <SimpleForm redirect={false} toolbar={<EditorToolbar showDelete />}>
        <TextField source="id" />
        <TextField source="user_id" label="User ID" />
        <FunctionField
          label="Duration"
          render={record => getDurationStr(record.duration)}
        />
        <FunctionField
          style={{ width: "100%" }}
          label="Link"
          render={record =>
            record.link && (
              <a target="_blank" href={record.link} rel="noopener noreferrer">
                {record.link}
              </a>
            )
          }
        />
        <FunctionField
          label="Speaker"
          render={r => (
            <a
              target="_blank"
              href={`https://www.torahanytime.com/#/speaker?l=${r.lecture_view?.speaker}`}
              rel="noopener noreferrer"
            >
              {`${r.lecture_view?.speaker_short_title} ${r.lecture_view?.speaker_first_name} ${r.lecture_view?.speaker_last_name}`}
            </a>
          )}
        />
        <TextField source="status" label="Status" />
        <TextField label="Clipper Email" source="clipper_email" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <DateField
          source="completed"
          label="Completed At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <FunctionField
          style={{ width: "100%" }}
          label="Lecture ID"
          render={record =>
            record.link && (
              <a
                target="_blank"
                href={`https://www.torahanytime.com/#/lectures?v=${record.videoID}`}
                rel="noopener noreferrer"
              >
                {record.videoID}
              </a>
            )
          }
        />
        <RadioButtonGroupInput
          style={{ width: 200 }}
          label="Clip Type"
          source="type"
          choices={[
            { id: "dose", name: "Dose" },
            { id: "tat_clip", name: "TAT Clip" },
            { id: "plug", name: "Plug" },
            { id: "pending", name: "Pending" },
            { id: "standard", name: "Standard" },
          ]}
          alwaysOn
        />
        <TextField
          source="filename"
          label="filename"
          style={{ width: "100%" }}
        />
        <NumberInput source="dose_id" label="Dose ID" />
        <TextInput source="title" label="Title" />
        <LongTextInput source="ending" label="Ending" />
        <LongTextInput source="notes" label="Notes" multiline />
        <FunctionField
          label="Email Action"
          render={record => <EmailClipInfo record={record} />}
        />
        <BooleanInput source="is_used" label="Clip Used" />
        <BooleanField source="has_intro" label="Clip Has Intro" />
        <TextField source="time_in" label="time_in" />
        <TextField source="uri" label="uri" />
        <FunctionField
          label="Snippet"
          render={record => <CopySnippet clip={record} />}
        />
        <FunctionField
          label="Reviewed"
          render={record => <UpdateReviewed record={record} />}
        />
      </SimpleForm>
    </Edit>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <SelectArrayInput
        style={{ width: 200 }}
        label="Clip Type"
        source="type"
        choices={[
          { id: "dose", name: "Dose" },
          { id: "tat_clip", name: "TAT Clip" },
          { id: "plug", name: "Plug" },
          { id: "pending", name: "Pending" },
          { id: "standard", name: "Standard" },
          { id: "other", name: "Other" },
        ]}
        alwaysOn
      />
      <BoolNumInput source="dose_id_ne" label="Unfilled Dose_IDs" alwaysOn />
      <TextInput label="User Email" source="user.email" alwaysOn />
      <TextInput label="Title" source="title" alwaysOn />
      <TextInput label="Notes" source="notes" alwaysOn />
      <TextInput label="User ID" source="user_id" alwaysOn />
      <TextInput label="Lecture ID" source="videoID" alwaysOn />
      <TextInput label="URL" source="link" alwaysOn />
      <DateInput
        label="From"
        source="created_at.>"
        alwaysOn
        format={formatDateTimeInput}
        parse={parseDateTimeIntUTC}
      />
      <DateInput
        label="To"
        source="created_at.<"
        alwaysOn
        format={formatDateTimeInput}
        parse={parseDateTimeIntUTC}
      />
      <TextInput label="Min Sec" source="duration.>" />
      <TextInput label="Max Sec" source="duration.<" />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Clip 'N Share"
      bulkActionButtons={<UpdateTypeButton />}
    >
      <Datagrid
        rowStyle={(record, index) => ({
          backgroundColor:
            record.type === "tat_clip" || record.type === "dose"
              ? record.is_used
                ? "#d6ffe1"
                : "lightyellow"
              : record.type === "pending"
                ? record.is_used
                  ? "#d6ffe1"
                  : "#dbf2ff"
                : undefined,
        })}
      >
        <FunctionField
          label="Action"
          render={record => {
            const { id } = record;
            return (
              <Button
                component={Link}
                to={`/manage-clipnshare/${id}/edit`}
                color="primary"
              >
                EDIT
              </Button>
            );
          }}
        />
        <FunctionField
          label="Reviewed"
          render={record => <UpdateReviewed record={record} />}
        />
        <FunctionField
          label="Snippet"
          render={record => <CopySnippet clip={record} />}
        />
        <FunctionField
          label="Clip Link"
          render={record => (
            <a rel="noopener noreferrer" target="_blank" href={record.link}>
              <Launch />
            </a>
          )}
        />
        <FunctionField
          label="Type"
          source="type"
          render={record =>
            [
              record.type ?? "",
              record.dose_id ? "#" + record.dose_id : "",
            ].join(" ")
          }
        />
        <FunctionField
          label="Duration"
          source="duration"
          render={record => getDurationStr(record.duration)}
        />
        <FunctionField
          label="Speaker"
          render={r => (
            <a
              target="_blank"
              href={`https://www.torahanytime.com/#/speaker?l=${r.lecture_view?.speaker}`}
              rel="noopener noreferrer"
            >
              {`${r.lecture_view?.speaker_short_title} ${r.lecture_view?.speaker_first_name} ${r.lecture_view?.speaker_last_name}`}
            </a>
          )}
        />
        <DateField
          source="created_at"
          label="Created"
          showTime
          options={{
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <FunctionField
          label="Used"
          render={record =>
            !record.is_used ? (
              <UpdateUsed record={record} isUsed={true} />
            ) : (
              <UpdateUsed record={record} isUsed={false} />
            )
          }
        />
        {/* Used a FunctionField since sort is broken */}
        <FunctionField label="Email" render={r => `${r.user?.email}`} />
        {/*<TextField source="user_id" label="User ID" />*/}
        <FunctionField
          style={{ width: "100%" }}
          label="Lecture ID"
          render={record =>
            record.link && (
              <a
                target="_blank"
                href={`https://www.torahanytime.com/#/lectures?v=${record.videoID}`}
                rel="noopener noreferrer"
              >
                {record.videoID}
              </a>
            )
          }
        />
        <TextField source="title" label="Title" />
        <TextField source="notes" label="Notes" />
      </Datagrid>
    </List>
  );
}

export const ClipNShare = {
  category: "database",
  name: "manage-clipnshare",
  list: ListView,
  icon: resourceIcon,
  options: { label: "Clip 'N Share" },
  edit: EditView,
};

const styles = theme => ({
  usedContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 18,
    padding: 4,
  },
  closeIcon: {
    color: "red",
    cursor: "pointer",
    "& :hover": {
      opacity: 0.7,
    },
  },
});

const UpdateUsed = withStyles(styles)(({ record, isUsed, classes }) => {
  const payload = { id: record.id, data: { is_used: isUsed } };
  return (
    <Mutation
      type="UPDATE"
      resource="manage-clipnshare"
      payload={payload}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "actionUpdate.success",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "actionUpdate.fail",
            level: "warning",
          },
        },
      }}
    >
      {approve =>
        isUsed ? (
          <Button
            variant="outlined"
            size="small"
            label="Add"
            onClick={approve}
            color="primary"
          >
            Mark Used
          </Button>
        ) : (
          <span className={classes.usedContainer}>
            Used
            <CloseIcon className={classes.closeIcon} onClick={approve} />
          </span>
        )
      }
    </Mutation>
  );
});
const UpdateReviewed = withStyles(styles)(({ record, classes }) => {
  const payload = { id: record.id, data: { reviewed: !record.reviewed } };
  return (
    <Mutation
      type="UPDATE"
      resource="manage-clipnshare"
      payload={payload}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "actionUpdate.success",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "actionUpdate.fail",
            level: "warning",
          },
        },
      }}
    >
      {approve =>
        !record.reviewed ? (
          <Button
            variant="outlined"
            size="small"
            label="Add"
            onClick={approve}
            color="primary"
          >
            Mark Reviewed
          </Button>
        ) : (
          <span className={classes.usedContainer}>
            Reviewed
            <CloseIcon className={classes.closeIcon} onClick={approve} />
          </span>
        )
      }
    </Mutation>
  );
});

const EmailClipInfo = withStyles(styles)(({ record, classes }) => {
  const [to, setTo] = useState(
    "rachamim@torahanytime.com,shimonkaylyakov@gmail.com",
  );
  const payload = () => ({ id: record.id, data: { to } });
  return (
    <Mutation
      type="UPDATE"
      resource="clipnshare/email"
      payload={payload()}
      options={{
        refresh: true,
        onSuccess: {
          notification: {
            body: "actionUpdate.success",
            level: "info",
          },
        },
        onFailure: {
          notification: {
            body: "actionUpdate.fail",
            level: "warning",
          },
        },
      }}
    >
      {approve => (
        <div>
          <CoreTextField
            style={{
              width: 600,
              marginBottom: 20,
            }}
            title="Emails to send snippet to"
            value={to}
            onChange={e => setTo(e.target.value)}
            label="Email To"
          />
          <Button
            variant="outlined"
            size="small"
            label="Add"
            onClick={approve}
            color="primary"
          >
            Email Clip Info
          </Button>
        </div>
      )}
    </Mutation>
  );
});
