import React from "react";
import IconExpand from "@material-ui/icons/ChevronRight";
import IconCollapse from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  listItem: {
    paddingLeft: "1rem",
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: "1rem",
  },
  sidebarIsOpen: {
    paddingLeft: 22,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
};

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  icon: Icon,
  isClosed,
  name,
  classes,
  children,
}) => (
  <React.Fragment>
    <ListItem
      dense
      button
      onClick={handleToggle}
      className={classes.listItem}
      title={sidebarIsOpen ? "" : name}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={isClosed ? "" : name}
        secondary={isClosed ? name : ""}
        className={classes.listItemText}
      />
      {isClosed ? <IconExpand /> : <IconCollapse />}
    </ListItem>
    <Collapse in={!isClosed} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </React.Fragment>
);

const enhance = withStyles(styles);

export default enhance(SubMenu);
