import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  UrlField,
  List,
  NumberField,
  SimpleForm,
  TextField,
  BooleanInput,
  TextInput,
  required,
  regex,
} from "react-admin";

import resourceIcon from "@material-ui/icons/DeviceHub";
import { EditorToolbar, WideCard } from "../components";

const validateImageLink = [
  required(),
  regex(
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    "Must be a valid URL (http://...)",
  ),
];

const validateLink = regex(
  /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  "Must be a valid URL (http://...)",
);

function ListView(props) {
  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <ImageField source="image" sortable={false} />
        <TextField source="name" />
        <UrlField
          source="link"
          target="_blank"
          rel="noopener noreferrer"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}

const PreviewImage = props => (
  <a
    href={props.record.link}
    target="_blank"
    title={props.record.name}
    rel="noopener noreferrer"
  >
    <WideCard photo={props.record.image} containImage />
  </a>
);

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <TextInput source="name" />
      <TextInput source="image" validate={validateImageLink} />
      <TextInput source="link" validate={validateLink} />
      <TextInput source="description" multiline />
      <BooleanInput source="display_active" />
      <PreviewImage />
    </SimpleForm>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const Partners = {
  category: "tanext",
  name: "partners",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Partners" },
};
