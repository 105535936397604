import React from "react";
import { Link } from "react-router-dom";
import {
  Datagrid,
  Filter,
  List,
  Show,
  TextField,
  FunctionField,
  DateField,
  DateInput,
  SimpleShowLayout,
} from "react-admin";
import { Button } from "@material-ui/core";
import emailIcon from "@material-ui/icons/Email";
import {
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
} from "../../components";
import format from "date-fns/format";
import { CopyToClipboard } from "../../components";
import { openPreview } from "./helpers";

function ShowView(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField
          source="updated_at"
          label="Updated At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />

        <FunctionField
          label="HTML"
          render={record => {
            const { html } = record;
            return (
              <div>
                <Button onClick={() => openPreview(html)}>Open Preview</Button>
                <CopyToClipboard
                  style={{ display: "inline" }}
                  textToCopy={html}
                />
              </div>
            );
          }}
        />

        <FunctionField
          label="Editor URL"
          render={record => {
            const { id, secret } = record;

            const url = `${
              process.env.NODE_ENV === "production"
                ? "https://torahanytime.com"
                : "http://localhost:3303"
            }/corp-sponsor?tab=email&emailId=${id}&secret=${secret}`;
            return (
              <a href={url} target="_blank">
                {url}
              </a>
            );
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <DateInput label="From" source="created_at" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={false}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Email Editor"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          render={record => {
            const { id, html } = record;
            return (
              <Button
                disabled={html === null}
                component={Link}
                to={`/corporate_email_template_editor/${id}/show`}
                color="primary"
              >
                View
              </Button>
            );
          }}
        />
        <TextField source="email" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
        <DateField
          source="updated_at"
          label="Updated At"
          showTime
          options={{
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/New_York",
          }}
        />
      </Datagrid>
    </List>
  );
}

export const EmailTemplateEditor = {
  category: "corporate_sponsors",
  name: "corporate_email_template_editor",
  list: ListView,
  show: ShowView,
  icon: emailIcon,
  options: { label: "Email Editor" },
  roles: ["corporate_sponsor_admin"],
  permissions: {
    corporate_sponsor_admin: ["list", "show"],
  },
};
