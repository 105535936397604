import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MuiTab from "@material-ui/core/Tab";
import classnames from "classnames";
import { translate } from "ra-core";

const sanitizeRestProps = ({ label, icon, value, translate, ...rest }) => rest;

const hiddenStyle = { display: "none" };

class CustomFormTab extends React.PureComponent {
  renderHeader = ({ className, label, icon, value, translate, ...rest }) => {
    const to = { pathname: value, state: { skipFormReset: true } };

    return (
      <MuiTab
        key={label}
        label={translate(label, { _: label })}
        value={value}
        icon={icon}
        className={classnames("form-tab", className)}
        component={Link}
        to={to}
        {...sanitizeRestProps(rest)}
      />
    );
  };

  renderContent = ({ children, hidden, basePath, record, resource }) => (
    <div style={hidden ? hiddenStyle : null}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          basePath,
          record,
          resource,
        }),
      )}
    </div>
  );

  render() {
    const { children, context, ...rest } = this.props;
    return context === "header"
      ? this.renderHeader(rest)
      : this.renderContent({ children, ...rest });
  }
}

CustomFormTab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.oneOf(["header", "content"]),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  translate: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CustomFormTab.displayName = "CustomFormTab";

export default translate(CustomFormTab);
