import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberField,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/ViewList";
import Button from "@material-ui/core/Button";
import {
  BoolNumInput,
  CategoryInput,
  connectCommon,
  CustomFormTab,
  EditorToolbar,
  isTabLocation,
  LanguageInput,
  SpeakerInput,
  SubCategoryInput,
  validation,
  ListActions,
} from "../components";
import { authClient } from "../server";

const validate = {
  title: [validation.required(), validation.minLength(2)],
  speaker: [validation.required()],
  language_id: [validation.required()],
};

class SeriesLectures extends React.PureComponent {
  state = {
    lectures_text:
      "" +
      (Array.isArray(this.props.record.seriesLectures)
        ? this.props.record.seriesLectures
            .map((row) => row.lecture_id)
            .join("\n")
        : ""),
    updating: false,
  };
  /** @param {React.KeyboardEvent<HTMLTextAreaElement>} e */
  keyUp = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
      this.save(e);
    }
  };

  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <div style={styles.formArea}>
          <p>
            Please enter one or more Lecture ID, each separated by a new line.
            <br />
            The order of Lecture IDs here determines the default sort order.
          </p>
          <textarea
            value={this.state.lectures_text}
            onKeyUp={this.keyUp}
            onChange={this.update}
            rows="15"
            cols="40"
          ></textarea>
          <div>
            <Button variant="contained" color="primary" onClick={this.save}>
              Save Lectures
            </Button>
          </div>
        </div>
      </div>
    );
  }

  /** @param {React.SyntheticEvent<HTMLTextAreaElement>} e */
  update = (e) => {
    this.setState({
      lectures_text: e.currentTarget.value,
    });
  };

  save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { lectures_text, updating } = this.state;
    if (updating) {
      return;
    }
    const parts = lectures_text.split("\n").map((line) => line.trim());
    if (parts.length > 0) {
      if (
        !parts.reduce(
          (ok, line) => (!ok ? ok : !!line.match(/^[\d,]*[, \r]*$/g)),
          true,
        )
      ) {
        window.alert("Only numbers, commas and new lines are allowed.");
        return;
      }
    }
    const {
      fetchEnd,
      fetchStart,
      record: { id },
      showError,
      showNotification,
    } = this.props;
    fetchStart();
    authClient
      .post(`/admin/series/${id}/lectures`, {
        lectures_text,
      })
      .then((res) => {
        const {
          json: { lectures_text },
        } = res;
        this.setState({ lectures_text });
        showNotification("Lectures updated.");
      })
      .catch(showError)
      .finally(fetchEnd);
  };
}
const SeriesLecturesView = connectCommon(SeriesLectures);

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput label="Title" source="title" alwaysOn />
      <BoolNumInput source="completed" alwaysOn />
    </Filter>
  );
}

function validateForm(values) {
  const errors = {};
  if (!values.parent_category_id && !values.category_id) {
    errors.category_id = "Topic required.";
  }
  return errors;
}

function renderEditForm({ creating, redirect }) {
  return (
    <TabbedForm
      redirect={redirect}
      toolbar={isTabLocation(1) ? null : <EditorToolbar />}
      submitOnEnter={false}
      validate={validateForm}
    >
      <FormTab label="Details" replace>
        <ImageField source="image" label="Image" />
        <LongTextInput source="title" label="Title" validate={validate.title} />
        <SpeakerInput source="speaker_id" validate={validate.speaker} />
        <CategoryInput
          source="parent_category_id"
          label="Topic"
          allowEmpty={true}
        />
        <SubCategoryInput
          source="category_id"
          parentSource="parent_category_id"
          label=""
        />
        <LanguageInput source="language_id" validate={validate.language_id} />
        <BooleanInput source="display_active" label="Active" />
        <BooleanInput source="completed" label="Completed" />
        <BooleanInput source="female_only" label="Female only" />
        <ImageInput
          source="FILE_image"
          label="Image Upload"
          accept="image/*"
          placeholder={<p>Click or drop your file here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      {!creating && (
        <CustomFormTab label="Lectures" replace>
          <SeriesLecturesView />
        </CustomFormTab>
      )}
    </TabbedForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props}>
      {renderEditForm({ creating: true, redirect: "edit" })}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      filters={<FiltersView />}
      actions={<ListActions />}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      title="Series"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <ImageField source="image" label="Image" />
        <TextField source="title" label="Title" />
        <ReferenceField
          label="Speaker"
          source="speaker_id"
          reference="speakers"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Topic"
          source="category_id"
          reference="categories"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Language"
          source="language_id"
          reference="languages"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="lecture_count" label="Lectures" />
        <BooleanField source="display_active" label="Active" />
        <BooleanField source="completed" label="Completed" />
        <BooleanField source="female_only" label="Female only" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

const styles = {
  formArea: {
    lineHeight: "42px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: ".875rem",
  },
};

export const Series = {
  category: "tanext",
  name: "series",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  roles: ["series_admin"],
  permissions: {
    series_admin: ["create", "list", "view", "edit"],
  },
  options: { label: "Series" },
};
