import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";

const labels = ["Male", "Female"];

export class UserGenderField extends React.PureComponent {
  static defaultProps = {
    addLabel: true,
  };

  render() {
    const { className, record, source = "is_female", title } = this.props;
    const value = get(record, source);
    // The value is 0 for Male, 1 for Female. If undefined, display nothing.
    const text = labels[value];
    return (
      <Typography
        component="span"
        body1="body1"
        className={className}
        title={title}
      >
        {text}
      </Typography>
    );
  }
}
