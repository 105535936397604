import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/CardGiftcard";
import { ListActions } from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="email" alwaysOn />
      <TextInput label="Date (Hebrew)" source="date_hebrew" alwaysOn />
      <TextInput label="Dedication" source="name" alwaysOn />
      <TextInput source="relationship" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "date_created", order: "DESC" }}
      title="Dedications"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="date" /> */}
        <DateField source="date_created" label="Created" />
        <TextField
          source="date_hebrew"
          label="Date"
          style={{ minWidth: 120 }}
        />
        <TextField source="name" label="Dedication" />
        <TextField source="user_name" label="User" style={{ minWidth: 140 }} />
        <TextField source="email" />
        <TextField source="relationship" style={{ minWidth: 140 }} />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Dedication">
          <TextField source="cause" />
          <DateField source="date_created" showTime label="Created" />
          <TextField source="date" label="Date Formatted" />
          <TextField source="date_hebrew" label="Hebrew Date" />
          <TextField source="name" label="Dedication" />
          <TextField source="user_name" label="User" />
          <TextField source="email" />
          <TextField source="relationship" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const Dedications = {
  category: "database",
  name: "dedications",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Dedications" },
  show: ShowView,
};
