import io from "socket.io-client";

const { REACT_APP_SOCKET_IO_URL: baseUrl } = process.env;

let auth;

export let isSocketConnencted = false;

/**
 * Connect to the socket listener
 */
export function authSocketConnect() {
  auth = io(`${baseUrl}/auth`);
  auth.on("connect", function() {
    isSocketConnencted = auth.connected;
  });
}

/**
 * Join a room using the user ID. Each user ID will be a seperate room.
 * @param {String|Number} userId
 * @param {Array.<{event: String, callback: function}>} events
 */
export function joinAuthRoom(userId, events) {
  if (!auth || !userId) return null;
  auth.emit("join", userId + "");
  events.forEach(e => {
    const { event, callback } = e;
    auth.on(event, callback);
  });
}

/**
 * Disconnect from the socket listener
 */
export function authDisconnect() {
  if (auth) {
    auth.disconnect();
    auth = undefined;
  }
}
