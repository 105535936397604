import React from "react";
import PropTypes from "prop-types";
import { connectCommon } from "../connectCommon";

class AutoRefresh extends React.PureComponent {
  static defaultProps = {
    seconds: 30,
  };

  static propTypes = {
    seconds: PropTypes.number,
  };
  /** Id of the last timer created by calling `setTimer`. */
  timer = 0;

  /** Calls `this.props.refreshView()`. */
  refresh = () => {
    const {
      props: { refreshView },
    } = this;
    // console.log("AUTO REFRESH");
    refreshView();
    this.startTimer();
  };

  componentDidMount() {
    // console.log("START AUTO REFRESH");
    this.startTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return null;
  }

  startTimer() {
    const {
      props: { seconds },
      refresh,
    } = this;
    this.timer = setTimeout(refresh, seconds * 1000);
  }
}

export const AutoRefreshView = connectCommon(AutoRefresh);
