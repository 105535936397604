import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import { authClient } from "../server";
import { connectCommon } from "../components";

const cardStyle = {
  fontFamily: "Roboto Condensed, sans-serif",
};
const containerStyle = {
  display: "flex",
};
const columnStyle = {
  padding: 10,
  fontSize: 39,
  lineHeight: 1,
};
const headerStyle = {
  fontSize: 23,
  fontWeight: 400,
};
const linkItemStyle = { padding: 6 };

class Dashboard extends React.PureComponent {
  state = {
    summary: {
      categories: 0,
      languages: 0,
      speakers: 0,
    },
  };

  async getSummary() {
    this.props.fetchStart();
    const res = await authClient
      .get("/admin/dashboard/summary")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      summary: res.json,
    });
  }

  async loggedInUser() {
    this.props.fetchStart();
    const res = await authClient
      .get("/users/mine")
      .catch(this.props.showError)
      .finally(this.props.fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      profile: res.json,
    });
  }

  componentDidMount() {
    this.getSummary();
    this.loggedInUser();
  }

  render() {
    const {
      state: {
        summary: { categories = 0, languages = 0, lectures = 0, speakers = 0 },
      },
    } = this;
    return (
      <Card>
        <Title title="Dashboard" />
        <CardContent style={cardStyle}>
          <h2 style={headerStyle}>Summary</h2>
          {!!this.state.profile && (
            <div
              style={{
                position: "absolute",
                right: 50,
                top: 100,
              }}
            >
              Welcome {this.state.profile.screen_name}
            </div>
          )}
          <div style={containerStyle}>
            <div style={columnStyle}>
              <div>{speakers.toLocaleString()}</div>
              <h2 style={headerStyle}>Speakers</h2>
            </div>
            <div style={columnStyle}>
              <div>{categories.toLocaleString()}</div>
              <h2 style={headerStyle}>Categories</h2>
            </div>
            <div style={columnStyle}>
              <div>{languages.toLocaleString()}</div>
              <h2 style={headerStyle}>Languages</h2>
            </div>
          </div>
          <div style={containerStyle}>
            <div style={columnStyle}>
              <div>{lectures.toLocaleString()}</div>
              <h2 style={headerStyle}>Lectures</h2>
            </div>
          </div>
          <h2 style={headerStyle}>Links</h2>
          <ul>
            <li style={linkItemStyle}>
              <a
                href="https://www.torahanytime.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Front Page
              </a>
            </li>
            <li style={linkItemStyle}>
              <a
                href="https://vimeo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vimeo
              </a>
            </li>
          </ul>
        </CardContent>
      </Card>
    );
  }
}
export const DashboardPage = connectCommon(Dashboard);
