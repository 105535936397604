import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { SimpleFileInput } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/School";
import { withRouter } from "react-router-dom";
import { connectCommon } from "../../components";
import { Title, FileField } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import SendIcon from "@material-ui/icons/Send";

const { REACT_APP_TINYMCE_KEY } = process.env;

const styles = {
  myTextField: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
};
export const DailyDoseEmail = {
  menu: {
    category: "dose",
    icon,
    name: "daily-dose-emails",
    label: "Dose Emails",
    url: "/email/daily-dose-emails",
    roles: ["dose_email_admin"],
  },
  options: { label: "Lecture Stats" },
  route: {
    exact: true,
    name: "daily-dose-emails",
    path: "/email/daily-dose-emails",
    view: withStyles(styles)(withRouter(connectCommon(View))),
  },
};

function View(props) {
  const {
    location: { search },
  } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const cardStyle = {
    fontFamily: "Roboto Condensed, sans-serif",
  };
  const emailTemplate = {
    title: "",
    clip_number: "",
    parent_lecture_id: "",
    feedbacks: ["", "", ""],
    scheduled_date_time: "",
    dedications_section: "",
  };
  const [email, setEmail] = useState({ ...emailTemplate });
  const isfirstTime = useRef(true);
  useEffect(() => {
    if (!isfirstTime.current) {
      return;
    }
    let isMounted = true; // note this flag denote mount status
    if (search && search.split("?id=").length) {
      authClient
        .get("admin/dose-emails/" + search.split("?id=")[1])
        .then(res => {
          isfirstTime.current = false;
          console.log("emails", res.json);
          const {
            title,
            clip_number,
            parent_lecture_id,
            feedbacks,
            scheduled_date_time,
            dedications_section,
          } = res.json;
          isMounted &&
            setEmail({
              title,
              clip_number,
              parent_lecture_id,
              feedbacks: JSON.parse(feedbacks),
              scheduled_date_time,
              dedications_section,
            });
        })
        .catch(console.error);
    }
    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [email, search]);
  const handleChange = event => {
    const { name, value } = event.target;
    setEmail({ ...email, [name]: value });
  };
  const requiredFields = {
    Title: email.title,
    "Clip #": email.clip_number,
    Clip: email.clip_file?.rawFile,
    image: email.image_file?.rawFile,
    "Parent Lecture Id": email.parent_lecture_id,
  };
  const handleSubmit = async event => {
    event.preventDefault();
    var formData = new FormData();
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!value) {
        props.showNotification(`${key} is required!`, "warning");
        return;
      }
    }
    if (email.is_test && !email.test_email_address) {
      props.showNotification("A test email address is required", "warning");
      return;
    }
    setButtonDisabled(true);
    try {
      formData.append("clip_file", email.clip_file.rawFile);
      formData.append("image_file", email.image_file.rawFile);
      formData.append(
        "feedbacks",
        JSON.stringify(email.feedbacks.filter(f => f)),
      );
      if (email.scheduled_date_time) {
        formData.append(
          "scheduled_date_time",
          new Date(email.scheduled_date_time).toISOString().split(".")[0],
        );
      }
      formData.append("title", email.title);
      formData.append("clip_number", email.clip_number);
      formData.append("parent_lecture_id", email.parent_lecture_id);
      formData.append("dedications_section", email.dedications_section || "");
      formData.append("template_id", email.template_id || "");
      formData.append("test_email_address", email.test_email_address);
      if (email.is_test) {
        formData.append("is_test", true);
      }
      await authClient
        .putUnstringifiedData("/admin/create-dose-broadcast", formData)
        .then(r => {
          console.log(r);
          props.showNotification(r.json.message);
          if (!email.is_test) {
            setEmail({ ...emailTemplate });
          }
          setButtonDisabled(false);
        })
        .catch(e => {
          console.error(e);
          props.showNotification(
            `Error! ${e}${
              e[e.length - 1] !== "." ? "." : ""
            } Submit again at your own risk.`,
            "warning",
          );
          setButtonDisabled(false);
        });
    } catch (e) {
      console.error(e);
      props.showNotification("Error!", "warning");
    }
  };
  const [templates, setTemplates] = useState([]);
  useEffect(() => {
    authClient.get("/admin/email-templates/code/dose_email").then(res => {
      setTemplates(res.json);
      console.log("res", res);
    });
  }, []);
  return (
    <Card style={{ marginLeft: 55 }}>
      <Title title="Daily Dose Email" />
      <CardContent style={cardStyle}>
        <TextField
          style={styles.myTextField}
          select
          fullWidth
          label="Template"
          name="template_id"
          value={email.template_id || ""}
          onChange={handleChange}
        >
          <MenuItem value="">Select a Template</MenuItem>
          {templates.map((t, i) => (
            <MenuItem key={i} value={t.id}>
              {t.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          style={styles.myTextField}
          fullWidth
          label="Title"
          name="title"
          value={email.title}
          onChange={handleChange}
        />
        <TextField
          style={styles.myTextField}
          fullWidth
          label="Parent Lecture Id"
          name="parent_lecture_id"
          value={email.parent_lecture_id}
          onChange={handleChange}
        />
        <TextField
          style={styles.myTextField}
          fullWidth
          label="Clip #"
          name="clip_number"
          value={email.clip_number}
          onChange={handleChange}
        />
        <SimpleFileInput
          placeholder={<p>Click or drop your file here.</p>}
          label="Image"
          input={{
            onBlur: file => {
              setEmail({ ...email, image_file: file });
            },
            value: email.image_file,
          }}
        >
          <FileField source="src" title="title" />
        </SimpleFileInput>
        <SimpleFileInput
          placeholder={<p>Click or drop your file here.</p>}
          label="Clip"
          input={{
            onBlur: file => {
              setEmail({ ...email, clip_file: file });
            },
            value: email.clip_file,
          }}
        >
          <FileField source="src" title="title" />
        </SimpleFileInput>
        {/* <Button
          variant="contained"
          size="small"
          label="Add"
          style={{ marginTop: 40, marginBottom: 20 }}
          onClick={() =>
            setEmail({ ...email, feedbacks: [...email.feedbacks, ""] })
          }
        >
          Add Feedback
        </Button> */}
        <label>Dedications Section</label>
        <TinyMCE
          apiKey={REACT_APP_TINYMCE_KEY}
          init={{
            height: 500,
            menubar: false,
          }}
          name="dedications_section"
          value={email.dedications_section}
          onEditorChange={e => {
            setEmail({ ...email, dedications_section: e });
          }}
        />
        {email.feedbacks.map((uf, i) => (
          <TextField
            key={i}
            style={{
              display: "block",
            }}
            fullWidth={true}
            multiline={true}
            placeholder="Feedback"
            rows={3}
            value={uf}
            onChange={e => {
              const feedbacks = [...email.feedbacks];
              feedbacks[i] = e.target.value;
              setEmail({ ...email, feedbacks: [...feedbacks] });
            }}
          />
        ))}

        <TextField
          type="datetime-local"
          label="Send at"
          InputLabelProps={{
            shrink: true,
          }}
          name="scheduled_date_time"
          value={email.scheduled_date_time}
          onChange={handleChange}
        />
        <br />
        <FormControlLabel
          control={
            <Switch
              name="is_test"
              label="Send test email?"
              onChange={e => {
                console.log("e.target.checked", e.target.checked);
                setEmail({ ...email, is_test: e.target.checked });
              }}
            />
          }
          label="Send test email?"
        />
        {email.is_test && (
          <TextField
            multiline
            fullWidth
            name="test_email_address"
            onChange={handleChange}
            value={email.test_email_address}
            placeholder="Email Address(es)"
          />
        )}
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            color="primary"
            style={{
              marginTop: 30,
            }}
            onClick={handleSubmit}
          >
            {!email.scheduled_date_time ? "Send" : "Schedule"}
            <SendIcon style={{ fontSize: 20, marginLeft: 10 }} />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
