import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  Create,
  Filter,
  ImageField,
  List,
  LongTextInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
} from "react-admin";
import {
  BoolNumInput,
  CategoryInput,
  ConventionInput,
  DateTimeInput,
  EditorToolbar,
  HostInput,
  LanguageInput,
  ListActions,
  LocationInput,
  OrganizationInput,
  SpeakerInput,
  SubCategoryInput,
  parseDateTimeInt,
  // validation,
} from "../../components";
import { timezoneOptions } from "../../assets/js/timezones";
import resourceIcon from "@material-ui/icons/LiveTv";
import { BoolNumField } from "../../components/field/BoolNumField";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="Title" source="event_title" alwaysOn />
      <SpeakerInput
        label="Speaker"
        source="speaker"
        reference="speakers"
        alwaysOn
      />
    </Filter>
  );
}
const validateDateCreated = (values) => {
  const errors = {};
  if (!values.date_start) {
    errors.date_start = ["Date start is required"];
  }
  if (!values.date_end) {
    errors.date_end = ["Date end is required"];
  }
  if (values.date_start > values.date_end) {
    errors.date_start = ["Date start must be less than Date end!"];
    errors.date_end = ["Date end must be greater than Date start!"];
  }
  return errors;
};
function renderEditForm({ redirect }) {
  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
      validate={validateDateCreated}
    >
      <BoolNumInput source="display_active" label="Display" />
      <LongTextInput source="event_title" label="Title" />
      <LongTextInput source="url" label="Event URL" />
      <BoolNumInput source="ladies" />
      <TextInput source="ref_post_id" label="Post ID" />
      <DateTimeInput
        source="date_start"
        label="Start"
        parse={parseDateTimeInt}
      />
      <DateTimeInput source="date_end" label="End" parse={parseDateTimeInt} />
      <SelectInput
        source="time_zone"
        label="Timezone"
        choices={timezoneOptions}
      />
      <LongTextInput source="event_picture" label="Event Picture" />
      <ConventionInput source="convention_id" allowEmpty />
      <BoolNumInput source="is_keynote" />
      <NumberInput source="lecture_id" allowEmpty label="Lecture ID" />
      <TextInput
        source="speaker_list"
        allowEmpty
        label="Speaker List (used by conventions)"
      />

      <TextInput source="recurring_pattern" label="Recurring Pattern" />
      <BoolNumInput source="recurring" label="Recurring" />
      <LanguageInput allowEmpty />
      <LocationInput allowEmpty />
      <HostInput allowEmpty />
      <OrganizationInput allowEmpty />
      <SpeakerInput allowEmpty />
      <CategoryInput allowEmpty />
      <SubCategoryInput allowEmpty />
    </SimpleForm>
  );
}
function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}
function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{ field: "date_start", order: "DESC" }}
      title="Live Events"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <ImageField source="pic_url" label="Picture" />
        <TextField source="event_title" label="Title" />
        <TextField source="speaker_name" label="By" style={{ minWidth: 180 }} />
        <BoolNumField source="display_active" label="Active" />
        <DateField
          source="date_start"
          showTime
          label="Start"
          style={{ minWidth: 160 }}
        />
      </Datagrid>
    </List>
  );
}

export const LiveEvents = {
  category: "manage",
  name: "manage-live-events",
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  create: CreateView,
  options: { label: "Live Events" },
};
