import englishMessages from "ra-language-english";

const messages = {
  en: {
    ...englishMessages,
    uploads: {
      archived: "OK. Uploads archived.",
      republished: "OK. Uploads republished.",
    },
    doseDonors: {
      updated: "Dedications updated.",
      failed: "Failed.",
    },
    actionUpdate: {
      success: "Update completed successfully.",
      fail: "Update Failed.",
    },
    email: {
      submitted: "Submitted email"
    }
  },
};
export const i18nProvider = locale => messages[locale];
