import React from "react";
import {
  Edit,
  SimpleForm,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Label";
import { CategoryInput, EditorToolbar } from "../../components";

function EditView(props) {
  return (
    <Edit {...props} title="Mobile App Categories">
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <CategoryInput
          label="Upcoming Holidays"
          source="holidays"
          filter={{ parent: 8 }}
          allowEmpty
        />
        <CategoryInput
          label="Weekly Parasha"
          source="parasha"
          reference="categories"
          filter={{ parent: 23 }}
          allowEmpty
        />
      </SimpleForm>
    </Edit>
  );
}

export const MobileAppCategories = {
  category: "manage",
  name: "manage-mobile-categories",
  edit: EditView,
  editId: "app_categories",
  icon: resourceIcon,
  options: { label: "Mobile App Categories" },
};
