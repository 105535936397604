import React from "react";
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  FormTab,
  List,
  TabbedForm,
  TextField,
  TextInput,
  SelectInput,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FalseIcon from "@material-ui/icons/Clear";
import TrueIcon from "@material-ui/icons/Done";
import resourceIcon from "@material-ui/icons/AccountCircle";
import {
  BoolNumInput,
  CustomFormTab,
  EditorToolbar,
  ListActions,
  connectCommon,
  formatDate,
  formatDateTime,
  formatDateTimeInput,
  isTabLocation,
} from "../components";
import { authClient, authRoles } from "../server";
import { UsersRethink } from "../pages/users/UsersRethink";

const roleChoices = authRoles.map(ar => ({ id: ar.id, name: ar.name }));

class Devices extends React.PureComponent {
  state = {
    items: [],
  };
  componentDidMount() {
    if (isTabLocation(3)) {
      this.getData();
    }
  }
  async getData() {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
      },
    } = this;
    fetchStart();
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(id)}/devices`)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      items: res.json,
    });
  }
  render() {
    const {
      state: { items },
    } = this;
    return (
      <div style={{ marginTop: 20 }}>
        <div style={styles.row}>
          <span style={styles.header_120}>Created</span>
          <span style={styles.header_180}>Updated</span>
          <span style={styles.header_wide}>Device</span>
          <span style={styles.header_140}>IP</span>
          <span style={styles.header_100}>Verified</span>
          <span style={styles.header}>ID</span>
        </div>
        {items.map(Devices.renderItem)}
      </div>
    );
  }
  static renderItem(item) {
    const created = formatDate(item.time_created);
    const updated = formatDateTime(item.time_updated);
    const verified = !!item.verified;
    return (
      <div key={item.id} style={styles.row} title={"id: " + item.id}>
        <span style={styles.col_120}>{created}</span>
        <span style={styles.col_180}>{updated}</span>
        <span style={styles.col_wide}>{item.agent_string}</span>
        <span style={styles.col_140}>{item.ip}</span>
        <span style={styles.col_100}>
          {verified ? <TrueIcon /> : <FalseIcon />}
        </span>
        <span style={styles.col}>{item.id}</span>
      </div>
    );
  }
}
const DevicesView = connectCommon(Devices);

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput source="email" alwaysOn />
      <TextInput source="name_first" alwaysOn label="First name" />
      <TextInput source="name_last" alwaysOn label="Last name" />
      <DateInput
        label="From"
        source="created_at.>"
        alwaysOn
        format={formatDateTimeInput}
      />
      <DateInput
        label="To"
        source="created_at.<"
        alwaysOn
        format={formatDateTimeInput}
      />
    </Filter>
  );
}

class Following extends React.PureComponent {
  state = {
    items: [],
    selectedAll: false,
  };

  toggleSelectAll = () => {
    this.setState(state => {
      const { items, selectedAll } = state;
      const selected = !selectedAll;
      return {
        ...state,
        items: items.map(item => ({
          ...item,
          selected,
        })),
        selectedAll: selected,
      };
    });
  };

  toggleSelected(id) {
    return () => {
      this.setState(state => {
        const { items } = state;
        return {
          ...state,
          items: items.map(item =>
            item.id !== id
              ? item
              : {
                  ...item,
                  selected: !item.selected,
                },
          ),
          selectedAll: false,
        };
      });
    };
  }

  unfollow = async () => {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id, email },
        showError,
      },
      state: { items },
    } = this;
    const selectedIds = items
      .filter(item => item.selected)
      .map(item => item.id);
    if (selectedIds.length < 1) {
      return;
    }
    if (
      !window.confirm(
        `Unfollow the ${selectedIds.length} selected speaker(s) for:\n` +
          `  ${email}?` +
          "\n******************************\n" +
          "  NOTE: This CANNOT be undone!" +
          "\n******************************\n",
      )
    ) {
      return;
    }
    fetchStart();
    const res = await authClient
      .post(`/admin/users/${encodeURIComponent(id)}/unfollow`, selectedIds)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      items: res.json,
      selectedAll: false,
    });
  };

  updateFollowingEmailPreference = async newState => {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id, email },
        showError,
      },
      state: { items },
    } = this;
    const selectedIds = items
      .filter(item => item.selected)
      .map(item => item.id);
    if (selectedIds.length < 1) {
      return;
    }

    fetchStart();
    const res = await authClient
      .post(
        `/admin/users/${encodeURIComponent(
          id,
        )}/update_notify_by_email?state=${newState}`,
        selectedIds,
      )
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      items: res.json,
    });
  };

  componentDidMount() {
    if (isTabLocation(1)) {
      this.getData();
    }
  }
  async getData() {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
      },
    } = this;
    fetchStart();
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(id)}/following`)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      items: res.json,
    });
  }
  render() {
    const {
      state: { items, selectedAll },
    } = this;
    return (
      <div style={{ marginTop: 20 }}>
        <div style={styles.row}>
          <span style={styles.header}>
            <Checkbox checked={selectedAll} onClick={this.toggleSelectAll} />
          </span>
          <span style={styles.header}>Date</span>
          <span style={styles.header}>Following</span>
          <span style={styles.header}>Email subscription</span>
        </div>
        {items.map(this.renderItem)}
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Button
            variant="raised"
            size="small"
            onClick={this.unfollow}
            title="Unfollow the selected speakers."
          >
            Unfollow
          </Button>
          <Button
            variant="raised"
            size="small"
            onClick={() => this.updateFollowingEmailPreference(false)}
            title="Unsubscribe from the following emails."
          >
            Email Notifications: Off
          </Button>
          <Button
            variant="raised"
            size="small"
            onClick={() => this.updateFollowingEmailPreference(true)}
            title="Subscribe the following emails."
          >
            Email Notifications: On
          </Button>
        </div>
      </div>
    );
  }
  renderItem = item => {
    const subscribed = formatDate(item.date_subscribed);
    const { id, selected = false } = item;

    let component = <span></span>;

    if (item.speaker_name) {
      component = (
        <span>
          Speaker:{" "}
          <a href={`/speakers/${item.speaker_id}`} target="_blank">
            {item.speaker_name}
          </a>
        </span>
      );
    } else if (item.category?.name) {
      component = (
        <span>
          Category:{" "}
          <a href={`/categories/${item.topic_id}`} target="_blank">
            {item.category.name}
          </a>
        </span>
      );
    } else if (item.organization?.name) {
      component = (
        <span>
          Org:{" "}
          <a href={`/organizations/${item.org_id}`} target="_blank">
            {item.organization.name}
          </a>
        </span>
      );
    } else if (item.series) {
      component = (
        <span>
          Series:{" "}
          <a href={`/series/${item.series_id}`} target="_blank">
            {item.series.title}
          </a>{" "}
          <br />{" "}
          <sub>
            <a href={`/speakers/${item.series.speaker.id}`} target="_blank">
              {item.series.speaker_name}
            </a>
          </sub>
        </span>
      );
    }

    return (
      <div key={id} style={styles.row}>
        <span style={styles.col}>
          <Checkbox checked={selected} onClick={this.toggleSelected(id)} />
        </span>
        <span style={styles.col}>{subscribed}</span>
        <span style={styles.col}>{component}</span>
        <span style={styles.col}>{item.notify_by_email ? "On" : "Off"}</span>
      </div>
    );
  };
}
const FollowingView = connectCommon(Following);

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={25}
      sort={{
        field: "email",
        order: "ASC",
      }}
      title="Users"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <TextField source="name_first" sortable={false} />
        <TextField source="name_last" sortable={false} />
        <DateField source="created_at" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <TabbedForm redirect={false} toolbar={<EditorToolbar />}>
        <FormTab label="Details" replace>
          <DateField source="created_at" />
          <BoolNumInput source="verified" />
          <TextInput source="email" />
          <TextInput source="user_login" />
          <TextInput source="screen_name" />
          <TextInput source="name_first" label="First name" />
          <TextInput source="name_last" label="Last name" />
          <BoolNumInput source="is_female" label="Female" />
          <TextInput source="speaker_id" label="Speaker Id" />
          <AutocompleteArrayInput
            source="role"
            choices={roleChoices}
            label="Permissions"
          />
          <SelectInput
            source="upload_zone"
            choices={[
              { id: null, name: "Default" },
              { id: "sfo2", name: "DO SFO2" },
              { id: "nyc3", name: "DO NYC3" },
              { id: "awsS3UsEast", name: "AWS US East" },
            ]}
          />
        </FormTab>
        <CustomFormTab label="Following" replace>
          <FollowingView />
        </CustomFormTab>
        <CustomFormTab label="Verification Attempts" replace>
          <VerificationView />
        </CustomFormTab>
        <CustomFormTab label="Devices" replace>
          <DevicesView />
        </CustomFormTab>
        <FormTab label="Rethink Database" replace>
          <UsersRethink />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

class Verification extends React.PureComponent {
  state = {
    items: [],
  };
  componentDidMount() {
    if (isTabLocation(2)) {
      this.getData();
    }
  }
  async getData() {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
      },
    } = this;
    fetchStart();
    const res = await authClient
      .get(`/admin/users/${encodeURIComponent(id)}/verification`)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      items: res.json,
    });
  }
  render() {
    const {
      state: { items },
    } = this;
    return (
      <div style={{ marginTop: 20 }}>
        <div style={styles.row}>
          <span style={styles.header}>Date</span>
          <span style={styles.header}>Device</span>
          <span style={styles.header}>Verified</span>
          <span style={styles.header}>UUID</span>
        </div>
        {items.map(this.renderItem)}
      </div>
    );
  }
  renderItem = item => {
    // date, device, id, verified
    const date = formatDateTime(item.time);
    return (
      <div key={item.id} style={styles.row}>
        <span style={styles.col}>{date}</span>
        <span style={styles.col}>{item.device}</span>
        <span style={styles.col}>
          {item.verified ? (
            <TrueIcon />
          ) : (
            <>
              <FalseIcon />
              <a href="." onClick={this.verify(item)}>
                Verify now
              </a>
            </>
          )}
        </span>
        <span style={styles.col}>{item.uuid}</span>
      </div>
    );
  };

  verify = item => {
    return async e => {
      e.preventDefault();
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const {
        props: {
          fetchEnd,
          fetchStart,
          record: { id },
          refreshView,
          showError,
        },
      } = this;
      fetchStart();
      await authClient
        .post(
          `/admin/users/${encodeURIComponent(
            id,
          )}/verification/${encodeURIComponent(item.id)}/verify`,
          item,
        )
        .catch(showError)
        .finally(() => {
          fetchEnd();
          refreshView();
        });
    };
  };
}
const VerificationView = connectCommon(Verification);

const styles = {
  col: {
    display: "inline-block",
    width: 280,
    lineHeight: 1,
  },
  col_100: {
    display: "inline-block",
    width: 100,
  },
  col_120: {
    display: "inline-block",
    width: 120,
  },
  col_140: {
    display: "inline-block",
    width: 140,
  },
  col_160: {
    display: "inline-block",
    width: 160,
  },
  col_180: {
    display: "inline-block",
    width: 180,
  },
  col_wide: {
    display: "inline-block",
    width: 340,
  },
  header: {
    display: "inline-block",
    width: 280,
    fontWeight: 500,
  },
  header_100: {
    display: "inline-block",
    width: 100,
    fontWeight: 500,
  },
  header_120: {
    display: "inline-block",
    width: 120,
    fontWeight: 500,
  },
  header_140: {
    display: "inline-block",
    width: 140,
    fontWeight: 500,
  },
  header_160: {
    display: "inline-block",
    width: 160,
    fontWeight: 500,
  },
  header_180: {
    display: "inline-block",
    width: 180,
    fontWeight: 500,
  },
  header_wide: {
    display: "inline-block",
    width: 340,
    fontWeight: 500,
  },
  row: {
    outline: "1px solid #eee",
    paddingLeft: 85,
    lineHeight: "42px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: ".875rem",
  },
};

export const Users = {
  category: "database",
  name: "users",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Users" },
  edit: EditView,
};
