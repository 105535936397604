import React from "react";

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  span: {
    color: "#0000008a",
    fontFamily: "Arial",
    marginTop: 25,
    padding: 14,
    whiteSpace: "nowrap",
  },
};

export class InputRow extends React.PureComponent {
  render() {
    const { children, style, ...rest } = this.props;
    const rowStyle = style ? { ...styles.row, ...style } : styles.row;
    return (
      <span style={rowStyle}>
        {React.Children.map(children, child => {
          if (typeof child.type === "string") {
            if (child.type === "span") {
              return React.cloneElement(child, {
                style: {
                  ...styles.span,
                  ...child.props.style,
                },
              });
            }
            return child;
          }
          return React.cloneElement(child, rest);
        })}
      </span>
    );
  }
}
