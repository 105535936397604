import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { makeSortableFormPage } from "../../components";
import { authClient } from "../../server";
import icon from "@material-ui/icons/NewReleases";

export const ManageBannersPopupPage = makeSortableFormPage({
  createItem() {
    return {
      display: false,
      height: 0,
      image: "",
      interval: 0,
      link: "",
      onclick: "",
      title: "",
      width: 0,
    };
  },
  fetch() {
    return authClient.get("/admin/manage-banners/popup");
  },
  load(res) {
    const {
      items: [item],
    } = res.json;
    return {
      items: [
        {
          ...item,
          display: item.display ? true : false,
          interval: item.interval ? item.interval / 3600000 : 0,
        },
      ],
    };
  },
  maxItems: 1,
  menu: {
    category: "banners",
    icon,
    name: "popup-banners",
    label: "Popup",
    url: "/manage-banners/popup",
  },
  renderItem({ actions, css, item, rowIndex }) {
    const { onChangeItem } = actions;
    return (
      <>
        <FormControl className={css.rowField}>
          <FormControlLabel
            label="Display"
            control={
              <Checkbox
                checked={item.display}
                onChange={onChangeItem(
                  rowIndex,
                  "display",
                  e => e.currentTarget.checked,
                )}
              />
            }
          />
        </FormControl>
        <TextField
          label=""
          placeholder="Image URL"
          value={item.image}
          onChange={onChangeItem(rowIndex, "image")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Title"
          value={item.title}
          onChange={onChangeItem(rowIndex, "title")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="Link URL"
          value={item.link}
          onChange={onChangeItem(rowIndex, "link")}
          className={css.rowField}
        />
        <TextField
          label=""
          placeholder="JS onclick function()"
          value={item.onclick}
          onChange={onChangeItem(rowIndex, "onclick")}
          className={css.rowField}
        />
        <TextField
          label="Height"
          value={item.height}
          onChange={onChangeItem(
            rowIndex,
            "height",
            e => e.currentTarget.valueAsNumber || 0,
          )}
          className={css.rowField}
          type="number"
        />
        <TextField
          label="Width"
          value={item.width}
          onChange={onChangeItem(
            rowIndex,
            "width",
            e => e.currentTarget.valueAsNumber || 0,
          )}
          className={css.rowField}
          type="number"
        />
        <TextField
          label="Interval Between Popups (hour)"
          value={item.interval}
          onChange={onChangeItem(
            rowIndex,
            "interval",
            e => e.currentTarget.valueAsNumber || 0,
          )}
          className={css.rowField}
          style={{ marginTop: 60 }}
          type="number"
        />
      </>
    );
  },
  save(state) {
    const {
      items: [item],
    } = state;
    return authClient.put("/admin/manage-banners/popup", {
      items: [
        {
          ...item,
          display: item.display ? 1 : 0,
          interval: (item.interval || 0) * 3600000,
        },
      ],
    });
  },
  subtitle: "NOTE: Checks are triggered on navigation only.",
  title: "Popup Banners",
});
