import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Filter,
  AutocompleteInput,
  NumberInput,
  SimpleForm,
  FunctionField,
  Error,
} from "react-admin";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import resourceIcon from "@material-ui/icons/Crop";
import { DateTimeInput, EditorToolbar, isTabLocation } from "../components";

const banners = [
  { field: "cell_one", label: "Cell 1" },
  { field: "cell_two", label: "Cell 2" },
  { field: "cell_three", label: "Cell 3" },
  { field: "cell_four", label: "Cell 4" },
  { field: "cell_five", label: "Cell 5" },
  { field: "cell_six", label: "Cell 6" },
  { field: "cell_seven", label: "Cell 7" },
  { field: "cell_eight", label: "Cell 8" },
];

function validate(values) {
  console.log({ values, v: Object.values(values) });
  const errors = {};
  if (!values.project_id) {
    errors.project_id = ["Required"];
  }
  if (!values.layout) {
    errors.layout = ["Please add at least one cell"];
  } else if (values.layout > 8) {
    errors.layout = ["8 cells maximum"];
  } else {
    for (let cell of banners) {
      if (!values[cell.field]) {
        errors[cell.field] = ["Required"];
      }
    }
  }

  // in progress / TODO: check for duplicate cells by checking the value. this is probably not the vest wayt o
  // const project_id = values.project_id;
  // delete values.project_id;

  // const valueArray = Object.values(values);
  // const set = new Set(valueArray);
  // values.project_id = project_id;

  if (!values.layout_name) {
    errors.layout_name = ["Layout name is required"];
  }
  return errors;
}

const LayoutFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="layout_name" alwaysOn />
  </Filter>
);

function renderEditForm(create = false) {
  return (
    <SimpleForm
      redirect={create ? "edit" : false}
      validate={validate}
      submitOnEnter={false}
      toolbar={<EditorToolbar />}
    >
      <ReferenceInput source="project_id" reference={"projects"}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="layout_name" required />
      {!create && <BooleanInput source="is_active" defaultValue={true} />}
      <NumberInput
        source="layout"
        max={8}
        min={1}
        defaultValue={1}
        label="Banner Count"
      />
      {banners.map((arr, i) => (
        <CellInput key={i} i={i} />
      ))}
    </SimpleForm>
  );
}

function renderCellInput(props) {
  const { formData = {}, i, ...rest } = props;
  if (!formData.layout || formData.layout <= i) {
    return null;
  }

  return (
    <>
      <ReferenceInput
        label={banners[i].label || ""}
        source={banners[i].field || ""}
        reference="banners"
        resource=""
        perPage={10000}
        required
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
    </>
  );
}

function CellInput(props) {
  return <FormDataConsumer {...props}>{renderCellInput}</FormDataConsumer>;
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm(true)}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      title="Layouts"
      filters={<LayoutFilter />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <NumberField source="project_id" />
        <TextField source="layout_name" />
        <BooleanField source="is_active" />
        <NumberField source="layout" />
      </Datagrid>
    </List>
  );
}

export const Layouts = {
  category: "tanext",
  name: "layouts",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
};
