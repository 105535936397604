import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { MenuItem } from "@material-ui/core";
import resourceIcon from "@material-ui/icons/AttachMoney";
import { EditorToolbar } from "../components";

function ListView(props) {
  return (
    <List {...props} sort={{ field: "id", order: "ASC" }}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="discount_code" />
        <BooleanField source="is_active" />
        <TextField source="amount" />
        <TextField source="type" />
      </Datagrid>
    </List>
  );
}

function renderEditForm({ redirect, create }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      {create ? (
        <TextInput source="discount_code" />
      ) : (
        <TextField source="discount_code" />
      )}
      <BooleanInput source="is_active" />
      <TextInput source="amount" />
      <TextInput source="type" select>
        <MenuItem value="percent">Percent</MenuItem>
        <MenuItem value="amount">Amount</MenuItem>
      </TextInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props}>
      {renderEditForm({ redirect: "edit", create: true })}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

export const DoseDiscountCodes = {
  category: "dose",
  name: "dose-discount-codes",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Discount Codes" },
};
