import React from "react";
import {
  Create,
  SimpleForm,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import {
  EditorToolbar,
  ListActions,
  MorePagination,
  DefaultBulkActionButtons,
  validation,
} from "../components";
import SanitizedP from "../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};
function CreateView(props) {
  return (
    <Create {...props} title="Upload Clip">
      <SimpleForm redirect="list" toolbar={<EditorToolbar />}>
        <SanitizedP>
          Note: You cannot select a currency that is already saved in the
          system.
        </SanitizedP>
        <ReferenceInput
          label="Currency"
          source="currency_id"
          reference="currencies"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="dose_amount"
          label="Amount"
          validate={validate.required}
        />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<EditorToolbar showDelete />}>
        <TextInput
          fullWidth
          source="currency_id"
          label="Currency"
          validate={validate.required}
          disabled
        />
        <TextInput
          fullWidth
          source="dose_amount"
          label="Amount"
          validate={validate.required}
        />
      </SimpleForm>
    </Edit>
  );
}

function FiltersView(props) {
  return <Filter {...props}></Filter>;
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filters={<FiltersView />}
      perPage={50}
      pagination={<MorePagination />}
      title="Dose Dedication Currency Amounts"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="currency_id" label="Currency" />
        <TextField source="dose_amount" label="Amount" />
      </Datagrid>
    </List>
  );
}

export const DoseCurrenciesAmounts = {
  category: "dose",
  name: "manage-dose-currencies-amounts",
  list: ListView,
  create: CreateView,
  icon: resourceIcon,
  options: { label: "Dose Currencies" },
  edit: EditView,
};
