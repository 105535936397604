/**
 * @file Copied from react-admin and modified to replace the ExportButton
 * Original code: packages/ra-ui-materialui/src/list/ListActions.js
 */
import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import { sanitizeListRestProps } from "ra-core";
import ClearIcon from "@material-ui/icons/Clear";

import { Button, CardActions, CreateButton, ExportButton } from "react-admin";

import { DownloadExportButton } from "./DownloadExportButton";

const Actions = ({
  bulkActions,
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total,
  ...rest
}) => (
  <CardActions className={className} {...sanitizeListRestProps(rest)}>
    {bulkActions &&
      cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {hasCreate && <CreateButton basePath={basePath} />}
    {exporter === false ? null : exporter === true ? (
      <DownloadExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
      />
    ) : (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
      />
    )}
    {filters && (
      <Button
        label="Clear"
        title="Clear Filters"
        onClick={() => rest.setFilters({})}
      >
        <ClearIcon />
      </Button>
    )}
  </CardActions>
);

Actions.propTypes = {
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number.isRequired,
};

Actions.defaultProps = {
  selectedIds: [],
};

export const ListActions = onlyUpdateForKeys([
  "resource",
  "filters",
  "displayedFilters",
  "filterValues",
  "selectedIds",
])(Actions);
