import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Button } from "@material-ui/core";
import { authClient } from "../../server";

export const UsersRethink = _UsersRethink;
function _UsersRethink() {
  const [settings, setSettings] = useState({});
  const [userToSave, setUserToSave] = useState({});
  const [userId, setUserId] = useState("");
  const [userChanged, setUserChanged] = useState(false);

  const saveSettings = async () => {
    setUserChanged(false);

    await authClient.post(
      `/admin/rethink-users/${userId}`,
      JSON.parse(userToSave),
    );
  };

  useEffect(() => {
    (async function() {
      let _userId = window.location.pathname.split("/")[2];
      setUserId(_userId);
      const response = await authClient.get(
        `/admin/rethink-users/${userId}`,
      );
      setSettings(JSON.parse(response.body));
    })();
  }, [userId]);

  return (
    <div>
      <JSONInput
        style={{
          width: "100%",
        }}
        id="a_unique_id"
        placeholder={settings}
        // colors={darktheme}
        locale={locale}
        height="550px"
        width="100%"
        onChange={e => {
          setUserChanged(true);
          console.log("e.json", e.json);
          setUserToSave(e.json);
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <Button
          disabled={!userChanged}
          variant={"outlined"}
          onClick={saveSettings}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
