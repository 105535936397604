import React from "react";
import {
  Filter,
  // DateTimeInput,
  // DateInput,
  TextInput,
  // SelectInput,
  LongTextInput,
  Edit,
  Create,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  DateField,
  // FunctionField,
} from "react-admin";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { EditorToolbar } from "../../components";

function renderEditForm({ redirect }) {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <LongTextInput source="title" label="Title" />
      <LongTextInput source="url" label="URL" />
      <TextInput source="post_id" label="Post Id" />
    </SimpleForm>
  );
}

function EditView(props) {
  return (
    <Edit {...props} title="News & Announcements">
      {renderEditForm({ redirect: false })}
    </Edit>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="post_id" label="Post Id" alwaysOn />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      // actions={<ListActions />}
      exporter={false}
      filters={<FiltersView />}
      title="News & announcements"
      // bulkActionButtons={false}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="post_id" label="Post Id" />
        <TextField source="title" label="Title" />
        <TextField source="url" label="URL" />
        <DateField source="updated_at" label="Updated At" showTime />
      </Datagrid>
    </List>
  );
}

export const News_announcements = {
  category: "manage",
  name: "manage-news-announcements",
  edit: EditView,
  create: CreateView,
  list: ListView,
  icon: AnnouncementIcon,
  options: { label: "News & Announcements" },
};
