import React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  FunctionField,
  DateInput,
} from "react-admin";
import resourceIcon from "@material-ui/icons/SwapHoriz";
import { ListActions, MorePagination } from "../components";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <DateInput label="From" source="from_date" alwaysOn />
      <DateInput label="To" source="to_date" alwaysOn />
    </Filter>
  );
}
function ListView(props) {
  return (
    <List
      {...props}
      filters={<FiltersView />}
      actions={<ListActions />}
      exporter={true}
      perPage={50}
      pagination={<MorePagination />}
      title="Dose Donors Reports"
    >
      <Datagrid>
        <TextField label="Donors" source="donations_amount" />
        <TextField label="Date" source="donated_date" />
        <FunctionField
          label="USD"
          render={record => parseFloat(record.total_usd).toFixed()}
        />
        <FunctionField
          label="CAD"
          render={record => parseFloat(record.total_cad).toFixed()}
        />
        <FunctionField
          label="NIS"
          render={record => parseFloat(record.total_ils).toFixed()}
        />
        <FunctionField
          label="EUR"
          render={record => parseFloat(record.total_eur).toFixed()}
        />
        <FunctionField
          label="GBP"
          render={record => parseFloat(record.total_gbp).toFixed()}
        />
        <FunctionField
          label="AUD"
          render={record => parseFloat(record.total_aud).toFixed()}
        />
        <FunctionField
          label="ZAR"
          render={record => parseFloat(record.total_zar).toFixed()}
        />
      </Datagrid>
    </List>
  );
}

export const DoseDedicationsReporting = {
  category: "dose",
  name: "dose-donors-reporting",
  list: ListView,
  icon: resourceIcon,
  options: { label: "Dose Reporting" },
};
