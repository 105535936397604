import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Confirm,
  showNotification,
  refreshView,
  setListSelectedIds,
} from "react-admin";
import { authClient } from "../../server";

class SendEmailButton extends Component {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = async () => {
    this.setState({ isOpen: false });

    const {
      resource,
      selectedIds,
      showNotification,
      setListSelectedIds,
      refreshView,
    } = this.props;
    setListSelectedIds(resource, []);
    refreshView();
    const results = await authClient
      .post("/beta_users/send_emails", {
        ids: selectedIds,
      })
      .catch(err => {
        console.error(err);
        showNotification("Emails Failed", "warning");
      });
    if (results) {
      showNotification("Emails sent");
      setListSelectedIds(resource, []);
      refreshView();
    }
  };

  render() {
    const { selectedIds } = this.props;

    return (
      <Fragment>
        <Button label="Send Email" onClick={this.handleClick} />
        <Confirm
          isOpen={this.state.isOpen}
          title="Send Beta Emails"
          content={`You are about to send ${selectedIds.length} emails.`}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

export default connect(
  undefined,
  { showNotification, refreshView, setListSelectedIds },
)(SendEmailButton);
