import React from "react";
import { Edit, SimpleForm } from "react-admin";
import icon from "@material-ui/icons/Restaurant";
import { EditorToolbar, EventSpeakersInput } from "../../components";

function EditView(props) {
  return (
    <Edit {...props} title="Mishloach Manot Campaign">
      <SimpleForm redirect={false} toolbar={<EditorToolbar />}>
        <EventSpeakersInput
          label="Available Speakers"
          source="speakers"
          allowEmpty
        />
      </SimpleForm>
    </Edit>
  );
}

export const MishloachManot = {
  category: "campaigns",
  name: "mishloach-manot",
  edit: EditView,
  editId: "campaign",
  icon,
  options: { label: "Mishloach Manot" },
};
