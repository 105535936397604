import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  DateField,
  UrlField,
  List,
  NumberField,
  BooleanField,
  ImageField,
  SimpleForm,
  TextField,
  TextInput,
  LongTextInput,
  required,
  regex,
  number,
  minValue,
} from "react-admin";

import resourceIcon from "@material-ui/icons/Announcement";
import { BooleanInput, NumberInput } from "ra-ui-materialui/lib/input";
import { EditorToolbar } from "../components";
import DisabledInput from "ra-ui-materialui/lib/input/DisabledInput";

const validateImageLink = [
  required(),
  regex(
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    "Must be a valid URL (http://...)",
  ),
];

const validateLink = regex(
  /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  "Must be a valid URL (http://...)",
);

 const validateNumber = values => {
  const errors = {};
    if (values.cells>3) {
      errors.cells = ["Please choose between 1-3 cells"];
    }
  return errors;
  }
function ListView(props) {
  return (
    <><h2>Active ads must total 2 or 3 cells</h2><List
      {...props}
      sort={{ field: "updated_at", order: "DESC" }}
      bulkActionButtons={false}
    >
      
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <ImageField
          source="full_image"
          classes={{ image: "small-image" }}
          sortable={false}
        />
        <UrlField
          source="link"
          target="_blank"
          rel="noopener noreferrer"
          sortable={false}
        />
        <NumberField source="cells" validate={validateNumber}/>
        <BooleanField source="show" sortable={false} />
        <DateField source="updated_at" showTime label="Updated at (GMT)" />
      </Datagrid>
    </List></>
  );
}

// const PreviewImage = props => (
//   <a
//     href={props.record.link}
//     target="_blank"
//     title={props.record.name}
//     rel="noopener noreferrer"
//   >
//     <WideCard photo={props.record.image} containImage />
//   </a>
// );

function renderEditForm({ redirect }) {
  return (
    <SimpleForm validate={validateNumber} redirect={redirect} toolbar={<EditorToolbar />}>
      <BooleanInput source="show" />
      
      <TextInput source="name" />
      <LongTextInput source="full_image" validate={validateImageLink} />
      <ImageField
        source="full_image"
        classes={{ image: "small-image" }}
        sortable={false}
      />
      <p style={{width:"50%"}}>ads can be 1-3 cells wide (totalling 2-3 cells), <br/>with each cell having an aspect ratio of 5:1 <br/>(i.e. an image spanning 2 cells would be 800x80, giving it a 10:1 ratio)</p>
      <NumberInput validate={required()} source="cells"/>
      <LongTextInput source="link" validate={validateLink} />
      
      {/* <PreviewImage /> */}
    </SimpleForm>
  );
}

// const ConditionalBooleanInput = ({ record, ...rest }) => {
//   return <BooleanInput record={record} {...rest} options={{ disabled: record.can_close }} />
// };

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: "list" })}
    </Edit>
  );
}

export const Ads = {
  category: "tanext",
  name: "announcements",
  create: CreateView,
  edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "Ads" },
};
