import React from "react";
import { Link } from "react-router-dom";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  FunctionField,
  DateField,
  BooleanField,
} from "react-admin";
import resourceIcon from "@material-ui/icons/Camera";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import { CreateDoseView, EditDoseView } from "./CreateAndUpdateDoses";
import { ListActions, MorePagination, createDoseUserCommentsURL } from "../../../components";

function Warning(props) {
  if (
    // !props.record.speaker_dose_app_photo_url ||
    !props.record.speaker_dose_podcast_photo_url ||
    !props.record.speaker_dose_instagram_photo_url ||
    !props.record.speaker_dose_email_photo_url ||
    !props.record.speaker_double_dose_email_photo_url
  ) {
    return (
      <Tooltip title="Speaker missing required assets">
        <WarningIcon />
      </Tooltip>
    );
  } else return <p></p>;
}
function ListView(props) {
  return (
    <List
      pagination={<MorePagination />}
      {...props}
      actions={<ListActions />}
      exporter={false}
      filters={<FiltersView />}
      perPage={50}
      sort={{
        field: "dose_number",
        order: "DESC",
      }}
      title="Doses"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="dose_number" />
        <Warning {...props} />
        <FunctionField
          label="Speaker"
          render={record =>
            ` ${record.speaker_title_short} ${record.speaker_name_first} ${record.speaker_name_last}`
          }
        />

        <TextField source="title" />
        <DateField
          source="released_at"
          label="Released at"
          style={{ minWidth: 150 }}
          options={{ timeZone: "UTC" }}
        />
        <BooleanField source="display"></BooleanField>
        <BooleanField source="double_dose"></BooleanField>
        <FunctionField
          label="Comments A/T"
          source="dose_number"
          render={record => (
            <a href={createDoseUserCommentsURL(record.dose_number)} target="_blank" onClick={(e) => {e.stopPropagation()}}>{record.comment_status}</a>
          )}
        />
      </Datagrid>
    </List>
  );
}

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput source="dose_number" alwaysOn />
      <TextInput source="title" alwaysOn />
    </Filter>
  );
}

export const Doses = {
  category: "dose",
  name: "daily_dose",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Doses" },
  create: CreateDoseView,
  edit: EditDoseView,
  roles: ["dose_admin", "dose_viewer"],
  permissions: {
    dose_admin: ["create", "list", "edit"],
    // We don't have a "view only" view. So we give the viewer edit
    // permissions here, however, we enforce read-only on the backend
    dose_viewer: ["list", "edit"],
  },
};
