import React from "react";
import { Filter, TextInput, DateInput, List, Datagrid, TextField, ArrayField } from 'react-admin';
import { DateTimeField, formatDateTimeInput } from "../components";
import resourceIcon from "@material-ui/icons/AccountBox";

function FiltersView(props) {
  return (
    <Filter {...props}>
      <TextInput label="User" source="user_name" alwaysOn />
      <TextInput label="Table" source="table_name" alwaysOn />
      <TextInput label="ID in Table" source="table_row_id" alwaysOn />
      <TextInput label="Field" source="table_row_id" alwaysOn />
      <DateInput
        label="From"
        source="created_at.>"
        alwaysOn
        format={formatDateTimeInput}
      />
      <DateInput
        label="To"
        source="created_at.<"
        alwaysOn
        format={formatDateTimeInput}
      />
    </Filter>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      exporter={false}
      filters={<FiltersView />}
      title="DB Operations"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField label="User" source="user_name" />
        <TextField label="Operation" source="operation" />
        <TextField label="Table" source="table_name" />
        <TextField label="ID in Table" source="table_row_id" />
        <ArrayField label="Changes" source="changes">
          <Datagrid>
            <TextField label="Field" source="field" />
            <TextField label="From" source="previous_value" />
            <TextField label="To" source="new_value" />
          </Datagrid>
        </ArrayField>
        <DateTimeField
          label="Date/Time"
          source="timestamp"
        />
      </Datagrid >
    </List >
  );
}

export const DbOperationLogs = {
  category: "misc",
  name: "db_operation_logs",
  //create: CreateView,
  //edit: EditView,
  icon: resourceIcon,
  list: ListView,
  options: { label: "DB Operations Log" },
};