import union from "lodash/union";
import DefaultCategoryIcon from "@material-ui/icons/FolderOpen";
import DefaultItemIcon from "@material-ui/icons/RadioButtonUnchecked";

import categoriesData from "../categories";
import resources from "../resources";
import { pageMenuItems } from "../pages";
import { DefaultListView, toProperCase } from "../components";
import { authorized } from "../server";

/** Array of menu categories.
 * @type {MenuCategoryInfo[]}
 */
export const categories = [];

/** Map of menu categories by name.
 * @type {{[name:string]:MenuCategoryInfo}}
 */
export const categoriesByName = categoriesData.reduce((map, cat) => {
  cat.items = [];
  if (!cat.label) {
    cat.label = toProperCase(cat.name);
  }
  if (!cat.icon) {
    cat.icon = DefaultCategoryIcon;
  }
  categories.push(cat);
  map[cat.name] = cat;
  return map;
}, {});

/** A category for all the items that have no category. (Not included in
 * `categories`.)
 * @type {MenuCategoryInfo}
 */
export const noCategory = {
  icon: null,
  label: "",
  name: "none",
  items: [],
};
/** Map of menu items by name.
 * @type {{[name:string]:MenuItemInfo}}
 */
export const menuItemsByName = {};

/** Copies default roles from category, if any. Builds up category item_roles.
 * @param {{category?:string,roles?:string[]}} src
 * @param {MenuCategoryInfo} [cat]
 * @returns {string[]} The roles assigned to `src`, if any.
 */
function mergeCategoryRoles(src, cat) {
  let { category, roles } = src;
  if (category) {
    if (!roles && cat.roles) {
      // Roles missing from source. Copy defaults from category.
      roles = cat.roles;
      src.roles = roles;
    } else {
      // Build up cat.item_roles so we can lookup all roles of all items in a
      // category, to see if the category should be displayed.
      cat.item_roles = union(cat.roles, cat.item_roles, roles);
    }
  }
  return roles;
}

resources.forEach(res => {
  if (res.hidden) {
    return;
  }
  const cat = categoriesByName[res.category] || noCategory;
  const {
    category,
    icon = DefaultItemIcon,
    name,
    options: { label } = {},
    list,
    create,
    editId = 1,
    permissions,
  } = res;
  const roles = mergeCategoryRoles(res, cat);
  // #region NOTE: Add default list view if missing, for issue:
  // - https://github.com/marmelab/react-admin/issues/2887
  // - "Saving Edit without changes clears values on Resource without list"
  // - Also, "Navigating to [a list-less resource edit page], if you're already
  // there, causes the form to get blanked out as well"
  // #endregion
  if (!list) {
    res.list = DefaultListView;
  }
  const item = {
    category,
    hasList: !!list,
    icon,
    label: !label ? toProperCase(name) : label,
    name,
    // If there is a list, link to that.
    // Otherwise link to create or edit with a default id of 1.
    url:
      list && authorized(roles, permissions, "list")
        ? `/${name}`
        : create && authorized(roles, permissions, "create")
        ? `/${name}/create`
        : `/${name}/${encodeURIComponent(editId)}`,
    roles,
  };
  cat.items.push(item);
  menuItemsByName[name] = item;
});

pageMenuItems.forEach(route => {
  const cat = categoriesByName[route.category] || noCategory;
  const { category, icon = DefaultItemIcon, label, name, url } = route;
  const roles = mergeCategoryRoles(route, cat);
  const item = {
    category,
    hasList: false,
    icon,
    label: !label ? toProperCase(name) : label,
    name,
    url: !url ? `/${name}` : url,
    roles,
  };
  cat.items.push(item);
  menuItemsByName[name] = item;
});

// #region Typedefs
/** @typedef {object} MenuCategoryInfo
 * @property {React.ComponentType} icon Icon to display for the item.
 * @property {string} label Display text of the category.
 * @property {string} name Name of the category used for identification.
 * @property {MenuItemInfo[]} items
 * @property {string[]} [roles] User roles allowed to see this category. Used as
 * the default roles for any items added to the category.
 * @property {string[]} [item_roles] User roles allowed to see items in this
 * category. Built up as items are added to the category and used to check if
 * a category should be visible.
 */
/** @typedef {object} MenuItemInfo
 * @property {string} category Name of the category this item belongs to.
 * @property {boolean} hasList True if the resource linked to has a list.
 * @property {React.ComponentType} icon Icon to display for the item.
 * @property {string} label Display text of the menu item.
 * @property {string} name Name used for identification.
 * @property {string[]} [roles] User roles allowed to see this item.
 * @property {string} url Relative URL that the item navigates to.
 */
// #endregion
