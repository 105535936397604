// Framework
import React, { useEffect } from "react";
import { Provider, connect } from "react-redux";
import {
  Admin,
  ListGuesser, // eslint-disable-line
  Resource,
  Login,
  userLogout,
} from "react-admin";
// Application
import { AppLayout } from "./layout";
import resources from "./resources";
import { DashboardPage } from "./pages";
import {
  authorized,
  authProvider,
  jsonDataProvider,
  authSocketConnect,
  joinAuthRoom,
  authUser,
} from "./server";
import { i18nProvider } from "./translation";
import { pageRoutes } from "./pages";
import createAdminStore from "./createAdminStore";
import history from "./history";
import { MediaUploadProvider } from "./uploader";
import HandleTokenRefresh from "./refresh-token";

const { REACT_APP_VERSION } = process.env;

const CustomBGLoginPage = () => (
  <Login backgroundImage="https://torahanytime-files.sfo2.cdn.digitaloceanspaces.com/assets/flash/banner/Nice-kotel-image.jpg" />
);
export default class App extends React.PureComponent {
  /** NOTES:
   * - [How to add a sticky footer](https://github.com/marmelab/react-admin/issues/2378#issuecomment-446701278)
   * - [Warning: Received true for non-boolean attribute...](https://github.com/marmelab/react-admin/issues/2651)
   */
  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider: jsonDataProvider,
          i18nProvider,
          history,
        })}
      >
        <MediaUploadProvider allowed={authorized(["author"])}>
          <Admin
            loginPage={CustomBGLoginPage}
            appLayout={AppLayout}
            authProvider={authProvider}
            dashboard={DashboardPage}
            dataProvider={jsonDataProvider}
            customRoutes={pageRoutes}
            history={history}
            i18nProvider={i18nProvider}
          >
            {App.renderResources}
          </Admin>
          <HandleTokenRefresh />
          <AuthSocket />
          {history.location.search.indexOf("rafooter=0") < 0 && (
            <div
              style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 2,
                padding: 4,
                fontFamily: "Roboto Condensed, sans-serif",
                fontSize: 12,
              }}
            >
              Torah Anytime Admin v{REACT_APP_VERSION}
            </div>
          )}
        </MediaUploadProvider>
      </Provider>
    );
  }

  static renderResources(/* roles */) {
    // NOTE: Not using roles here because we're using our `authorized` function.
    // WARNING: If the call to this function is replaced with
    // `{resources.map(App.renderResource)}`
    // then resources that require a certain role might not work until the user
    // refreshes the page after logging in.
    return resources.map(App.renderResource);
  }

  static renderResource(props) {
    const {
      // #region Sanitize props that should not be passed to `<Resource />`
      category: _category,
      editId: _editId,
      hidden: _hidden,
      permissions,
      roles,
      // #endregion
      create,
      edit,
      list,
      ...rest
    } = props;
    // NOTE: If a user is not authorized to interact with a resource, we are
    // still rendering the resource specification but not the create/edit/list
    // views. This allows the restricted user to use related resource references
    // in the resources that they are allowed to access.
    return (
      <Resource
        key={props.name}
        create={authorized(roles, permissions, "create") ? create : null}
        edit={authorized(roles, permissions, "edit") ? edit : null}
        list={authorized(roles, permissions, "list") ? list : null}
        view={authorized(roles, permissions, "view") ? list : null}
        {...rest}
      />
    );
  }
}

function AuthSocket_({ isLoggedIn, userLogout }) {
  const authSocketEvents = [
    {
      event: "logout",
      callback: msg => {
        userLogout();
        window.location = "/login";
      },
    },
  ];

  useEffect(() => {
    // need to check the token because for some reason if the user is
    // already logged in then `isLoggedIn` is false...
    if (isLoggedIn || !!localStorage.getItem("token")) {
      //authSocketConnect();
      //joinAuthRoom(authUser.id, authSocketEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return null;
}

function mapState(state) {
  return {
    isLoggedIn: state.admin.auth.isLoggedIn,
  };
}

const AuthSocket = connect(
  mapState,
  { userLogout },
)(AuthSocket_);
